import { FC, useState } from 'react'
import { Button, Row } from 'antd'
import { ISearchProp } from './Types'
import { SearchItem } from './item'
import { AdvancedSearch } from './advancedSearch'
import './header.less'

export const YhPageHeader: FC<ISearchProp> = (props) => {
    const { handleReset = () => {}, handleSearch = () => {}, searchFields, searchMoreFields, size, justify = 'start', style } = props

    const [showAdvanced, setShowAdvanced] = useState(false)
    return (
        <div className="toolbar" style={{ ...style, display: !searchFields || searchFields?.length === 0 ? 'none' : 'bloack' }}>
            {/* <span className="modules-name" hidden={!title}>{title}</span> */}
            <div hidden={!searchFields || searchFields?.length === 0} className="pub-search-box">
                <Row gutter={[20, 10]} justify={justify}>
                    {searchFields?.map((el, index) => (
                        <SearchItem key={index} {...{ ...props, ...el, size }} />
                    ))}
                </Row>
                {/* {showMore && <Row
                    // hidden={!searchMoreFields || searchMoreFields?.length === 0}
                    gutter={[20, 10]}
                    justify="start"
                    style={{margin:0}}
                >
                    {searchMoreFields?.map(
                        (el, index) => <SearchItem
                            key={index}
                            {...{ ...props, ...el, size }}
                        />
                    )}
                </Row>} */}
            </div>
            <div hidden={!searchFields || searchFields?.length === 0} className="pub-search-right">
                <Button size={size} onClick={() => handleSearch?.()} type="primary" className="header-search">
                    检索
                </Button>
                <Button size={size} onClick={() => handleReset?.()} className="header-reset">
                    重置
                </Button>
                <Button
                    hidden={!(searchMoreFields && searchMoreFields.length !== 0)}
                    size={size}
                    onClick={() => {
                        setShowAdvanced(true)
                    }}
                    className="header-top"
                >
                    高级搜索
                </Button>
            </div>
            <AdvancedSearch {...props} showAdvanced={showAdvanced} setShowAdvanced={setShowAdvanced} />
        </div>
    )
}
