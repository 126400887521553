export enum DictCodeEnmu {
    // 故障报修记录
    REPAIR_INIT = 'repair.init',
    REPAIR_TRANSFERED = 'repair.transferred',

    //车辆品牌
    VEHICLE_BRAND = 'vehicle-brand',

    //车辆类型
    VEHICLE_TYPE = 'vehicle-type',

    //库存盘点状态
    STOCK_CHECK_STATUS = 'stocktaking-status',
    //库存盘点 （已作废）
    STOCK_CHECK_STATUS_CANCEL = 'stocktaking-status.cancel',
    //库存盘点 （已完成）
    STOCK_CHECK_STATUS_FINISH = 'stocktaking-status.finish',
    //库存盘点 （进行中）
    STOCK_CHECK_STATUS_CREATE = 'stocktaking-status.create',
    //库存盘点 待审核
    STOCK_CHECK_STATUS_BE_REVIEWED = 'stocktaking-status.be-reviewed',
    //库存盘点 驳回
    STOCK_CHECK_STATUS_REJECT = 'stocktaking-status.reject',

    //单据类型
    TRADE_TYPE = 'trade-type',
    //单据类型 (销售单)
    TRADE_TYPE_SO = 'trade-type.sale',
    //单据类型 (销售订单)
    TRADE_TYPE_SORDER = 'trade-type.sale-order',
    //单据类型 ( 销售退货）
    TRADE_TYPE_SRO = 'trade-type.sale-return',
    //单据类型 (采购单)
    TRADE_TYPE_PO = 'trade-type.purchase',
    //单据类型 (采购退货)
    TRADE_TYPE_PRO = 'trade-type.purchase-return',

    //单据状态
    TRADE_STSTUS = 'purchase-status',
    DOCUMENT_STSTUS = 'document-status',
}
export const sourceAppOrBranch = {
    sourceApp: 'ims',
    branch: 'ims',
}
