import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'

export function Unfinished() {
    return (
        <>
            <PageHeader />
            <DataBox />
        </>
    )
}
