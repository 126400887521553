import React, { FC, useState, useEffect } from 'react'
import { IFormItem } from '../Types'
import { Upload, Button, Form, Input, message } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { getBaseRequestUrl, getToken } from '@/utils/constants'
import { publicService } from '@/views/public/publicService'
import { convertRes2Blob } from '@/utils/exportFile'
import { CloseCircleOutlined } from '@ant-design/icons'

interface fileInfo {
    md5: string
    fileName: string
}
//王哥的文件服务
export const __FormFileImage: FC<IFormItem> = ({ md5List = [], onSetFieldsValue, ...props }) => {
    const [previewImage, setpreviewImage] = useState<fileInfo[]>(md5List) //商品缩略图

    // 图片上传
    const handleImgChange = (info: UploadChangeParam) => {
        if (info.file.status === 'done') {
            console.log(info)
            let originImg = [...previewImage]
            let md5 = info.file.response.retData as any // string | object;
            if (typeof md5 === 'string') {
                originImg.push({
                    md5,
                    fileName: info.file.name,
                })
                setpreviewImage(originImg)
            } else {
                message.warning(md5?.message ?? '文件服务有问题')
            }
        } else if (info.file.status === 'error') {
            message.error(info.file.response?.retMsg ?? '导入错误')
        }
    }

    useEffect(() => {
        setpreviewImage(md5List)
    }, [md5List])

    useEffect(() => {
        onSetFieldsValue && onSetFieldsValue({ [props.fieldName]: previewImage })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewImage])

    //下载文件
    const downloadFile = async (fileId: string, fileName: string) => {
        if (!fileId) return message.warning('文件异常，无法下载查看')
        //pdf开网页预览
        if (fileId.indexOf('.pdf') >= 0) {
            window.open(`${getBaseRequestUrl()}/fsrv/fetch/${fileId}?Authorization=${getToken()}`)
            return
        }
        try {
            let result = await publicService.downloadFile(fileId)
            convertRes2Blob(result, fileName)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <Form.Item name={props.fieldName} hidden>
                <Input />
            </Form.Item>
            <Form.Item label={props.fieldLabel} rules={props.rules} labelCol={props.labelCol}>
                {previewImage &&
                    previewImage.map((item, index) => (
                        <div key={index} onClick={() => downloadFile(item.md5, item.fileName)} style={{ color: '#00f', cursor: 'pointer' }}>
                            {item.fileName}&nbsp;&nbsp;&nbsp;&nbsp;
                            <CloseCircleOutlined
                                style={{ color: '#f5222d' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    let originImg = previewImage.filter((file) => file.md5 !== item.md5)
                                    setpreviewImage(originImg)
                                }}
                            />
                        </div>
                    ))}
                {!props.disable && (
                    <Upload
                        disabled={props.disable}
                        name="file"
                        showUploadList={false}
                        action={`${getBaseRequestUrl()}/fsrv/upload_file?Authorization=${getToken()}`}
                        onChange={handleImgChange}
                        headers={{
                            authorization: getToken(),
                        }}
                        data={{
                            appCode: 'ims',
                            fileMode: 0, //0私有  1是共有
                        }}
                    >
                        <Button type="link" disabled={props.disable}>
                            上传
                        </Button>
                    </Upload>
                )}
            </Form.Item>
        </>
    )
}
