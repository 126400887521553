import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { getBaseCiRequestUrl } from '@/utils/constants'

//详情
export interface IDetail {
    /**
     * 期初数
     */
    beginNum?: number
    /**
     * 维修预约占用数
     */
    bookOccupyNum?: number
    /**
     * 公交销售数
     */
    busSaleNum?: number
    /**
     * 公交销退数
     */
    busSreturnNum?: number
    /**
     * 补偿数
     */
    compensateNum?: number
    /**
     * 当前库存数
     */
    currentNum?: number
    /**
     * 差异数
     */
    differenceNum?: number
    /**
     * 维修用数
     */
    maintainNum?: number
    /**
     * 维修占用数
     */
    maintainOccupyNum?: number
    /**
     * 物料编码
     */
    materialCode?: string
    /**
     * 物料名称
     */
    materialName?: string
    /**
     * 维修退数
     */
    mreturnNum?: number
    /**
     * 占用差异
     */
    occupyDifference?: number
    /**
     * 占用库存数
     */
    occupyNum?: number
    /**
     * 采购退货数
     */
    preturnNum?: number
    /**
     * 采退占用数
     */
    preturnOccupyNum?: number
    /**
     * 采购价
     */
    purchasePrice?: { [key: string]: any }
    /**
     * 最新进货价
     */
    purchasePriceLatest?: { [key: string]: any }
    /**
     * 采购数(含期初和补偿)
     */
    purhcaseNum?: number
    /**
     * 进销存剩余数
     */
    remainNum?: number
    /**
     * 销售数
     */
    saleNum?: number
    /**
     * 销售占用数
     */
    saleOccupyNum?: number
    /**
     * 门店id
     */
    shopId?: string
    /**
     * 销售退货数
     */
    sreturnNum?: number
    /**
     * 库存成本
     */
    stockCost?: { [key: string]: any }
    /**
     * 最新库存成本
     */
    stockCostLatest?: { [key: string]: any }
    /**
     * 盘库占用数
     */
    stocktakingOccupyNum?: number
    /**
     * 非关联销售退货
     */
    unusualSreturnNum?: number
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    result?: string
    offset?: string
    module?: string
    ip?: string
    operationType?: string
    startTime?: string
    endTime?: string
    operator?: string
    searchItem?: {}
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
    branch: string
}

class Service {
    async page(searchVo: ISearch, config: object = {}) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/statistics/purchaseSaleStorage'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/statistics/purchaseSaleStorage'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/statistics/purchaseSaleStorage'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-storage/statistics/purchaseSaleStorage'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, { ...config, timeout: 300000, ...{ baseURL } })
    }

    //导出
    async export(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/statistics/purchaseSaleStorageExport'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/statistics/purchaseSaleStorageExport'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/statistics/purchaseSaleStorageExport'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-storage/statistics/purchaseSaleStorageExport'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }
}

export const service = new Service()
