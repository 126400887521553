import { useEffect, useState, useRef } from 'react'
import * as echarts from 'echarts'
import styled from '@emotion/styled'
import { Spin } from 'antd'
import { EChartOption } from 'echarts'
import { useHomeModel } from '../homeModel'

export default function PieChart() {
    const { warehouseStatisticsLoading, warehouseStatisticsDetails, getWarehouseStatistics } = useHomeModel()

    const chartRef = useRef<HTMLDivElement>(null)
    const [chart, setChart] = useState<echarts.ECharts>()
    const handleResize = () => chart?.resize()

    let option: EChartOption = {
        color: ['#5B8FF9', '#5AD8A6', '#FF9D4D', '#F6BD16', '#E8684A', '#009966', '#CC0033', '#0099CC', '#FFCCCC', '#CCCC33', '#CCCCFF', '#FF9966'],
        tooltip: {
            trigger: 'item',
            formatter: (params: any) => {
                let _html = ''
                _html =
                    _html +
                    '<div><span style="width: 8px;height: 8px;border-radius: 50%;margin-right: 5px;display: inline-block;background: ' +
                    params.color +
                    '"></span>' +
                    params.data.name +
                    '</div>'
                _html = _html + '<div>数量：' + params.data.value + '</div>'
                _html = _html + '<div>金额：' + params.data.amount + '</div>'
                return _html
            },
        },
        legend: {
            top: '40%',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 10,
            right: 50,
            orient: 'vertical',
            icon: 'circle',
            data: [],
        },
        series: [
            {
                type: 'pie',
                radius: ['30%', '75%'],
                labelLine: {
                    length: 20,
                    lineStyle: {
                        color: 'rgba(0, 0, 0, 0.45)',
                    },
                },
                label: {
                    formatter: '{b}\n {c} ',
                    color: 'rgba(0, 0, 0, 0.45)',
                },
                data: [],
            },
        ],
    }

    const init = () => {
        if (chart) {
            window.removeEventListener('resize', handleResize)
        }

        option!.legend!.data = warehouseStatisticsDetails?.names
        option!.series![0]!.data = warehouseStatisticsDetails?.infos

        let chartInstance = echarts.getInstanceByDom(chartRef.current as HTMLDivElement)
        if (!chartInstance) {
            chartInstance = echarts.init(chartRef.current as HTMLDivElement)
        }
        chartInstance.setOption(option)
        window.addEventListener('resize', () => chartInstance?.resize())
        setChart(chartInstance)
    }

    useEffect(() => {
        getWarehouseStatistics()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (warehouseStatisticsDetails.isInit) {
            return
        }

        init()

        return () => {
            window.removeEventListener('resize', handleResize)
            chart?.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseStatisticsDetails])

    return (
        <Box className="index-content pieChart">
            <div className="index-content-title-bar">
                <span className="index-content-title-bar-title">仓库数据统计</span>
            </div>

            <Spin tip="Loading..." spinning={warehouseStatisticsLoading}>
                <div className="content">
                    <div ref={chartRef} className="chart"></div>
                </div>
            </Spin>
        </Box>
    )
}

const Box = styled.div`
    width: calc(50% - 8px);
    height: 320px;

    .content {
        position: relative;
        .chart {
            width: 100%;
            height: 250px;
        }
    }
`
