import React, { FC, useEffect, useImperativeHandle } from 'react'
import { Form, Row, message } from 'antd'
import { IYhForm } from './Types'
import { __FormItem as FormItem } from './item'
import copy from 'copy-to-clipboard'

export const __YhForm: FC<IYhForm> = ({ formRef, items, ...props }) => {
    const [form] = Form.useForm()
    const { labelCol, labelAlign, disabled, formValues, onChange, onFinish } = props
    const onValuesChange = (changedValues: any, values: any) => {
        //disabled ? form.setFieldsValue(formValues) : onChange?.(changedValues);
        onChange?.(changedValues)
    }
    const copyText = (fieldName: string) => {
        let value = form.getFieldsValue([fieldName])![fieldName]
        if (!value) {
            value = ' '
        }
        if (typeof value !== 'string') return
        copy(value)
        message.success('复制成功')
    }
    useEffect(() => {
        // form.resetFields();
        form.setFieldsValue(formValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues])

    useImperativeHandle(formRef, () => ({
        validateFields: async () => {
            return await form.validateFields()
        },
        setFieldsValue: form.setFieldsValue,
        getFieldsValue: () => {
            return form.getFieldsValue()
        },
        resetFields: form.resetFields,
        inst: () => {
            return form
        },
    }))
    return (
        <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish} labelCol={labelCol} labelAlign={labelAlign} layout="horizontal">
            <Row justify="start">
                {items.map((el, index) => {
                    return <FormItem key={index} {...el} disable={el.disable ?? disabled} copyText={(fieldName: string) => copyText(fieldName)} />
                })}
            </Row>
        </Form>
    )
}
