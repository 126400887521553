import { ChangeEvent, useState, useEffect } from 'react'
import logoImg from '../../assets/images/logoimg.png'
import { message, Input, Modal } from 'antd'
import useGlobalModel from '../../model/globalModel'
import { useHistory } from 'react-router-dom'
import './Login.scss'
import { setToken, defaultMdCmParam, CLIENT_APP_CODE } from '../../utils/constants'
import { loginServiceAuth2, Auth2MenuDetail } from './LoginServiceAuth2'
import { globalPrompt } from '@/components/message'
import { SmileTwoTone, ToolOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { IShopDetail, mdmService } from '@/views/public/mdmService'

interface IgetIpType {
    cid: string
    cip: string
    cname: string
}
declare const window: Window & { returnCitySN?: IgetIpType }

export const Login = () => {
    const history = useHistory()
    const { user, setUser, setBeShopDs, setShopDs, setResourceAuth, setMenuAuth } = useGlobalModel()

    const [state, setstate] = useState({
        userAccount: '',
        userPassword: '',
        token: '',
    })

    const [checkPwd, setCheckPwd] = useState<boolean>(() => {
        let userAccount = window.localStorage.getItem('userAccount')
        let userPassword = window.localStorage.getItem('userPassword')
        if (userAccount || userPassword) {
            return true
        }
        return false
    })

    const [loading, setLoading] = useState<boolean>(false)

    const onAccountChange = (e: ChangeEvent<HTMLInputElement>) => {
        setstate({ ...state, userAccount: e.target.value })
        checkPwd && window.localStorage.setItem('userAccount', e.target.value)
    }
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setstate({ ...state, userPassword: e.target.value })
        checkPwd && window.localStorage.setItem('userPassword', e.target.value)
    }

    const [IP, setIP] = useState<string>('')

    //回显记住账号密码
    useEffect(() => {
        let userAccount = window.localStorage.getItem('userAccount')
        let userPassword = window.localStorage.getItem('userPassword')

        if (userAccount || userPassword) {
            setstate({
                ...state,
                userAccount: userAccount ?? '',
                userPassword: userPassword ?? '',
            })
        }

        setIP(window.returnCitySN?.cip ?? '') //设置ip
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        user.token && history.push('/')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.token])

    const beforeLogin = async (token: string) => {
        let menuArr: string[] = []
        function findMenuAll(array: Auth2MenuDetail[]) {
            array.forEach((item) => {
                item.linkUrl && menuArr.push(item.linkUrl)
                if (item.children && item.children.length > 0) findMenuAll(item.children)
            })
        }

        try {
            //初始页数
            const searchParams = { pageSize: -1, pageNum: 1 }
            let { retData } = await loginServiceAuth2.VerifyInfo({ sourceApp: defaultMdCmParam.sourceApp, token })
            let { menus, resources, roles, shops, ...userInfo } = retData ?? {}

            //用户下没有门店或者门店信息不存在直接返回警告。
            if (!shops || !shops.length) {
                loginServiceAuth2.Logout()
                setLoading(false)
                message.warning('该用户下没有门店信息,请联系后台人员配置门店信息', 5)
                return
            }
            setResourceAuth(resources ?? []) //设置资源列表

            //设置用户菜单所有权限列表,(递归查询到所有要展示得菜单地址。)
            findMenuAll(menus?.[CLIENT_APP_CODE] ?? [])
            setMenuAuth(menuArr)

            //设置用户门店信息
            let { retData: shopRetData } = await mdmService.getMdmShop({ page: searchParams, sourceApp: defaultMdCmParam.sourceApp })
            const records = shopRetData
            if (records && records.length > 0) {
                //拿到外贸所有的门店， 过滤出用户有权限的门店。
                let filterShop = records.filter((item) => shops.indexOf(item.code) > -1)

                if (!filterShop.length) {
                    loginServiceAuth2.Logout()
                    setLoading(false)
                    message.warning('该用户下没有配置gtm业务系统的门店信息')
                    return
                }

                setShopDs(
                    filterShop.map((item) => ({
                        key: item.id,
                        label: item.name,
                        value: item.id,
                        code: item.code,
                        branch: item.branch,
                    }))
                )

                let setShopModal = Modal.confirm({
                    title: <span style={{ fontSize: '20px', fontWeight: 'bold' }}>请选择你的门店</span>,
                    centered: true,
                    closable: true,
                    icon: <ToolOutlined />,
                    className: 'login-shop-modal',
                    content: (
                        <div className="login-shop-container">
                            {filterShop.map((item) => (
                                <span key={item.id} onClick={() => setShopInfoOrUser(item)}>
                                    {item.name}
                                </span>
                            ))}
                        </div>
                    ),
                    onCancel: () => {
                        loginServiceAuth2.Logout()
                        setLoading(false)
                    },
                })

                const setShopInfoOrUser = (shop: IShopDetail) => {
                    //默认设置用户第一个门店的信息 及门店下面对应的经营实体
                    setBeShopDs(
                        (shop?.details ?? []).map((item) => ({
                            label: item.bentityName ?? '',
                            value: item.bentityCode ?? '',
                            branch: item.branch ?? '',
                        }))
                    )
                    setUser({
                        ...user,
                        ...userInfo,
                        token,
                        shopId: shop.id,
                        shopName: shop.name,
                        shopCode: shop.code,
                        branch: shop.branch,
                        loginType: 'account',
                    })
                    sessionStorage.setItem('loginType', 'account')
                    loginServiceAuth2.recordAppLoginInfo(CLIENT_APP_CODE, userInfo.userCode)
                    globalPrompt('notification', {
                        type: 'success',
                        title: '登录成功',
                        duration: 3,
                        text: (
                            <p className="bold">
                                <span className="block">
                                    <SmileTwoTone /> {`您好!${userInfo.userName ?? ''}`}
                                </span>
                                欢迎使用库存管理系统
                            </p>
                        ),
                    })
                    setShopModal.destroy()
                }
            }
        } catch (error) {
            setLoading(false)
        }
    }

    // 登录
    const doLogin = debounce(async () => {
        try {
            if (!state.userAccount) {
                message.warning('请输入账号')
                setLoading(false)
                return
            }
            if (!state.userPassword) {
                setLoading(false)
                message.warning('请输入密码')
                return
            }
            // debugger;
            let { retData, retMsg } = await loginServiceAuth2.Login(
                {
                    userCode: state.userAccount,
                    userPassword: state.userPassword,
                    token: state.token,
                    sourceApp: defaultMdCmParam.branch,
                },
                { headers: { REALIP: IP } }
            )
            if (retData) {
                setToken(retData)
                beforeLogin(retData)
            } else {
                message.error('登录失败：' + retMsg)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }, 800)

    //记住密码
    const checkout = (e: any) => {
        let checked = e.target.checked
        if (checked) {
            setCheckPwd(true)
            window.localStorage.setItem('userAccount', state.userAccount)
            window.localStorage.setItem('userPassword', state.userPassword)
        } else {
            setCheckPwd(false)
            window.localStorage.removeItem('userAccount')
            window.localStorage.removeItem('userPassword')
        }
    }

    return (
        <div className="container-login">
            <div className="login-warp">
                <div className="bg1">
                    <img src={logoImg} alt="" className="login-logo" />
                    <p className="big-font">库存管理系统</p>
                    <p className="small-font">安全 | 高效 | 便捷</p>
                </div>

                <div className="input-box">
                    <div className="input-title">
                        <span>登录</span>
                    </div>
                    <div className="account form-line">
                        <p>账号</p>
                        <Input
                            type="text"
                            placeholder="请输入账号"
                            value={state.userAccount}
                            onChange={onAccountChange}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    setLoading(true)
                                    !loading && doLogin()
                                }
                            }}
                        />
                    </div>
                    <div className="password form-line">
                        <p>密码</p>
                        <Input
                            type="password"
                            placeholder="请输入密码"
                            value={state.userPassword}
                            onChange={onPasswordChange}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    setLoading(true)
                                    !loading && doLogin()
                                }
                            }}
                        />
                    </div>
                    <div className="login-input-three">
                        <span>
                            <input type="checkbox" defaultChecked={checkPwd} onClick={checkout} /> 记住密码
                        </span>
                    </div>
                    <p
                        className="login-but"
                        onClick={() => {
                            setLoading(true)
                            doLogin()
                        }}
                    >
                        立即登录
                    </p>

                    {loading && (
                        <div className="caseMarronFonce">
                            <div className="spinner"></div>
                            <div className="spinner-text">登录中。。。</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Login
