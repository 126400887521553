import { useEffect, useRef, useState } from 'react'
import { Button, message } from 'antd'
import { YhFormBox } from '@/components/YhFormBoxVertical'
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef } from '@/components/YhFormBoxVertical/Types'
import useNavModel, { RouterParamType } from '@/model/navModel'
import { getFormValidatorErrText } from '@/utils/utils'
import { YHDetailNav } from '@/components/YHDetailNav'
import { TabsBox } from './TabsBox'
import { useModel } from '../../model'

export const CommoditiesListDetails = ({ mode }) => {
    const { editFormVal, updateEditFormVal, getOne, editData } = useModel()
    const { selectedNavId, replaceNav, getSelectedNavState } = useNavModel(({ selectedNavId }) => [selectedNavId])
    const formRef = useRef<IYhFormRef>()
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType)

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (!Object.keys(editFormVal).length && detailsInfo.mode === 'edit') {
            getOne(detailsInfo.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsInfo])

    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, '2-1')
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current
            ?.validateFields()
            .then(() => {
                editData({ limitUpper: editFormVal.limitUpper, limitLower: editFormVal.limitLower }, detailsInfo.id, editFormVal.materialCode).then((res) => {
                    res && onClose()
                    updateEditFormVal(null)
                    window.sessionStorage.setItem('commoditiesDetails', '')
                })
            })
            .catch((err) => {
                message.warning(getFormValidatorErrText(err))
            })
    }
    //保留缓存及回显表单
    const saveCache = (val: { [key: string]: any }, isFormItem: boolean = true) => {
        isFormItem && formRef.current?.setFieldsValue(val)
        updateEditFormVal(val)
    }

    const onChange = (changedValues: { [key: string]: any }) => {
        saveCache(changedValues, false)
    }
    const onBlur = (e: any) => {
        let name = e.target.id
        let list = {}
        if (!e.target.value) {
            list[name] = 0
            saveCache(list)
        }
    }

    const baseFormItem: IYhFormItemsBox = {
        title: '基础信息',

        style: { padding: '20px', marginBottom: '10px' },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '商品编码',
                fieldName: 'materialCode',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '商品名称',
                fieldName: 'materialName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '商品类型',
                fieldName: 'typeName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '商品品牌',
                fieldName: 'brandName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '单位',
                fieldName: 'unitName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '规格型号',
                fieldName: 'model',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '产地',
                fieldName: 'madeFrom',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '图号',
                fieldName: 'replaceCode',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '最小起订量',
                fieldName: 'minOrderNum',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '备注',
                fieldName: 'note',
                disable: true,
                span: 4,
            },
        ],
    }
    const inventoryFormItem: IYhFormItemsBox = {
        title: '库存信息',

        style: { padding: '20px', marginBottom: '10px' },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '最低下限',
                fieldName: 'limitLower',
                onInputNumBlur: onBlur,
                rules: [{ required: true, message: '请输入最低下限' }],
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '最高上限',
                fieldName: 'limitUpper',
                onInputNumBlur: onBlur,
                rules: [{ required: true, message: '请输入最高上限' }],
                span: 4,
            },
        ],
    }

    const AccountingFromConfig: IYhFormBox<any> = {
        formRef,
        labelAlign: 'right',
        formValues: editFormVal,
        onChange,
        boxs: [baseFormItem, inventoryFormItem],
    }
    const action = (
        <>
            {detailsInfo.status !== 'calculate.finish' && (
                <Button className="ml-10" shape="round" onClick={() => onSubmit()} type="primary" style={{ float: 'right' }}>
                    保存
                </Button>
            )}

            <Button shape="round" onClick={onClose} style={{ float: 'right' }}>
                返回
            </Button>
        </>
    )

    return (
        <>
            <YHDetailNav
                title={
                    <span>
                        商品编码:
                        <span style={{ margin: '0 30px 0 10px', fontWeight: 600, color: '#3e67f4' }}>{editFormVal.materialCode}</span>
                        商品名称:
                        <span style={{ margin: '0 30px 0 10px', fontWeight: 600, color: '#3e67f4' }}>{editFormVal.materialName}</span>
                    </span>
                }
                btns={action}
                style={{ marginBottom: '-5px' }}
            />
            <div style={{ maxHeight: 'calc(100vh - 180px)', overflowY: 'auto', paddingBottom: '20px' }}>
                <YhFormBox {...AccountingFromConfig} />
                <TabsBox mode={detailsInfo.mode} />
            </div>
        </>
    )
}
