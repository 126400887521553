import { ISearchField, ISearchProp } from '../Types'
import { Input, Tooltip } from 'antd'

type PropType = ISearchProp & ISearchField
export const SearchInput = (props: PropType) => {
    return (
        <Tooltip title="我支持回车检索哦">
            <Input
                size={props.size}
                allowClear
                datatype="round"
                placeholder={props.placeholder as string}
                onPressEnter={() => (props.triggerSearch ? props.handleSearch?.() : null)}
                value={props.searchValues?.[props.fieldName] as string}
                onChange={(e) => {
                    props.onChange?.({ [props.fieldName]: e.target.value })
                    // if (props.triggerSearch) props.handleSearch?.();
                    if (props.triggerSearch && !e.target.value) {
                        props.handleSearch?.({ [props.fieldName]: '' })
                    }
                }}
                // style={{width:'214px'}}
                disabled={props.disabled}
            />
        </Tooltip>
    )
}
