import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, DatePicker } from "antd";

export const __FormDatePicker: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            hidden={props.hidden}
        >
            <DatePicker
                format={props?.format ?? "YYYY-MM-DD"}
                showTime={props.showTime}
                disabledTime={props.disabledDateTime}
                disabled={props.disable}
                allowClear
                showNow={true}
            />
        </Form.Item>
    )
}