import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useNavModel from '../model/navModel'
import useGlobalModel from '../model/globalModel'
import { forFind } from '../utils/utils'
import menuData from '../data/menuData.json'
import { Layout, Spin } from 'antd'
import AppMenu from './AppMenu'
import AppHeader from './AppHeader'
import AppNav from './AppNav'
import AppRoute from './AppRoute'
import logo from '@/assets/images/menuLogo.png'

const { Sider, Content } = Layout

const Index = () => {
    const history = useHistory()
    const {
        user,
        cm,
        loadingInfo: { loadingVisible, txt },
        setLoadingInfo,
    } = useGlobalModel()
    const { addNav } = useNavModel()

    //监听在地址栏输入url。
    useEffect(() => {
        history.listen((location, action) => {
            //每次切换页面 关闭全局loading
            setLoadingInfo({ loadingVisible: false, txt: '' })

            if (action === 'POP' && location.pathname !== '/login' && user.token) {
                let findNav = forFind(menuData, location.pathname, 'path', true)
                // console.log(location, action, "监听路有变化", findNav)
                addNav(findNav ? findNav.id : '404')
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout style={{ height: '100vh' }}>
            <Layout>
                <Sider className="layout-sider menu-box" trigger={null} collapsible collapsed={cm.collapsed} width={208}>
                    {/* <div className="layout-sider-shop">{user.shopName}1</div> */}
                    <div className="layout-sider-top">
                        <img alt="" src={logo} />
                        <span>库存管理系统</span>
                    </div>
                    <AppMenu />
                </Sider>
                <Layout className="layout-section">
                    <AppHeader />
                    <AppNav />
                    <Spin tip={txt} spinning={loadingVisible}>
                        <Content>
                            <AppRoute />
                        </Content>
                    </Spin>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default Index
