import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'

//详情
export interface IDetail {
    /**
     * 分支
     */
    branch?: string
    /**
     * ID
     */
    id?: string
    /**
     * 最高下限
     */
    limitLower?: number
    /**
     * 下限紧急度
     */
    limitLowerUrgency?: number
    /**
     * 最低上限
     */
    limitUpper?: number
    /**
     * 上限紧急度
     */
    limitUpperUrgency?: number
    /**
     * 商品编码
     */
    materialCode?: string
    /**
     * 商品名称
     */
    materialName?: string
    /**
     * 门店ID
     */
    shopId?: string
    /**
     * 门店名称
     */
    shopName?: string
    /**
     * 商品类型编码
     */
    typeCode?: string
    /**
     * 商品类型名称
     */
    typeName?: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    result?: string
    offset?: string
    module?: string
    ip?: string
    operationType?: string
    startTime?: string
    endTime?: string
    operator?: string
    searchItem?: {}
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
}

class Service {
    async page(searchVo: ISearch, config: object = {}) {
        const apiUrl = '/ims/material/urgency'
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, config)
    }
    //导出
    async export(searchVo: ISearch) {
        let apiUrl = '/yh-export/material/urgency/export'
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{} })
    }
}

export const service = new Service()
