import React, {FC} from "react";
import {IFormItem} from "../Types";
import {Form, InputNumber} from "antd";

export const __FormInputNumber: FC<IFormItem> = ({ placeholder,rules,labelCol,fieldLabel, fieldName, ...props }) => {
    return (
        <Form.Item
            label={fieldLabel}
            name={fieldName}
            rules={rules}
            labelCol={labelCol}
            
        >
            <InputNumber
                disabled={props.disable}
                defaultValue={0}
                style={{width:'100%'}}
                min={0}
                onBlur={(e) => props.onInputNumBlur?.(e)}
                />
        </Form.Item>
    )
}