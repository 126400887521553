import { useEffect, useRef, useState } from 'react'
import { Button } from 'antd'
import { YhFormBox } from '@/components/YhFormBoxVertical'
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef } from '@/components/YhFormBoxVertical/Types'
import useNavModel, { RouterParamType } from '@/model/navModel'
import { DictCodeEnmu } from '@/types/AppDictEnums'
import { YHDetailNav } from '@/components/YHDetailNav'
import { TabsBox } from './TabsBox'
import { useModel } from '../../model'

export const InventoryCountingDetails = ({ mode }) => {
    const { editFormVal, getOne } = useModel()
    const { selectedNavId, replaceNav, getSelectedNavState } = useNavModel(({ selectedNavId }) => [selectedNavId])
    const formRef = useRef<IYhFormRef>()
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType)

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        getOne(detailsInfo.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, '5-3')
    }

    const baseFormItem: IYhFormItemsBox = {
        title: '基础信息',

        style: { padding: '20px', marginBottom: '10px' },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '盘点单号',
                fieldName: 'stocktakingCode',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '盘点结果',
                fieldName: 'result',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: ' 盘点仓库',
                fieldName: 'warehouseName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: ' 盘点货位',
                fieldName: 'shelfCode',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '盘点类型',
                fieldName: 'typeName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '所属门店',
                fieldName: 'shopName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '开始时间',
                fieldName: 'startTime',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '结束时间',
                fieldName: 'endTime',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '盘点状态',
                fieldName: 'statusName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '制单员',
                fieldName: 'createUser',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '制单时间',
                fieldName: 'createTime',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '备注',
                fieldName: 'note',
                disable: true,
                span: 4,
            },
        ],
    }

    const AccountingFromConfig: IYhFormBox<any> = {
        formRef,
        labelAlign: 'right',
        formValues: editFormVal,
        boxs: [baseFormItem],
    }
    const action = (
        <>
            <Button shape="round" onClick={onClose} style={{ float: 'right' }}>
                返回
            </Button>
        </>
    )

    return (
        <>
            <YHDetailNav
                title={
                    <span>
                        盘点单号:
                        <span style={{ margin: '0 30px 0 10px', fontWeight: 600, color: '#3e67f4' }}>{editFormVal.stocktakingCode}</span>
                        单据状态:
                        <span
                            style={{
                                display: 'inline-block',
                                width: '57px',
                                height: '22px',
                                background:
                                    editFormVal.status === DictCodeEnmu.STOCK_CHECK_STATUS_CREATE
                                        ? 'rgba(82, 216, 242, .2)'
                                        : editFormVal.status === DictCodeEnmu.STOCK_CHECK_STATUS_FINISH
                                        ? 'rgba(65, 101, 227, .3)'
                                        : 'rgba(153, 153, 153, .2)',
                                borderRadius: '7px',
                                fontSize: '13px',
                                color:
                                    editFormVal.status === DictCodeEnmu.STOCK_CHECK_STATUS_CREATE
                                        ? 'rgba(82, 216, 242, 1)'
                                        : editFormVal.status === DictCodeEnmu.STOCK_CHECK_STATUS_FINISH
                                        ? 'rgba(65, 101, 227, 1)'
                                        : 'rgba(153, 153, 153, 1)',
                                lineHeight: '22px',
                                textAlign: 'center',
                                marginLeft: '10px',
                            }}
                        >
                            {editFormVal.statusName}
                        </span>
                    </span>
                }
                btns={action}
                style={{ marginBottom: '-5px' }}
            />
            <div style={{ maxHeight: 'calc(100vh - 180px)', overflowY: 'auto', paddingBottom: '20px' }}>
                <YhFormBox {...AccountingFromConfig} />
                <TabsBox mode={detailsInfo.mode} />
            </div>
        </>
    )
}
