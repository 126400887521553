import { useState } from 'react'
import { useModel } from '../model'
import { useInterval } from 'ahooks'
import { ISearchValue, SearchTypeEnum } from '@/components/YhPageHeader/Types'
import { YhPageHeader } from '@/components/YhPageHeader'
import { exportStatusOptions } from '@/types/TcoDs'
import { Switch } from 'antd'

export function PageHeader() {
    const { searchParam, updateSearchParam, resetSearchParam, loadData } = useModel()
    const [isRefreshing, setIsRefreshing] = useState(true)

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '文件名称',
            fieldName: 'fileName',
            span: 4,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '菜单',
            fieldName: 'menu',
            span: 4,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '状态',
            fieldName: 'status',
            span: 4,
            options: exportStatusOptions,
        },
    ]

    useInterval(() => {
        if (isRefreshing) {
            loadData({ pageNum: 1 }, { ...searchParam })
        }
    }, 5000)

    //控制刷新
    const refresh = () => {
        setIsRefreshing(!isRefreshing)
        if (isRefreshing) {
            loadData({ pageNum: 1 }, { ...searchParam })
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%' }}>
            <YhPageHeader
                title=""
                size="small"
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                triggerSearch
                handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
                handleReset={resetSearchParam}
                style={{ flex: 1 }}
            />
            <div style={{ display: 'flex', alignItems: 'center', height: '58px', backgroundColor: '#fff', padding: '3px 12px', margin: '16px 0 8px 0' }}>
                <Switch checkedChildren="开启自动刷新" unCheckedChildren="关闭自动刷新" checked={isRefreshing} onChange={refresh} />
            </div>
        </div>
    )
}
