import { Switch, Route } from 'react-router-dom'
import { Home } from '../views/home/Home'
import { HomeAll } from '../views/home/HomeAll'

//商品管理
import Error404 from '../views/404'

// 商品管理
import { CommoditiesList } from '../views/commodities/commoditiesList/view'
import { CommoditiesListDetails } from '../views/commodities/commoditiesList/view/details'

// 出入库单
import { OutWarehouseList } from '../views/receipt/outWarehouse/view'
import { OutWarehouseListDetails } from '../views/receipt/outWarehouse/view/details'
import { EnterWarehouse } from '../views/receipt/enterWarehouse/view'
import { EnterWarehouseListDetails } from '../views/receipt/enterWarehouse/view/details'
import { OutWarehouseDetailsList } from '../views/receipt/outWarehouseDetail/view'
import { EnterWarehouseDetailsList } from '../views/receipt/enterWarehouseDetail/view'
import { Unfinished } from '../views/receipt/unfinished/view'
import { Pending } from '../views/receipt/pending/view'

// 库存记录
import { InventoryStatistics } from '../views/inventory/inventoryStatistics/view'
import { InventoryDetails } from '../views/inventory/inventoryDetails/view'
import { InventoryCounting } from '../views/inventory/inventoryCounting/view'
import { InventoryCountingDetails } from '../views/inventory/inventoryCounting/view/details'
import { InventoryWarning } from '../views/inventory/inventoryWarning/view'

// 统计分析
import { SalesInventoryScale } from '../views/statistics/salesInventoryScale/view'
import { OverstockDetail } from '../views/statistics/overstockDetail/view'
import { OverstockCollect } from '../views/statistics/overstockCollect/view'
import { PurchaseSalesInventory } from '../views/statistics/purchaseSalesInventory/view'

// 导出记录
import { ExportRecord } from '../views/exportrecord/view'

//数据字典
import { BaseDict } from '../views/base/dict/view'

function AppRoute() {
    return (
        <Switch>
            <Route exact={true} path={'/'} key="1" component={Home} />
            <Route exact={true} path={'/homeAll'} key="10" component={HomeAll} />

            {/* 商品管理 */}
            <Route exact={true} path={'/commodities/commoditiesList'} key="2-1" component={CommoditiesList} />
            <Route exact={true} path={'/commodities/commoditiesList/details'} key="2-1-1" component={CommoditiesListDetails} />

            {/* 出入库单 */}
            <Route exact={true} path={'/receipt/outWarehouse'} key="3-1" component={OutWarehouseList} />
            <Route exact={true} path={'/receipt/outWarehouse/details'} key="3-1-1" component={OutWarehouseListDetails} />
            <Route exact={true} path={'/receipt/enterWarehouse'} key="3-2" component={EnterWarehouse} />
            <Route exact={true} path={'/receipt/enterWarehouse/details'} key="3-2-1" component={EnterWarehouseListDetails} />
            <Route exact={true} path={'/receipt/outWarehouseDetail'} key="3-3" component={OutWarehouseDetailsList} />
            <Route exact={true} path={'/receipt/enterWarehouseDetail'} key="3-4" component={EnterWarehouseDetailsList} />
            <Route exact={true} path={'/receipt/unfinished'} key="3-5" component={Unfinished} />
            <Route exact={true} path={'/receipt/pending'} key="3-6" component={Pending} />

            {/* 库存记录 */}
            <Route exact={true} path={'/inventory/inventoryStatistics'} key="5-1" component={InventoryStatistics} />
            <Route exact={true} path={'/inventory/inventoryDetails'} key="5-2" component={InventoryDetails} />
            <Route exact={true} path={'/inventory/inventoryCounting'} key="5-3" component={InventoryCounting} />
            <Route exact={true} path={'/inventory/inventoryCounting/details'} key="5-3-1" component={InventoryCountingDetails} />
            <Route exact={true} path={'/inventory/inventoryWarning'} key="5-2" component={InventoryWarning} />

            {/* 统计分析 */}
            <Route exact={true} path={'/statistics/salesInventoryScale'} key="6-1" component={SalesInventoryScale} />
            <Route exact={true} path={'/statistics/overstockDetail'} key="6-2" component={OverstockDetail} />
            <Route exact={true} path={'/statistics/overstockCollect'} key="6-3" component={OverstockCollect} />
            <Route exact={true} path={'/statistics/purchaseSalesInventory'} key="6-4" component={PurchaseSalesInventory} />

            {/* 导出记录 */}
            <Route exact={true} path={'/exportRecord'} key="7" component={ExportRecord} />

            {/* 数据字典 */}
            <Route exact={true} path={'/base/dict'} key="8-1" component={BaseDict} />

            <Route exact={true} path={'/*'} key="404" component={Error404} />
        </Switch>
    )
}

export default AppRoute
