import { httpRequest } from '@/utils/HttpRequest'
export interface StatisticsVo {
    /**
     * 库存金额
     */
    inventoryAmount?: number | null
    /**
     * 库存数量
     */
    //库存上线
    inventoryLimitUpper?: number | null
    //库存下限
    inventoryLimitLower?: number | null
    //库存积压
    inventoryDeadStock?: number | null
    inventoryQuantity?: number | null
    [property: string]: any
}

export interface PendingVo {
    /**
     * 待审核数量
     */
    auditNum?: number | null
    /**
     * 待入库数量
     */
    stockInNum?: number | null
    /**
     * 待出库数量
     */
    stockOutNum?: number | null
    [property: string]: any
}

export interface SetUpDetail {
    code?: null | string
    createTime?: null | string
    createUser?: null | string
    deleteFlag?: number | null
    /**
     * 图标
     */
    icon?: null | string
    id?: null | string
    /**
     * 菜单名称
     */
    name?: null | string
    orderNum?: number | null
    /**
     * 路径
     */
    path?: null | string
    traceId?: null | string
    updateTime?: null | string
    updateUser?: null | string
    /**
     * url
     */
    url?: null | string
    /**
     * 账户名
     */
    userCode?: null | string
    [property: string]: any
}
export interface IiconList {
    code: string
    name: string
    icon: string
    url: string
    select?: boolean
}

export interface StockStatisticsVo {
    /**
     * 总单据数量
     */
    allNum?: number | null
    /**
     * 总入库金额
     */
    allStockIoAmount?: number | null
    /**
     * 总入库数量
     */
    allStockIoNum?: number | null
    /**
     * 入库明细
     */
    detailList?: StockStatisticsDetailVo[] | null
    [property: string]: any
}
export interface StockStatisticsDetailVo {
    /**
     * 单据数量
     */
    num?: number | null
    /**
     * 入库金额
     */
    stockIoAmount?: number | null
    /**
     * 入库数量
     */
    stockIoNum?: number | null
    /**
     * 日期
     */
    time?: null | string
    [property: string]: any
}
export interface StockStatisticsDetailAll {
    isInit?: boolean

    dates?: string[]

    inallNum?: number | null
    inallStockIoNum?: number | null
    inallStockIoAmount?: number | null
    indetailList?: StockStatisticsDetailVo[] | null

    outallNum?: number | null
    outallStockIoNum?: number | null
    outallStockIoAmount?: number | null
    outdetailList?: StockStatisticsDetailVo[] | null
}

export interface AllWarehouseStatisticsVo {
    /**
     * 门店名称
     */
    shopName?: null | string
    /**
     * 仓库统计
     */
    warehouseDetailVos?: WarehouseDetailVo[] | null
    [property: string]: any
}
export interface WarehouseDetailVo {
    /**
     * 库存金额
     */
    amount?: number | null
    /**
     * 库存数量
     */
    num?: number | null
    /**
     * 门店名称
     */
    shopName?: null | string
    /**
     * 仓库名称
     */
    warehouseName?: null | string
    [property: string]: any
}
export interface AllWarehouseStatisticsAll {
    isInit?: boolean

    shops?: string[]

    warehouses?: { name: string; info: { num: number; amount: number; isHas: boolean }[] }[]
}

export interface AllUnfinishedStatisticsVo {
    /**
     * 门店名称
     */
    shopName?: null | string
    /**
     * 仓库统计
     */
    unfinishedDetailVos?: UnfinishedDetailVo[] | null
    [property: string]: any
}

/**
 * yh.ims.homePage.vo.AllUnfinishedVo.UnfinishedDetailVo
 *
 * UnfinishedDetailVo
 */
export interface UnfinishedDetailVo {
    /**
     * 采购数量
     */
    pnum?: number | null
    /**
     * 采退数量
     */
    prNum?: number | null
    /**
     * 门店名称
     */
    shopName?: null | string
    /**
     * 销售数量
     */
    snum?: number | null
    /**
     * 销退数量
     */
    srNum?: number | null
    [property: string]: any
}
export interface AllUnfinishedStatisticsAll {
    isInit?: boolean

    shops?: string[]

    statistics?: { pnum: number; prNum: number; snum: number; srNum: number }[]
}

export interface WarehouseStatisticsVo {
    isInit?: boolean
    /**
     * 数量
     */
    num?: number | null
    /**
     * 仓库名称
     */
    warehouseName?: null | string
    [property: string]: any
}
export interface WarehouseStatisticsAll {
    isInit?: boolean

    names?: string[]

    infos?: { value: number; name: string; amount: number }[]
}

export interface UnfinishedStatisticsVo {
    /**
     * 采购数量
     */
    pNum?: number | null
    /**
     * 采退数量
     */
    prNum?: number | null
    /**
     * 销售数量
     */
    sNum?: number | null
    /**
     * 销退数量
     */
    srNum?: number | null
    [property: string]: any
}
export interface UnfinishedStatisticsAll {
    isInit?: boolean

    names?: string[]

    infos?: { value: number; name: string }[]
}

class HomeService {
    // 统计查询
    async statistics(data: { shopId?: string; branch?: string }) {
        const apiUrl = `/ims/homePage/inventory/statistics`
        return await httpRequest.post<StatisticsVo>(apiUrl, data)
    }
    // 首页待处理统计
    async pending(data: { shopId?: string; branch?: string }) {
        const apiUrl = `/ims/homePage/pending`
        return await httpRequest.post<PendingVo>(apiUrl, data)
    }
    // 快捷方式设置查询
    async findByUserCode(data: { userCode: string }) {
        const apiUrl = `/ims/setUp/findByUserCode`
        return await httpRequest.post<SetUpDetail>(apiUrl, data)
    }
    // 快捷方式设置新增、修改
    async update(data: { userCode: string; details: SetUpDetail[] }) {
        const apiUrl = `/ims/setUp/update`
        return await httpRequest.post<SetUpDetail>(apiUrl, data)
    }
    // 首页入库单统计
    async inStockStatistics(data: { shopId?: string; startTime?: string; endTime?: string }) {
        const apiUrl = `/ims/homePage/inStockStatistics`
        return await httpRequest.post<StockStatisticsVo>(apiUrl, data)
    }
    // 首页出库单统计
    async outStockStatistics(data: { shopId?: string; startTime?: string; endTime?: string }) {
        const apiUrl = `/ims/homePage/outStockStatistics`
        return await httpRequest.post<StockStatisticsVo>(apiUrl, data)
    }
    // 首页仓库数据统计
    async allWarehouseStatistics(data: { branch?: string; startTime?: string; endTime?: string }) {
        const apiUrl = `/ims/homePage/allWarehouseStatistics`
        return await httpRequest.post<AllWarehouseStatisticsVo>(apiUrl, data)
    }
    // 首页未闭环单据统计
    async allUnfinishedStatistics(data: { branch?: string; startTime?: string; endTime?: string }) {
        const apiUrl = `/ims/homePage/allUnfinishedStatistics`
        return await httpRequest.post<AllUnfinishedStatisticsVo>(apiUrl, data)
    }
    // 首页分门店版本仓库统计
    async warehouseStatistics(data: { shopId?: string }) {
        const apiUrl = `/ims/homePage/warehouseStatistics`
        return await httpRequest.post<WarehouseStatisticsVo>(apiUrl, data)
    }
    // 首页分门店版本未闭环单据统计
    async unfinishedStatistics(data: { shopId?: string }) {
        const apiUrl = `/ims/homePage/unfinishedStatistics`
        return await httpRequest.post<UnfinishedStatisticsVo>(apiUrl, data)
    }
}

export const homeService = new HomeService()
