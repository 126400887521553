import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, TreeSelect } from "antd";
import { ITreeSelectData } from "@/types/AppTypes";

export const __FormTreeSelect: FC<IFormItem> = ({ placeholder, fieldLabel, fieldName, ...props }) => {
    return (
        <Form.Item
            label={fieldLabel}
            name={fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <TreeSelect
                placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
                treeDefaultExpandAll
                allowClear={props.allowClear}
                treeData={props.treeData}
                disabled={props.disable}
                onSelect={(value: string, option) => props.onTreeSelectChange?.(value, option as ITreeSelectData)}
                showSearch
                treeNodeFilterProp='title'
            />
        </Form.Item>
    )
}