import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, Input } from "antd";

const { TextArea } = Input

export const __FormTextArea: FC<IFormItem> = ({ placeholder, fieldLabel, fieldName, ...props }) => {
    return (
        <Form.Item
            label={fieldLabel}
            name={fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}
        >
            <TextArea
                disabled={props.disable}
                allowClear={props.allowClear}
                placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
                prefix={props.prefix}
                autoSize={{ minRows: 2, maxRows: 6 }}
                onBlur={() => props.onInputBlur?.(fieldName)}
            />
        </Form.Item>
    )
}