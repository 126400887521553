import { useEffect } from 'react'
import styled from '@emotion/styled'
import HeaderIcon1 from '@/assets/images/index-header-icon1.png'
import HeaderIcon2 from '@/assets/images/index-header-icon2.png'
import HeaderIcon3 from '@/assets/images/index-header-icon3.png'
import HeaderIcon4 from '@/assets/images/index-header-icon4.png'
import HeaderIcon5 from '@/assets/images/index-header-icon5.png'
import { useHomeModel } from './homeModel'
import { moneyFormat } from '@/utils/utils'

export function Header(props) {
    const { statistics, getStatistics } = useHomeModel()

    useEffect(() => {
        getStatistics(props.type)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="index-header">
            <Item className="statistics-item" style={{ background: 'linear-gradient(90deg, #3985FF 0%, #6AA1FF 100%)' }}>
                <img src={HeaderIcon1} alt="" />
                <div className="index-header-title">库存数量</div>
                <div className="index-header-content">{moneyFormat(statistics?.inventoryQuantity || 0, 0)}</div>
            </Item>
            <Item className="statistics-item" style={{ background: 'linear-gradient(90deg, #2CCA93 0%, #36DBA6 100%)' }}>
                <img src={HeaderIcon2} alt="" />
                <div className="index-header-title">库存金额</div>
                <div className="index-header-content">
                    ￥
                    {statistics?.inventoryAmount && (statistics?.inventoryAmount + '').indexOf('.') !== -1
                        ? (statistics?.inventoryAmount + '').split('.')[0]
                        : statistics?.inventoryAmount + ''}
                    <span>
                        .
                        {(statistics?.inventoryAmount && (statistics?.inventoryAmount + '').indexOf('.') !== -1 ? statistics?.inventoryAmount + '' : '0.00')
                            .split('.')[1]
                            .substring(0, 2)}
                    </span>
                </div>
            </Item>
            <Item className="statistics-item" style={{ background: 'linear-gradient(90deg, #FFA233 0%, #FBB61B 100%)' }}>
                <img src={HeaderIcon3} alt="" />
                <div className="index-header-title">库存上限预警</div>
                <div className="index-header-content">{moneyFormat(statistics?.inventoryLimitUpper || 0, 0)}</div>
            </Item>
            <Item className="statistics-item" style={{ background: 'linear-gradient(90deg, #6C6CFC 0%, #6161F8 100%)' }}>
                <img src={HeaderIcon4} alt="" />
                <div className="index-header-title">库存下限预警</div>
                <div className="index-header-content">{moneyFormat(statistics?.inventoryLimitLower || 0, 0)}</div>
            </Item>
            <Item className="statistics-item" style={{ background: 'linear-gradient(90deg, #FF5252 0%, #FF7954 100%)' }}>
                <img src={HeaderIcon5} alt="" />
                <div className="index-header-title">积压预警</div>
                <div className="index-header-content">{moneyFormat(statistics?.inventoryDeadStock || 0, 0)}</div>
            </Item>
        </div>
    )
}

const Item = styled.div`
    flex: 1;
    height: 100px;
    padding: 24px 0 0 30px;
    margin-right: 18px;
    border-radius: 8px;
    position: relative;
    &:last-child {
        margin-right: 0;
    }
    img {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 50px;
        height: 48px;
        opacity: 0.2;
        transform: translateY(-25px);
    }
    .index-header-title {
        font-size: 14px;
        color: #fff !important;
        line-height: 20px;
    }
    .index-header-content {
        font-size: 24px;
        color: #fff !important;
        line-height: 32px;
        span {
            font-size: 14px;
        }
    }
`
