import { useModel } from '../model'
import { ISearchValue, SearchTypeEnum } from '@/components/YhPageHeader/Types'
import { YhPageHeader } from '@/components/YhPageHeader'

export function PageHeader() {
    const { searchParam, updateSearchParam, resetSearchParam, loadData } = useModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '商品编码',
            fieldName: 'materialCode',
            span: 4,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '商品名称',
            fieldName: 'materialName',
            span: 4,
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: '',
            title: '出入库时间段',
            rangePickerFieldName: ['startTime', 'endTime'],
            placeholder: ['出入库开始时间', '出入库结束时间'],
            format: 'YYYY-MM-DD',
            span: 5,
        },
    ]

    return (
        <YhPageHeader
            title=""
            size="small"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    )
}
