import React, { FC } from 'react'
import { Row } from 'antd'
import { __FormItem as FormItem } from './item'
import { IYhFormItemsBox, ISelectOption } from './Types'
import { useState } from 'react'
import FontIcon from '../YhIcon'
import { UpOutlined } from '@ant-design/icons'

export const __FormItemsBox: FC<IYhFormItemsBox> = ({ items, ...props }) => {
    const [isOpne, setIsopen] = useState(false)
    return (
        <div className="yh-form-box" style={{ height: isOpne ? '55px' : 'auto', ...props.style }}>
            {props.isShowHeader && (
                <div className="yh-form-box-header">
                    <div className="yh-form-box-header-title">{props?.title}</div>
                    <div className="yh-form-box-header-btn">
                        {props?.rightTitle}
                        <span
                            onClick={() => {
                                setIsopen(!isOpne)
                            }}
                        >
                            {!isOpne ? (
                                <>
                                    收起 <UpOutlined />
                                </>
                            ) : (
                                <>
                                    展开 <FontIcon type="icon-zhankai"></FontIcon>
                                </>
                            )}
                        </span>
                    </div>
                </div>
            )}
            <Row gutter={[40, 0]} justify="start">
                {items.map((el, index) => (
                    <FormItem
                        key={index}
                        {...el}
                        disable={el.disable ?? props.disabled}
                        copyText={(fieldName: string, option?: ISelectOption[]) => props?.copyText?.(fieldName, option as ISelectOption[])}
                    />
                ))}
            </Row>
        </div>
    )
}
