//是否占用
export const isOccupyOptions = [
    { label: '是', value: 1 },
    { label: '否', value: 0 as any },
]
export const isOccupyOptions1 = [
    { label: '是', value: 0 },
    { label: '否', value: null as any },
]
//是否积压
export const isOverStockOptions = [
    { label: '是', value: 1 },
    { label: '否', value: 0 as any },
]
//是否投放
export const isTfFlagOptions = [
    { label: '是', value: 1 },
    { label: '否', value: 0 as any },
]
//待处理事务状态
export const pendingOptions = [
    { label: '待入库', value: 'in' },
    { label: '待出库', value: 'out' },
    { label: '待审核', value: 'audit' },
    { label: '调拨待调入', value: 'allotIn' },
    { label: '调拨待调出', value: 'allotOut' },
]
//门店类型
export const shopTypeOptions = [
    { label: '分门店', value: 'one' },
    { label: '总门店', value: 'all' },
]
//导出记录状态
export const exportStatusOptions = [
    { label: '进行中', value: 'processing' },
    { label: '完成', value: 'success' },
    { label: '失败', value: 'fail' },
]
