import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'

//详情
export interface IDetail {
    /**
     * 分支
     */
    branch?: string
    /**
     * 单号
     */
    code?: string
    /**
     * 制单时间
     */
    createTime?: string
    /**
     * 制单员
     */
    createUser?: string
    /**
     * 门店ID
     */
    shopId?: string
    /**
     * 门店名称
     */
    shopName?: string
    /**
     * 单据状态
     */
    status?: string
    /**
     * 单据类型
     */
    stockType?: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    code?: string
    status?: any
    type?: any
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    stockType?: string
}

class Service {
    async page(searchVo: ISearch, config: object = {}) {
        const apiUrl = '/ims/homePage/pendingDeatil'
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, config)
    }
    //导出
    async export(searchVo: ISearch) {
        let apiUrl = '/yh-export/pending/export'
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{} })
    }
}

export const service = new Service()
