import { importPropsConfig } from '@/utils/importFile'
import { Modal, Select, Upload } from 'antd'
import FontIcon from '../YhIcon'
import './index.less'

const Typebs = {
    cover: '覆盖',
}

export const YHImport = ({
    visible,
    setVisible,
    onCancel,
    downloadTemlpate,
    upLoadCallback,
    importUrl,
    exportFile,
}: {
    visible: boolean
    setVisible: (boolean) => void
    onCancel: () => void
    downloadTemlpate: () => void
    exportFile: () => void
    upLoadCallback: (retData: any) => void
    importUrl: string
}) => {
    return (
        <Modal
            open={visible}
            width="818px"
            footer={null}
            title="批量导入"
            onCancel={() => {
                setVisible(false)
                onCancel?.()
            }}
        >
            <div className="import-item">
                <div className="import-item-icon-box">
                    <FontIcon className="import-item-icon" type="icon-shibiedaoru" />
                </div>
                <div className="important-item-main">
                    <div className="important-item-main-title">填写导入产品的信息</div>
                    <div className="important-item-main-info">请按照数据模板的格式准备导入数据，模板中</div>
                    <div style={{ display: 'flex' }}>
                        <div className="important-item-main-btn mr-10 ml-10" onClick={downloadTemlpate}>
                            下载模板
                        </div>
                        <div className="important-item-main-btn mr-10 ml-10" onClick={exportFile}>
                            导出数据
                        </div>
                    </div>
                </div>
            </div>
            <div className="import-item">
                <div className="import-item-icon-box">
                    <FontIcon className="import-item-icon" type="icon-shibiedaoru" />
                </div>
                <div className="important-item-main">
                    <div className="important-item-main-title">上传填好的信息表</div>
                    <div className="important-item-main-info">文件后缀名必须为xls 或xlsx （即</div>

                    <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(importUrl, upLoadCallback)}>
                        <div className="important-item-main-btn">上传文件</div>
                    </Upload>
                </div>
            </div>
            <div className="import-item">
                <div className="import-item-icon-box">
                    <FontIcon className="import-item-icon" type="icon-shibiedaoru" />
                </div>
                <div className="important-item-main">
                    <div className="important-item-main-title">选择数据重复时的处理方式</div>
                    <Select style={{ width: '200px', marginTop: '12px' }} placeholder="请选择方式" value={Object.keys(Typebs)[0]}>
                        {Object.entries(Typebs).map((item) => (
                            <Select.Option value={item[0]} key={item[0]}>
                                {item[1]}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
        </Modal>
    )
}
