import { useState } from 'react'
import { createModel } from 'hox'
import useGlobalModel from '@/model/globalModel'
import {
    homeService,
    StatisticsVo,
    PendingVo,
    IiconList,
    StockStatisticsDetailAll,
    AllWarehouseStatisticsAll,
    AllWarehouseStatisticsVo,
    WarehouseDetailVo,
    AllUnfinishedStatisticsAll,
    AllUnfinishedStatisticsVo,
    WarehouseStatisticsVo,
    WarehouseStatisticsAll,
    UnfinishedStatisticsAll,
} from './homeService'
import { globalPrompt } from '@/components/message'
import moment from 'moment'

export const useHomeModel = createModel(function () {
    const {
        user: { shopId, branch },
    } = useGlobalModel()

    //统计模块
    const [statistics, setStatistics] = useState<StatisticsVo | null>({
        inventoryQuantity: 0,
        inventoryAmount: 0,
        inventoryLimitUpper: 0,
        inventoryLimitLower: 0,
        inventoryDeadStock: 0,
    })
    const getStatistics = async (type: string = 'all') => {
        let _data: { branch: string; shopId?: string } = { branch }
        if (type === 'one') {
            _data.shopId = shopId
        }
        let { retData } = await homeService.statistics(_data)
        setStatistics(retData)
    }

    //首页待处理统计模块
    const [pending, setPending] = useState<PendingVo | null>(null)
    const getPending = async (type: string = 'all') => {
        let _data: { branch: string; shopId?: string } = { branch }
        if (type === 'one') {
            _data.shopId = shopId
        }
        let { retData } = await homeService.pending(_data)
        setPending(retData)
    }

    //首页快捷方式模块
    const [iconList, setIconList] = useState<IiconList[]>([])
    const [iconListState, setIconListState] = useState('3')
    const iconListDefault = [
        {
            code: 'jingyingshiti',
            name: '商品管理',
            icon: '#icon-jingyingshiti',
            url: '2-1',
            select: false,
        },
        {
            code: 'chukudanju',
            name: '出库单据',
            icon: '#icon-chukudanju',
            url: '3-1',
            select: false,
        },
        {
            code: 'rukudanju',
            name: '入库单据',
            icon: '#icon-rukudanju',
            url: '3-2',
            select: false,
        },
        {
            code: 'chukudanju1',
            name: '出库单据明细',
            icon: '#icon-chukudanju1',
            url: '3-3',
            select: false,
        },
        {
            code: 'rukudanju1',
            name: '入库单据明细',
            icon: '#icon-rukudanju1',
            url: '3-4',
            select: false,
        },
        {
            code: 'kucuntongji2',
            name: '库存统计',
            icon: '#icon-kucuntongji2',
            url: '5-1',
            select: false,
        },
        {
            code: 'kucunmingxi',
            name: '库存明细',
            icon: '#icon-kucunmingxi',
            url: '5-2',
            select: false,
        },
        {
            code: 'kucunpandian1',
            name: '库存盘点',
            icon: '#icon-kucunpandian1',
            url: '5-3',
            select: false,
        },
        {
            code: 'kucuntongji1',
            name: '库存预警',
            icon: '#icon-kucuntongji1',
            url: '5-4',
            select: false,
        },
        {
            code: 'xiaocunshuaitongji',
            name: '销存率统计',
            icon: '#icon-xiaocunshuaitongji',
            url: '6-1',
            select: false,
        },
        {
            code: 'cangkuguanli1',
            name: '积压统计明细',
            icon: '#icon-cangkuguanli1',
            url: '6-2',
            select: false,
        },
        {
            code: 'xiaoshoudan',
            name: '积压统计汇总',
            icon: '#icon-xiaoshoudan',
            url: '6-3',
            select: false,
        },
        {
            code: 'jinxiaocuntongji',
            name: '进销存统计',
            icon: '#icon-jinxiaocuntongji',
            url: '6-4',
            select: false,
        },
    ]
    const getQuickEntrance = async () => {
        let { retData } = await homeService.findByUserCode({ userCode: 'quickEntrance' })
        if (retData && retData.length !== 0) {
            let _list: IiconList[] = []
            iconListDefault.forEach((item) => {
                retData.forEach((icon: IiconList) => {
                    if (icon.code === item.code) {
                        _list.push(item)
                    }
                })
            })
            setIconList(_list)
            setIconListState('2')
        } else {
            setIconList([])
            setIconListState('1')
        }
    }
    const upDateQuickEntrance = async (list: IiconList[]) => {
        await homeService.update({ userCode: 'quickEntrance', details: list })
        globalPrompt('message', { text: '编辑成功', type: 'success' })
        setOpen(false)
        getQuickEntrance()
    }
    const [open, setOpen] = useState(false)
    const [iconModalList, setIconModalList] = useState<IiconList[]>([])
    const showModal = () => {
        let _list: IiconList[] = []
        iconListDefault.forEach((item) => {
            if (iconList && iconList.length !== 0) {
                iconList.forEach((icon: IiconList) => {
                    if (icon.code === item.code) {
                        item.select = true
                    }
                })
            }
            _list.push(item)
        })
        setIconModalList(_list)
        setOpen(true)
    }
    const handleSelectIcon = (item: IiconList) => {
        let _list: IiconList[] = []
        let isFull: boolean = false
        isFull = iconModalList.reduce((x, y) => x + (y.select ? 1 : 0), 0) >= 9
        if (!item.select && isFull) {
            globalPrompt('message', { text: '最多设置9个快捷方式', type: 'error' })
            return false
        }
        iconModalList.forEach((icon) => {
            if (icon.code === item.code) {
                icon.select = !icon.select
            }
            _list.push(icon)
        })
        setIconModalList(_list)
    }
    const handleOk = (e: React.MouseEvent<HTMLElement>) => {
        let _list: IiconList[] = []
        iconModalList.forEach((item) => {
            if (item.select) {
                _list.push(item)
            }
        })
        upDateQuickEntrance(_list)
    }
    const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
        setOpen(false)
    }

    //返回所有区间时间段
    const changeDate = (start: string, end: string) => {
        const startDate = moment(start)
        const endDate = moment(end)

        const datesInRange: string[] = []
        let currentDate = startDate

        while (currentDate.isSameOrBefore(endDate)) {
            datesInRange.push(currentDate.format('YYYY-MM-DD'))
            currentDate = currentDate.add(1, 'day')
        }
        return datesInRange
    }
    //出入库统计图
    const [stockStatisticsStart, setStockStatisticsStart] = useState('')
    const [stockStatisticsEnd, setStockStatisticsEnd] = useState('')
    const [stockStatisticsTab, setStockStatisticsTab] = useState('')
    const [stockStatisticsType, setStockStatisticsType] = useState('')
    const [stockStatisticsLoading, setStockStatisticsLoading] = useState(true)
    const [stockStatisticsDetails, setStockStatisticsDetails] = useState<StockStatisticsDetailAll>({ isInit: true })
    const handleStockStatisticsTab = (type: string) => {
        if (!type) {
            setStockStatisticsTab('')
            return
        }
        if (type === 'all') {
            setStockStatisticsTab('')
            getStockStatistics('', '')
            return
        }

        let _startTime = ''
        let _endTime = ''
        if (type === 'day') {
            _startTime = moment().format('YYYY-MM-DD')
            _endTime = moment().format('YYYY-MM-DD')
        }
        if (type === 'week') {
            _startTime = moment().startOf('week').format('YYYY-MM-DD')
            _endTime = moment().endOf('week').format('YYYY-MM-DD')
        }
        if (type === 'month') {
            _startTime = moment().startOf('month').format('YYYY-MM-DD')
            _endTime = moment().endOf('month').format('YYYY-MM-DD')
        }
        setStockStatisticsStart(_startTime)
        setStockStatisticsEnd(_endTime)
        setStockStatisticsTab(type)
        getStockStatistics(_startTime, _endTime)
    }
    const getStockStatistics = async (startTime: string, endTime: string) => {
        setStockStatisticsLoading(true)

        let _data = {
            shopId: stockStatisticsType === 'one' ? shopId : undefined,
            startTime: startTime ? startTime + ' 00:00:00' : '',
            endTime: endTime ? endTime + ' 23:59:59' : '',
        }
        const { retData: inRes } = await homeService.inStockStatistics(_data)
        const { retData: outRes } = await homeService.outStockStatistics(_data)
        let resData = {
            dates: changeDate(startTime, endTime),

            inallNum: inRes.allNum || 0,
            inallStockIoNum: inRes.allStockIoNum || 0,
            inallStockIoAmount: inRes.allStockIoAmount || 0,
            indetailList: inRes.detailList || [],

            outallNum: outRes.allNum || 0,
            outallStockIoNum: outRes.allStockIoNum || 0,
            outallStockIoAmount: outRes.allStockIoAmount || 0,
            outdetailList: outRes.detailList || [],
        }

        setStockStatisticsDetails(resData)
        setStockStatisticsLoading(false)
    }
    const handleStockStatisticsDate = (dates: any) => {
        if (!dates) {
            getStockStatistics('', '')
            return
        }
        let _startTime = moment(dates![0]).format('YYYY-MM-DD')
        let _endTime = moment(dates![1]).format('YYYY-MM-DD')
        setStockStatisticsStart(_startTime)
        setStockStatisticsEnd(_endTime)
        getStockStatistics(_startTime, _endTime)
    }

    //数组去重
    const arrayNonRepeatfy = (arr: any[], name: string) => {
        const newArr = arr.reduce((prev, element) => {
            if (!prev.find((el: any) => el[name] === element[name])) {
                prev.push(element)
            }
            return prev
        }, [])
        return newArr
    }
    //首页仓库数据统计
    const [allWarehouseStatisticsStart, setAllWarehouseStatisticsStart] = useState('')
    const [allWarehouseStatisticsEnd, setAllWarehouseStatisticsEnd] = useState('')
    const [allWarehouseStatisticsTab, setAllWarehouseStatisticsTab] = useState('')
    const [allWarehouseStatisticsBranch, setAllWarehouseStatisticsBranch] = useState('scm')
    const [allWarehouseStatisticsLoading, setAllWarehouseStatisticsLoading] = useState(true)
    const [allWarehouseStatisticsDetails, setAllWarehouseStatisticsDetails] = useState<AllWarehouseStatisticsAll>({ isInit: true })
    const handleAllWarehouseStatisticsTab = (type: string) => {
        if (!type) {
            setAllWarehouseStatisticsTab('')
            return
        }
        if (type === 'all') {
            setAllWarehouseStatisticsTab('')
            getAllWarehouseStatistics(allWarehouseStatisticsBranch, '', '')
            return
        }

        let _startTime = ''
        let _endTime = ''
        if (type === 'day') {
            _startTime = moment().format('YYYY-MM-DD')
            _endTime = moment().format('YYYY-MM-DD')
        }
        if (type === 'week') {
            _startTime = moment().startOf('week').format('YYYY-MM-DD')
            _endTime = moment().endOf('week').format('YYYY-MM-DD')
        }
        if (type === 'month') {
            _startTime = moment().startOf('month').format('YYYY-MM-DD')
            _endTime = moment().endOf('month').format('YYYY-MM-DD')
        }
        setAllWarehouseStatisticsStart(_startTime)
        setAllWarehouseStatisticsEnd(_endTime)
        setAllWarehouseStatisticsTab(type)
        getAllWarehouseStatistics(allWarehouseStatisticsBranch, _startTime, _endTime)
    }
    const getAllWarehouseStatistics = async (branch: string, startTime: string, endTime: string) => {
        setAllWarehouseStatisticsLoading(true)

        let _data = {
            branch,
            startTime: startTime ? startTime + ' 00:00:00' : '',
            endTime: endTime ? endTime + ' 23:59:59' : '',
        }
        const { retData } = await homeService.allWarehouseStatistics(_data)
        let warehouses: any[] = []
        if (retData?.length !== 0) {
            retData.forEach((shop: AllWarehouseStatisticsVo) => {
                if (shop.warehouseDetailVos?.length !== 0) {
                    shop.warehouseDetailVos?.forEach((warehouse: WarehouseDetailVo) => {
                        warehouses.push({
                            name: warehouse.warehouseName,
                            info: retData.map(() => {
                                return { num: 0, amount: 0,isHas:false }
                            }),
                        })
                    })
                }
            })
            if (warehouses?.length !== 0) {
                warehouses = arrayNonRepeatfy(warehouses, 'name')
                retData.forEach((shop: AllWarehouseStatisticsVo, index) => {
                    if (shop.warehouseDetailVos?.length !== 0) {
                        shop.warehouseDetailVos?.forEach((warehouse: WarehouseDetailVo) => {
                            warehouses.forEach((ware) => {
                                if (warehouse.warehouseName === ware.name) {
                                    ware.info[index].num = warehouse.num
                                    ware.info[index].amount = warehouse.amount
                                    ware.info[index].isHas = true
                                }
                            })
                        })
                    }
                })
            }
        }
        let dataAll = {
            shops: retData.map((shop: AllWarehouseStatisticsVo) => shop.shopName),

            warehouses,
        }

        setAllWarehouseStatisticsDetails(dataAll)
        setAllWarehouseStatisticsLoading(false)
    }
    const handleAllWarehouseStatisticsDate = (dates: any) => {
        if (!dates) {
            getAllWarehouseStatistics(allWarehouseStatisticsBranch, '', '')
            return
        }
        let _startTime = moment(dates![0]).format('YYYY-MM-DD')
        let _endTime = moment(dates![1]).format('YYYY-MM-DD')
        setAllWarehouseStatisticsStart(_startTime)
        setAllWarehouseStatisticsEnd(_endTime)
        getAllWarehouseStatistics(allWarehouseStatisticsBranch, _startTime, _endTime)
    }

    //首页未闭环单据统计
    const [allUnfinishedStatisticsStart, setAllUnfinishedStatisticsStart] = useState('')
    const [allUnfinishedStatisticsEnd, setAllUnfinishedStatisticsEnd] = useState('')
    const [allUnfinishedStatisticsTab, setAllUnfinishedStatisticsTab] = useState('')
    const [allUnfinishedStatisticsBranch, setAllUnfinishedStatisticsBranch] = useState('scm')
    const [allUnfinishedStatisticsLoading, setAllUnfinishedStatisticsLoading] = useState(true)
    const [allUnfinishedStatisticsDetails, setAllUnfinishedStatisticsDetails] = useState<AllUnfinishedStatisticsAll>({ isInit: true })
    const handleAllUnfinishedStatisticsTab = (type: string) => {
        if (!type) {
            setAllUnfinishedStatisticsTab('')
            return
        }
        if (type === 'all') {
            setAllUnfinishedStatisticsTab('')
            getAllUnfinishedStatistics(allUnfinishedStatisticsBranch, '', '')
            return
        }

        let _startTime = ''
        let _endTime = ''
        if (type === 'day') {
            _startTime = moment().format('YYYY-MM-DD')
            _endTime = moment().format('YYYY-MM-DD')
        }
        if (type === 'week') {
            _startTime = moment().startOf('week').format('YYYY-MM-DD')
            _endTime = moment().endOf('week').format('YYYY-MM-DD')
        }
        if (type === 'month') {
            _startTime = moment().startOf('month').format('YYYY-MM-DD')
            _endTime = moment().endOf('month').format('YYYY-MM-DD')
        }
        setAllUnfinishedStatisticsStart(_startTime)
        setAllUnfinishedStatisticsEnd(_endTime)
        setAllUnfinishedStatisticsTab(type)
        getAllUnfinishedStatistics(allUnfinishedStatisticsBranch, _startTime, _endTime)
    }
    const getAllUnfinishedStatistics = async (branch: string, startTime: string, endTime: string) => {
        setAllUnfinishedStatisticsLoading(true)

        let _data = {
            branch,
            startTime: startTime ? startTime + ' 00:00:00' : '',
            endTime: endTime ? endTime + ' 23:59:59' : '',
        }
        const { retData } = await homeService.allUnfinishedStatistics(_data)
        let statistics: any[] = []
        if (retData?.length !== 0) {
            retData.forEach((shop: AllUnfinishedStatisticsVo) => {
                let _item = {
                    pnum: 0,
                    prNum: 0,
                    snum: 0,
                    srNum: 0,

                    unrNum: 0,
                    maNum: 0,
                    marNum: 0,
                    busNum: 0,
                    busrNum: 0,
                }
                if (shop.unfinishedDetailVos?.length !== 0) {
                    _item.pnum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.pnum || 0), 0) || 0
                    _item.snum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.snum || 0), 0) || 0
                    _item.prNum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.prNum || 0), 0) || 0
                    _item.srNum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.srNum || 0), 0) || 0

                    _item.unrNum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.unrNum || 0), 0) || 0
                    _item.maNum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.maNum || 0), 0) || 0
                    _item.marNum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.marNum || 0), 0) || 0
                    _item.busNum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.busNum || 0), 0) || 0
                    _item.busrNum = shop.unfinishedDetailVos?.reduce((prev, next) => prev + (next.busrNum || 0), 0) || 0
                }
                statistics.push(_item)
            })
        }
        let dataAll = {
            shops: retData.map((shop: AllUnfinishedStatisticsVo) => shop.shopName),

            statistics,
        }

        setAllUnfinishedStatisticsDetails(dataAll)
        setAllUnfinishedStatisticsLoading(false)
    }
    const handleAllUnfinishedStatisticsDate = (dates: any) => {
        if (!dates) {
            getAllUnfinishedStatistics(allUnfinishedStatisticsBranch, '', '')
            return
        }
        let _startTime = moment(dates![0]).format('YYYY-MM-DD')
        let _endTime = moment(dates![1]).format('YYYY-MM-DD')
        setAllUnfinishedStatisticsStart(_startTime)
        setAllUnfinishedStatisticsEnd(_endTime)
        getAllUnfinishedStatistics(allUnfinishedStatisticsBranch, _startTime, _endTime)
    }

    //首页分门店版本仓库统计
    const [warehouseStatisticsLoading, setWarehouseStatisticsLoading] = useState(true)
    const [warehouseStatisticsDetails, setWarehouseStatisticsDetails] = useState<WarehouseStatisticsAll>({ isInit: true })
    const getWarehouseStatistics = async () => {
        setWarehouseStatisticsLoading(true)

        let _data = {
            shopId,
        }
        const { retData } = await homeService.warehouseStatistics(_data)
        let names: string[] = []
        let infos: { value: number; name: string; amount: number }[] = []
        if (retData.length !== 0) {
            retData.forEach((item: WarehouseStatisticsVo) => {
                names.push(item.warehouseName as string)
                infos.push({ value: item.num || 0, name: item.warehouseName as string, amount: item.amount || 0 })
            })
        }
        let dataAll = {
            names,

            infos,
        }
        setWarehouseStatisticsDetails(dataAll)
        setWarehouseStatisticsLoading(false)
    }

    //首页分门店版本未闭环单据统计
    const [unfinishedStatisticsLoading, setUnfinishedStatisticsLoading] = useState(true)
    const [unfinishedStatisticsDetails, setUnfinishedStatisticsDetails] = useState<UnfinishedStatisticsAll>({ isInit: true })
    const getUnfinishedStatistics = async () => {
        setUnfinishedStatisticsLoading(true)

        let _data = {
            shopId,
        }
        const { retData } = await homeService.unfinishedStatistics(_data)
        let names: string[] = []
        let infos: { value: number; name: string }[] = []
        if (retData.length !== 0) {
            Object.keys(retData[0]).forEach((key) => {
                if (key === 'pnum' && retData[0].pnum !== null && retData[0].pnum !== undefined) {
                    names.push('采购')
                    infos.push({ value: retData[0].pnum || 0, name: '采购' })
                }
                if (key === 'snum' && retData[0].snum !== null && retData[0].snum !== undefined) {
                    names.push('销售')
                    infos.push({ value: retData[0].snum || 0, name: '销售' })
                }
                if (key === 'prNum' && retData[0].prNum !== null && retData[0].prNum !== undefined) {
                    names.push('采退')
                    infos.push({ value: retData[0].prNum || 0, name: '采退' })
                }
                if (key === 'srNum' && retData[0].srNum !== null && retData[0].srNum !== undefined) {
                    names.push('销退')
                    infos.push({ value: retData[0].srNum || 0, name: '销退' })
                }
                if (key === 'unrNum' && retData[0].unrNum !== null && retData[0].unrNum !== undefined) {
                    names.push('非关联退数量')
                    infos.push({ value: retData[0].unrNum || 0, name: '非关联退数量' })
                }
                if (key === 'maNum' && retData[0].maNum !== null && retData[0].maNum !== undefined) {
                    names.push('维修数量')
                    infos.push({ value: retData[0].maNum || 0, name: '维修数量' })
                }
                if (key === 'marNum' && retData[0].marNum !== null && retData[0].marNum !== undefined) {
                    names.push('维修退单数量')
                    infos.push({ value: retData[0].marNum || 0, name: '维修退单数量' })
                }
                if (key === 'busNum' && retData[0].busNum !== null && retData[0].busNum !== undefined) {
                    names.push('公交预售数量')
                    infos.push({ value: retData[0].busNum || 0, name: '公交预售数量' })
                }
                if (key === 'busrNum' && retData[0].busrNum !== null && retData[0].busrNum !== undefined) {
                    names.push('公交预退数量')
                    infos.push({ value: retData[0].busrNum || 0, name: '公交预退数量' })
                }
            })
        }
        let dataAll = {
            names,

            infos,
        }
        setUnfinishedStatisticsDetails(dataAll)
        setUnfinishedStatisticsLoading(false)
    }

    return {
        statistics,
        getStatistics,

        pending,
        getPending,

        iconList,
        iconListState,
        getQuickEntrance,
        open,
        iconModalList,
        handleSelectIcon,
        showModal,
        handleOk,
        handleCancel,

        stockStatisticsStart,
        stockStatisticsEnd,
        setStockStatisticsStart,
        setStockStatisticsEnd,
        stockStatisticsTab,
        stockStatisticsLoading,
        getStockStatistics,
        handleStockStatisticsTab,
        stockStatisticsDetails,
        handleStockStatisticsDate,
        stockStatisticsType,
        setStockStatisticsType,

        allWarehouseStatisticsStart,
        allWarehouseStatisticsEnd,
        setAllWarehouseStatisticsStart,
        setAllWarehouseStatisticsEnd,
        allWarehouseStatisticsTab,
        allWarehouseStatisticsLoading,
        allWarehouseStatisticsBranch,
        setAllWarehouseStatisticsBranch,
        getAllWarehouseStatistics,
        handleAllWarehouseStatisticsTab,
        allWarehouseStatisticsDetails,
        handleAllWarehouseStatisticsDate,

        allUnfinishedStatisticsStart,
        allUnfinishedStatisticsEnd,
        setAllUnfinishedStatisticsStart,
        setAllUnfinishedStatisticsEnd,
        allUnfinishedStatisticsTab,
        allUnfinishedStatisticsLoading,
        allUnfinishedStatisticsBranch,
        setAllUnfinishedStatisticsBranch,
        getAllUnfinishedStatistics,
        handleAllUnfinishedStatisticsTab,
        allUnfinishedStatisticsDetails,
        handleAllUnfinishedStatisticsDate,

        warehouseStatisticsLoading,
        warehouseStatisticsDetails,
        getWarehouseStatistics,

        unfinishedStatisticsLoading,
        unfinishedStatisticsDetails,
        getUnfinishedStatistics,
    }
})
