import { httpRequest } from '@/utils/HttpRequest'
import { getBaseFmsRequestUrl } from '@/utils/constants'
export interface IMdBeDetail {
    address?: string
    application?: string
    applicationName?: string
    bank?: string
    bankAcct?: string
    branch?: string
    code?: string
    corporation?: string
    createTime?: string
    createUser?: string
    deleteFlag?: number
    enable?: number
    id: string
    licenseNo?: string
    name: string
    note?: string
    orderNum?: string
    phone?: string
    status?: string
    traceId?: string
    updateTime?: string
    updateUser?: string
    ver?: string
}

class CommonService {
    async bePage(searchVo?: any, config: object = {}) {
        const apiUrl = '/tco/bentity/find'
        return await httpRequest.post<IMdBeDetail[]>(apiUrl, searchVo, { ...config, baseURL: getBaseFmsRequestUrl() })
    }

    // 用户自定义设置
    //查询一条
    async userCustomizeConfigOne(key: string) {
        const apiUrl = `/ims/user/customize/config/one/${key}`
        return await httpRequest.get<any>(apiUrl)
    }
    //新增一条
    async userCustomizeConfigSave(data: {}) {
        const apiUrl = '/ims/user/customize/config/save'
        return await httpRequest.post<boolean>(apiUrl, data)
    }
}

export const commonService = new CommonService()
