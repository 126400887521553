import { useEffect, useState, useRef } from 'react'
import * as echarts from 'echarts'
import styled from '@emotion/styled'
import { DatePicker, Select, Spin } from 'antd'
import type { Moment } from 'moment'
import { EChartOption } from 'echarts'
import { useHomeModel } from '../homeModel'
import { useNavModel } from '@/model/navModel'
import useGlobalModel from '@/model/globalModel'

const { RangePicker } = DatePicker

type RangeValue = [Moment | null, Moment | null] | null

export default function BarStackChart() {
    const {
        allUnfinishedStatisticsStart,
        allUnfinishedStatisticsEnd,
        allUnfinishedStatisticsTab,
        handleAllUnfinishedStatisticsTab,
        allUnfinishedStatisticsLoading,
        allUnfinishedStatisticsDetails,
        handleAllUnfinishedStatisticsDate,
        allUnfinishedStatisticsBranch,
        setAllUnfinishedStatisticsBranch,
        getAllUnfinishedStatistics,
    } = useHomeModel()
    const { addNav, findNav, switchNavState } = useNavModel(() => [])
    const { menuAuth } = useGlobalModel()

    const [isHas35, setIsHas35] = useState(false)

    const chartRef = useRef<HTMLDivElement>(null)
    const [chart, setChart] = useState<echarts.ECharts>()
    const handleResize = () => chart?.resize()

    const [value, setValue] = useState<RangeValue>(null)
    const onChange = (val: RangeValue) => {
        handleAllUnfinishedStatisticsTab('')
        setValue(val)
        handleAllUnfinishedStatisticsDate(val)
    }

    const handleChange = (val: string) => {
        setAllUnfinishedStatisticsBranch(val)
        getAllUnfinishedStatistics(val, allUnfinishedStatisticsStart, allUnfinishedStatisticsEnd)
    }

    let legendList: string[] = []
    const option: EChartOption = {
        grid: {
            top: 40,
            right: 30,
            bottom: 20,
            left: 80,
        },
        color: ['#5B8FF9', '#5AD8A6', '#FF9D4D', '#E8684A'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
        },
        legend: {
            itemWidth: 8,
            itemHeight: 8,
            itemGap: 20,
            left: 10,
            icon: 'rect',
            data: [],
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: [],
            },
        ],
        yAxis: [
            {
                type: 'value',
                minInterval: 1,
            },
        ],
        dataZoom: [
            {
                type: 'slider',
                start: 0,
                end: 50,
            },
        ],
        series: [],
    }

    const init = () => {
        if (chart) {
            window.removeEventListener('resize', handleResize)
        }

        let _branch = allUnfinishedStatisticsBranch
        let _keys: string[] = []
        legendList = []
        option!.xAxis![0]!.data = []
        option!.series = []
        if (_branch === 'scm') {
            legendList = ['采购', '销售', '采退', '销退', '非关联退数量']
            _keys = ['pnum', 'snum', 'prNum', 'srNum', 'unrNum']
        }
        if (_branch === 'bcm') {
            legendList = ['采购', '销售', '采退', '销退']
            _keys = ['pnum', 'snum', 'prNum', 'srNum']
        }
        if (_branch === 'vrm') {
            legendList = ['采购', '销售', '采退', '销退', '维修数量', '维修退单数量']
            _keys = ['pnum', 'snum', 'prNum', 'srNum', 'maNum', 'marNum']
        }
        if (_branch === 'ci') {
            legendList = ['采购', '销售', '采退', '销退', '维修数量', '维修退单数量']
            _keys = ['pnum', 'snum', 'prNum', 'srNum', 'maNum', 'marNum']
        }
        option!.legend!.data = legendList

        option!.xAxis![0]!.data = allUnfinishedStatisticsDetails?.shops
        if (allUnfinishedStatisticsDetails?.statistics?.length !== 0) {
            let _data: any[] = legendList.map(() => [])
            allUnfinishedStatisticsDetails?.statistics?.forEach((item) => {
                _keys.forEach((key, index) => {
                    _data[index].push(item[key])
                })
            })
            legendList.forEach((name, index) => {
                option!.series!.push({
                    name,
                    type: 'bar',
                    stack: 'total',
                    barWidth: 16,
                    data: _data[index],
                })
            })
        }

        let chartInstance = echarts.getInstanceByDom(chartRef.current as HTMLDivElement)
        if (!chartInstance) {
            chartInstance = echarts.init(chartRef.current as HTMLDivElement)
        }
        chartInstance.setOption(option)
        window.addEventListener('resize', () => chartInstance?.resize())
        setChart(chartInstance)
    }

    useEffect(() => {
        if (menuAuth?.indexOf('/receipt/unfinished') !== -1) {
            setIsHas35(true)
        }
    }, [menuAuth])
    useEffect(() => {
        if (allUnfinishedStatisticsTab) {
            setValue(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUnfinishedStatisticsTab])
    useEffect(() => {
        handleAllUnfinishedStatisticsTab('all')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (allUnfinishedStatisticsDetails.isInit) {
            return
        }

        init()

        return () => {
            window.removeEventListener('resize', handleResize)
            chart?.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUnfinishedStatisticsDetails, allUnfinishedStatisticsBranch])

    return (
        <Box className="index-content">
            <div className="index-content-title-bar">
                {!isHas35 ? (
                    <span className="index-content-title-bar-title">未闭环单据</span>
                ) : (
                    <span
                        className="index-content-title-bar-title"
                        style={{ color: '#4165E3', cursor: 'pointer' }}
                        onClick={() => {
                            let _key = '3-5'
                            let isFindNav = findNav(_key)
                            let _params = { type: 'all', id: '', mode: 'look' }

                            if (!isFindNav) {
                                addNav(_key, _params)
                            } else {
                                switchNavState(_key, _params)
                            }
                        }}
                    >
                        未闭环单据
                    </span>
                )}

                <div className="index-content-title-bar-more">
                    <div className="home-title-menu">
                        <Select
                            value={allUnfinishedStatisticsBranch}
                            style={{ width: 180, height: 32 }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'scm',
                                    label: '配件进销存',
                                },
                                {
                                    value: 'bcm',
                                    label: '客车管理',
                                },
                                {
                                    value: 'vrm',
                                    label: '维保管理',
                                },
                                {
                                    value: 'ci',
                                    label: '云修系统',
                                },
                            ]}
                        />
                        <div className="tabs">
                            <div
                                className={allUnfinishedStatisticsTab === 'day' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleAllUnfinishedStatisticsTab('day')
                                }}
                            >
                                今日
                            </div>
                            <div
                                className={allUnfinishedStatisticsTab === 'week' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleAllUnfinishedStatisticsTab('week')
                                }}
                            >
                                本周
                            </div>
                            <div
                                className={allUnfinishedStatisticsTab === 'month' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleAllUnfinishedStatisticsTab('month')
                                }}
                            >
                                本月
                            </div>
                        </div>
                        <div className="select">
                            <RangePicker value={value} onChange={onChange} />
                        </div>
                    </div>
                </div>
            </div>

            <Spin tip="Loading..." spinning={allUnfinishedStatisticsLoading}>
                <div className="content">
                    <div ref={chartRef} className="chart"></div>
                </div>
            </Spin>
        </Box>
    )
}

const Box = styled.div`
    height: 320px;
    margin-bottom: 16px;

    .content {
        position: relative;
        .chart {
            width: 100%;
            height: 220px;
            margin-top: 20px;
        }
    }
`
