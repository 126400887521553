import { useEffect, useRef, useState } from 'react'
import { createModel } from 'hox'
import { TablePaginationConfig } from 'antd/es/table/interface'
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants'
import { IDetail, IListDetail, ISearchFields, service } from './service'
import useGlobalModel, { transPgToPagination } from '@/model/globalModel'
import { ISearchPage } from '@/types/AppTypes'
import { momentFormat } from '@/utils/utils'
import { message } from 'antd'
import { cloneDeep } from 'lodash';

export const useModel = createModel(function () {
    const {
        user: { shopId, branch },
        setLoadingInfo,
    } = useGlobalModel()
    /**
     * 列表
     */
    //列表数据源
    const [dataSource, setDataSource] = useState<IDetail[]>([])
    //查询条件
    const [searchParam, setSearchParam] = useState<ISearchFields>({})
    const orders = useRef<{ column: string; asc: boolean }[]>([])
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg)
    const pgCfgRef = useRef<TablePaginationConfig>(initPgCfg)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        pgCfgRef.current = pgCfg
    }, [pgCfg])

    //列表查询
    const loadData = async (page: ISearchPage = pgCfgRef.current, searchValue: ISearchFields = searchParam) => {
        setLoading(true)

        if (typeof searchValue.status === 'object') {
            searchValue.status = searchValue.status.join(',')
        }
        const searchParams = {
            shopId,
            branch,
            ioFlag: '0',
            ...searchValue,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? DEFAULT_FIRST_PAGE_NUM,
            orders: orders.current,
        }
        let { retData } = await service.page(searchParams)
        const { records, ...pg } = retData
        setPgCfg(transPgToPagination(pg))
        if (records && records?.length !== 0) {
            records.forEach((item, index) => {
                item.index = index
            })
        }
        setDataSource(records || [])
        setLoading(false)
    }
    //设置搜索value
    const updateSearchParam = (updateParam: ISearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({})
        orders.current = []
        loadData(initPgCfg, {})
    }

    const [columnsSetVisible, setColumnsSetVisible] = useState<boolean>(false)

    //详情页面
    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState<any>({})
    //表单编辑修改
    const updateEditFormVal = (val: object | null) => {
        val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal({})
    }
    //获取一条详情数据 Api
    const getOne = async (id: string, mode = 'edit') => {
        let _details: IListDetail = JSON.parse(window.sessionStorage.getItem('outWarehouseDetails') as string)
        let { retData } = await service.one(id, branch, _details?.tradeTypeName)

        const { calculateJobs, calculateItems, calculateMaterials, calculateVehicles, ...formVal } = retData

        retData &&
            setEditFormVal({
                ...formVal,
                ...{ code: _details?.code, status: _details?.status, tradeTypeName: _details?.tradeTypeName },
                disableFlag: !!formVal.disableFlag,
                createTime: momentFormat(formVal.createTime as any),
                transferTime: momentFormat(formVal.transferTime as any),
            })
    }

    //导出
    const exportData = async (searchValue: ISearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: '快速导出中' })
        try {
            const params = cloneDeep<any>(JSON.parse(JSON.stringify(searchValue)));
            if (typeof params.status === 'object') {
                params.status = params.status.join(',')
            }
            const searchParams = {
                shopId,
                branch,
                ioFlag: '0',
                ...params,
                pageSize: -1,
                pageNum: 1,
                orders: orders.current,
            }
            let result = await service.export(searchParams)
            result && setLoadingInfo({ loadingVisible: false, txt: '' })
            message.success('导出进行中。请稍后去导出记录列表查看')
        } catch {
            setLoadingInfo({ loadingVisible: false, txt: '' })
        }
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        pgCfg,
        columnsSetVisible,
        loading,
        orders,
        editFormVal,
        /***method***/
        setDataSource,
        resetSearchParam,
        updateSearchParam,
        loadData,
        setColumnsSetVisible,
        updateEditFormVal,
        getOne,
        exportData,
    }
})
