import { IMdCmFields } from '../types/AppTypes'
import { MdEnableStatusEnum } from '../types/AppEnums'

//系统标识  测试和生产主数据标识区别
export const CLIENT_APP_CODE = 'ims'

export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_FIRST_PAGE_NUM = 1
export const DEFAULT_REQUEST_TIMEOUT = 30000

//API请求环境
const baseRequestUrl = {
    TESTAPI: 'http://test-api-2.laoniuche.cn',
    DEV: 'http://api.peijian88.cn',
    TEST: 'http://test-api-2.laoniuche.cn',
    PROD: 'https://api-2.yunhaogroup.com',
    STAGE: 'http://api.beiben88.com',
}
const baseWSRequestUrl = {
    DEV: `ws://api.peijian88.cn/tco`,
    // TEST: `ws://test-api.laoniuche.cn/fms`,
    // TEST: `ws://api-2.laoniuche.cn/fms`,
    // TEST: `ws://192.168.110.121:9059/tco`,
    TEST: `ws://test.tco.websocket.laoniuche.cn/tco`,
    // PROD: 'wss://api-2.yunhaogroup.com/fms',
    PROD: 'ws://tco.websocket.yunhaogroup.com/tco',
    // STAGE: 'ws://api.beiben88/fms',
}
//财务的请求环境
const baseFmsRequestUrl = {
    TESTAPI: 'http://test-api.laoniuche.cn',
    DEV: 'http://api.peijian88.cn',
    TEST: 'http://test-api-2.laoniuche.cn',
    PROD: 'https://api-2.yunhaogroup.com',
    STAGE: 'http://api.beiben88.com',
}
const baseCiRequestUrl = {
    TESTAPI: `http://test.api.yunhaochefu.com`,
    DEV: `http://test.api.yunhaochefu.com`,
    TEST: `http://test.api.yunhaochefu.com`,
    PROD: 'https://api.yunhaochefu.com',
    STAGE: 'http://test.api.yunhaochefu.com',
}
const baseCiDownLoadUrl = {
    DEV: `https://file-server.yunhaochefu.com`,
    TEST: `https://file-server.yunhaochefu.com`,
    PROD: 'https://file-server.yunhaochefu.com',
    STAGE: 'https://file-server.yunhaochefu.com',
}

//根据环境变量自动获取请求地址
export const getBaseRequestUrl = () => {
    const REACT_APP_ENV = process.env.REACT_APP_ENV as 'DEV' | 'TEST' | 'PROD' | 'STAGE'
    return baseRequestUrl[REACT_APP_ENV ?? 'DEV']
}
export const getBaseWSRequestUrl = () => {
    const REACT_APP_ENV = process.env.REACT_APP_ENV as 'DEV' | 'TEST' | 'PROD'
    return baseWSRequestUrl[REACT_APP_ENV ?? 'DEV']
}
export const getBaseFmsRequestUrl = () => {
    const REACT_APP_ENV = process.env.REACT_APP_ENV as 'DEV' | 'TEST' | 'PROD' | 'STAGE' | 'TESTAPI'
    return baseFmsRequestUrl[REACT_APP_ENV ?? 'DEV']
}
export const getBaseCiRequestUrl = () => {
    const REACT_APP_ENV = process.env.REACT_APP_ENV as 'DEV' | 'TEST' | 'PROD' | 'STAGE' | 'TESTAPI'
    return baseCiRequestUrl[REACT_APP_ENV ?? 'DEV']
}
export const getBaseCiDownLoadUrl = () => {
    const REACT_APP_ENV = process.env.REACT_APP_ENV as 'DEV' | 'TEST' | 'PROD' | 'STAGE' | 'TESTAPI'
    return baseCiDownLoadUrl[REACT_APP_ENV ?? 'DEV']
}

//服务器自定义错误码
export const INTERNAL_SERVER_ERROR_CODE = 500
//token存储KEY值
export const TOKEN_KEY = 'token'

//获取token
export const getToken = () => {
    return sessionStorage.getItem(TOKEN_KEY) ?? 'default-token-str'
}

//存储token
export const setToken = (token: string) => {
    sessionStorage.setItem(TOKEN_KEY, token)
}
//通用字段定义
export const defaultMdCmParam: IMdCmFields = {
    sourceApp: 'ims',
    sourceAuthKey: '',
    branch: 'ims',
    ver: undefined,
    enable: MdEnableStatusEnum.ENABLE,
}

export const branchDs = [
    { label: '财务分支', value: 'fms' },
    { label: '主分支', value: 'main' },
]
