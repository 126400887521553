import { useModel } from '../model'
import { ISearchValue, SearchTypeEnum } from '@/components/YhPageHeader/Types'
import { YhPageHeader } from '@/components/YhPageHeader'
import useGlobalModel from '@/model/globalModel'
import { useDictModel } from '@/views/base/dict/dictModel'
import { DictCodeEnmu } from '@/types/AppDictEnums'
import { arrayNonRepeatfy } from '@/utils/utils'

export function PageHeader() {
    const {
        user: { branch },
    } = useGlobalModel()
    const { getSelectedDictOne } = useDictModel(() => [])
    const { searchParam, updateSearchParam, resetSearchParam, loadData, pageMode } = useModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '单据编号',
            fieldName: 'code',
            span: 4,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '单据状态',
            fieldName: 'status',
            span: 5,
            options: arrayNonRepeatfy(getSelectedDictOne(pageMode === 'all' ? 'all' : branch, 'unfinished', DictCodeEnmu.DOCUMENT_STSTUS), 'value'),
            mode: 'multiple',
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '单据类型',
            fieldName: 'type',
            span: 4,
            options: getSelectedDictOne(pageMode === 'all' ? 'all' : branch, 'unfinished', DictCodeEnmu.TRADE_TYPE).map((option) => ({
                value: option.label,
                label: option.label,
            })),
        },
    ]

    return (
        <YhPageHeader
            title=""
            size="small"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    )
}
