import { DataTable } from './DataTable'
import { YhBox } from '@/components/YhBox'

export const DataBox = () => {
    return (
        <YhBox style={{ height: 'calc(100vh - 185px)' }}>
            <DataTable />
        </YhBox>
    )
}
