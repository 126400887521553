import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { getBaseCiRequestUrl } from '@/utils/constants'

//详情
export interface IDetail {
    areaId: null
    /**
     * 账面库存数
     */
    bookTotalNum: number
    /**
     * 分支
     */
    branch: string
    /**
     * 创建时间
     */
    createTime: string
    /**
     * 创建人
     */
    createUser: string
    deleteFlag: number
    details: null
    differenceItem: null
    differenceRate: string
    /**
     * 差异金额
     */
    differenceTotalAmount: number
    /**
     * 差异数量
     */
    differenceTotalNum: number
    /**
     * 盘点结束
     */
    endTime: string
    id: string
    lossItem: number
    note: string
    /**
     * 实物盘点数
     */
    realTotalNum: number
    result: number
    /**
     * 报废金额
     */
    scrapTotalAmount: number
    /**
     * 报废数量
     */
    scrapTotalNum: number
    shelvesId: null
    shopId: string
    /**
     * 门店名称
     */
    shopName: string
    /**
     * 盘点开始
     */
    startTime: string
    /**
     * 盘点状态
     */
    status: string
    /**
     * 盘点单号
     */
    stocktakingCode: string
    surplusItem: number
    updateTime: string
    updateUser: string
    warehouseCode: string
    warehouseName: string
    [property: string]: any
}

export interface IContent {
    areaId: null
    bookTotalNum: number
    branch: null
    /**
     * 制单时间
     */
    createTime: string
    /**
     * 制单员
     */
    createUser: string
    deleteFlag: number
    details: Detail[]
    differenceItem: null
    differenceRate: string
    differenceTotalAmount: number
    differenceTotalNum: number
    /**
     * 结束时间
     */
    endTime: string
    id: string
    lossItem: number
    /**
     * 备注
     */
    note: string
    realTotalNum: number
    /**
     * 盘点结果
     */
    result: number
    scrapTotalAmount: number
    scrapTotalNum: number
    shelvesId: null
    shopId: string
    /**
     * 所属门店
     */
    shopName: string
    /**
     * 开始时间
     */
    startTime: string
    /**
     * 盘点状态
     */
    status: string
    /**
     * 盘点单号
     */
    stocktakingCode: string
    surplusItem: number
    updateTime: string
    updateUser: string
    warehouseCode: string
    /**
     * 盘点仓库
     */
    warehouseName: string
    [property: string]: any
}

export interface Detail {
    /**
     * 最新采购价格
     */
    avgPrice?: number
    code?: null
    costAmount?: number
    createTime?: string
    createUser?: string
    deleteFlag?: number
    /**
     * 差异金额
     */
    differenceAmount?: number
    /**
     * 差异量
     */
    differenceNum?: number
    /**
     * 差异原因
     */
    differenceReason?: string
    finishTime?: string
    id?: string
    /**
     * 系统库存
     */
    inventoryNum?: number
    /**
     * 商品名称
     */
    materialCode?: string
    /**
     * 商品编码
     */
    materialName?: string
    note?: string
    orderNum?: number
    profit?: number
    /**
     * 实际库存
     */
    realNum?: number
    replaceCode?: string
    /**
     * 盘点结果
     */
    result?: string
    /**
     * 报废金额
     */
    scrapAmount?: number
    /**
     * 报废数量
     */
    scrapNum?: number
    /**
     * 报废原因
     */
    scrapReason?: string
    shelfCode?: string
    shopId?: string
    shopName?: string
    status?: string
    stocktakingCode?: string
    traceId?: string
    updateTime?: string
    updateUser?: null
    usableNum?: number
    /**
     * 货位
     */
    warehouseCode?: string
    /**
     * 仓库
     */
    warehouseName?: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    stocktakingCode?: string
    materialCode?: string
    warehouseCode?: string
    startTime?: string
    endTime?: string
    status?: any
    searchItem?:any
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
    branch: string
}

class Service {
    async page(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/stock/stocktaking/listPage'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/stock/check/findPage'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/stock/check/findPage'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-storage/stock/check/findPage'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }

    //详情页
    async one(id: string, branch: string) {
        let apiUrl = ''
        if (branch === 'scm') {
            apiUrl = `/scm2/stock/stocktaking/findByIdOrCode/${id}`
        }
        if (branch === 'bcm') {
            apiUrl = `/bcm/stock/check/findById/${id}`
        }
        if (branch === 'vrm') {
            apiUrl = `/vrm2/stock/check/findById/${id}`
        }
        if (branch === 'ci') {
            apiUrl = `/ci-storage/stock/check/findById/${id}`
        }
        const baseURL = branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.get<IContent>(apiUrl, { timeout: 300000, ...{ baseURL } })
    }

    //导出
    async export(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/stock/stocktaking/export'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/stock/check/export'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/stock/check/export'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-analyze/stock/check/export'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }
}

export const service = new Service()
