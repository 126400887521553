import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { getBaseCiRequestUrl } from '@/utils/constants'

//详情
export interface IDetail {
    /**
     * 单据编号
     */
    bussCode?: string
    /**
     * 入库单价
     */
    costPrice?: { [key: string]: any }
    /**
     * 创建时间
     */
    createTime?: string
    /**
     * No comments found.
     */
    id: string
    /**
     * 库存成本
     */
    inventoryAmount?: { [key: string]: any }
    /**
     * 库存数量
     */
    inventoryQuantity?: number
    /**
     * 商品编码
     */
    materialCode?: string
    /**
     * 商品名称
     */
    materialName?: string
    /**
     * 占用数量
     */
    occupyNum?: number
    /**
     * 单位名称
     */
    orgName?: string
    /**
     * 积压时长
     */
    overstock?: number
    /**
     * 替换编号
     */
    replaceCode?: string
    /**
     * 最近一月是否销售 0否 1是
     */
    saleRecent?: number
    /**
     * 最近一年是否销售 0否 1是
     */
    saleRecentYear?: number
    /**
     * 货位编号
     */
    shelfCode?: string
    /**
     * No comments found.
     */
    shopId?: string
    /**
     * 入库金额
     */
    stockIoAmount?: { [key: string]: any }
    /**
     * 入库数量
     */
    stockIoNum?: number
    /**
     * 入库日期
     */
    stockIoTime?: string
    /**
     * 商品类型编码
     */
    typeCode?: string
    /**
     * 商品类型
     */
    typeName?: string
    /**
     * 仓库编号
     */
    warehouseCode?: string
    /**
     * 仓库名称
     */
    warehouseName?: string
    //最新进价
    lastPurchasePrice: number
    //最初入库日期
    lastStockIoTime: number
    //是否积压 0否 1是
    overstockFlag: number
    //年周转率
    turnoverRate: number
    //积压率
    overstockRate: number
    //销存率
    saleOverstockRate: number
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    result?: string
    offset?: string
    module?: string
    ip?: string
    operationType?: string
    startTime?: string
    endTime?: string
    operator?: string
    searchItem?: {}
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
    branch: string
}

class Service {
    async page(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/inventory/overstock/details'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/inventory/overstock/details'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/inventory/overstock/details'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-storage/inventory/overstock/details'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }

    //导出
    async export(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/inventory/overstock/details/export'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/inventory/overstock/details/export'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/inventory/overstock/details/export'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-analyze/inventory/overstock/details/export'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }
}

export const service = new Service()
