import { useEffect, useState, useRef } from 'react'
import * as echarts from 'echarts'
import styled from '@emotion/styled'
import { Spin } from 'antd'
import { EChartOption } from 'echarts'
import { useHomeModel } from '../homeModel'
import { useNavModel } from '@/model/navModel'
import useGlobalModel from '@/model/globalModel'

export default function PieCavityChart() {
    const { unfinishedStatisticsLoading, unfinishedStatisticsDetails, getUnfinishedStatistics } = useHomeModel()
    const { addNav, findNav, switchNavState } = useNavModel(() => [])
    const { menuAuth } = useGlobalModel()

    const [isHas35, setIsHas35] = useState(false)

    const chartRef = useRef<HTMLDivElement>(null)
    const [chart, setChart] = useState<echarts.ECharts>()
    const handleResize = () => chart?.resize()

    let option: EChartOption = {
        color: ['#5B8FF9', '#5AD8A6', '#FF9D4D', '#F6BD16', '#E8684A', '#009966', '#CC0033', '#0099CC', '#FFCCCC', '#CCCC33', '#CCCCFF', '#FF9966'],
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: '40%',
            itemWidth: 10,
            itemHeight: 10,
            itemGap: 10,
            right: 50,
            orient: 'vertical',
            icon: 'circle',
            data: [],
        },
        series: [
            {
                type: 'pie',
                radius: ['30%', '75%'],
                labelLine: {
                    length: 20,
                    lineStyle: {
                        color: 'rgba(0, 0, 0, 0.45)',
                    },
                },
                label: {
                    formatter: '{b}\n {c} ',
                    color: 'rgba(0, 0, 0, 0.45)',
                },
                data: [],
            },
        ],
    }

    const init = () => {
        if (chart) {
            window.removeEventListener('resize', handleResize)
        }

        option!.legend!.data = unfinishedStatisticsDetails?.names
        option!.series![0]!.data = unfinishedStatisticsDetails?.infos

        let chartInstance = echarts.getInstanceByDom(chartRef.current as HTMLDivElement)
        if (!chartInstance) {
            chartInstance = echarts.init(chartRef.current as HTMLDivElement)
        }
        chartInstance.setOption(option)
        window.addEventListener('resize', () => chartInstance?.resize())
        setChart(chartInstance)
    }

    useEffect(() => {
        if (menuAuth?.indexOf('/receipt/unfinished') !== -1) {
            setIsHas35(true)
        }
    }, [menuAuth])
    useEffect(() => {
        getUnfinishedStatistics()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (unfinishedStatisticsDetails.isInit) {
            return
        }

        init()

        return () => {
            window.removeEventListener('resize', handleResize)
            chart?.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unfinishedStatisticsDetails])

    return (
        <Box className="index-content pieCavityChart">
            <div className="index-content-title-bar">
                {!isHas35 ? (
                    <span className="index-content-title-bar-title">未闭环单据</span>
                ) : (
                    <span
                        className="index-content-title-bar-title"
                        style={{ color: '#4165E3', cursor: 'pointer' }}
                        onClick={() => {
                            let _key = '3-5'
                            let isFindNav = findNav(_key)
                            let _params = { type: 'one', id: '', mode: 'look' }

                            if (!isFindNav) {
                                addNav(_key, _params)
                            } else {
                                switchNavState(_key, _params)
                            }
                        }}
                    >
                        未闭环单据
                    </span>
                )}
            </div>

            <Spin tip="Loading..." spinning={unfinishedStatisticsLoading}>
                <div className="content">
                    <div ref={chartRef} className="chart"></div>
                </div>
            </Spin>
        </Box>
    )
}

const Box = styled.div`
    width: calc(50% - 8px);
    height: 320px;

    .content {
        position: relative;
        .chart {
            width: 100%;
            height: 250px;
        }
    }
`
