import React from 'react'
import { ISearchField, ISearchProp } from '../Types'
import { DatePicker } from 'antd'
import moment from 'moment'

type PropType = ISearchProp & ISearchField
const { RangePicker } = DatePicker
export const SearchFmsRangePicker = (props: PropType) => {
    const value = props.searchValues?.[props.fieldName] as { startTime: string; endTime: string }
    return (
        <RangePicker
            placeholder={props.placeholder as [string, string]}
            className="ant-picker-round"
            format={props.format || 'YYYY-MM-DD'}
            value={value && value.startTime && value.endTime ? [moment(value.startTime), moment(value.endTime)] : undefined}
            onChange={(value, timeStr) => {
                let obj = {
                    [props.fieldName]:
                        timeStr[0] !== '' && timeStr[1] !== ''
                            ? {
                                  startTime: timeStr[0] + ' 00:00:00',
                                  endTime: timeStr[1] + ' 23:59:59',
                              }
                            : undefined,
                }
                props.onFmsChange?.(obj)
                if (props.triggerSearch) props.handleSearch?.()
            }}
            disabled={props.disabled}
        />
    )
}
