import { commonService } from '@/service/common'
import { Button, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import update from 'immutability-helper'
import React, { useCallback, useRef, useState } from 'react'
import { useEffect } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import FontIcon from '../YhIcon'
import { YHRightModal } from '../YHRightModal'

export function YhTableSort({
    modalTitle = '列配置',
    visible,
    setVisible,
    defaultColumns,
    sortKey,
    onSuccess,
}: {
    modalTitle?: string
    defaultColumns: any[]
    visible: boolean
    sortKey: string
    setVisible: (boolean) => void
    onSuccess: () => void
}) {
    interface DataType {
        name: string
        isSort: boolean
        isShow: boolean
        isDisabled?: boolean
        key: string
    }

    interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
        index: number
        moveRow: (dragIndex: number, hoverIndex: number) => void
    }

    const type = 'DraggableBodyRow'

    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
        const ref = useRef<HTMLTableRowElement>(null)
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: (monitor) => {
                const { index: dragIndex } = monitor.getItem() || {}
                if (dragIndex === index) {
                    return {}
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                }
            },
            drop: (item: { index: number }) => {
                moveRow(item.index, index)
            },
        })
        const [, drag] = useDrag({
            type,
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })
        drop(drag(ref))

        return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />
    }

    const column: ColumnsType<DataType> = [
        {
            title: '列名称',
            dataIndex: 'name',
            // align: 'center',
            key: 'name',
        },
        {
            title: '是否排序',
            dataIndex: 'isSort',
            // align: 'center',
            render(t, r, i) {
                return (
                    <Checkbox
                        key={'isSort' + r.key + i}
                        name={r.name}
                        checked={t}
                        disabled={r.isDisabled}
                        onChange={(e) => {
                            console.log(r)
                            onChangeData('isSort', i, e.target.checked)
                        }}
                    />
                )
            },
        },
        {
            title: '操作',
            // align: 'center',
            render() {
                return <FontIcon type="icon-shangxiatuodong" />
            },
        },
    ]

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

    const onChangeData = (k: 'isSort', i, checked) => {
        let dataSource = [...data]
        dataSource.splice(i, 1, { ...dataSource[i], [k]: checked })
        setData(dataSource)
    }

    useEffect(() => {
        if (visible) {
            init()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const init = async () => {
        const newC: DataType[] = []
        let { retData } = await commonService.userCustomizeConfigOne(sortKey)

        let showKeys: string[] = []
        defaultColumns.forEach((i) => {
            if (i.isSet) {
                let one = retData && JSON.parse(retData.val).find((one) => one.key === i.dataIndex)
                newC.push({
                    key: i.dataIndex,
                    name: i.copyTitle || i.title,
                    isSort: one ? one.isSort : i.sorter,
                    isShow: one ? one.isShow : true,
                    isDisabled: i.sorter === undefined ? true : false,
                })
                if (retData && one) {
                    one.isShow && showKeys.push(one.key)
                } else {
                    showKeys.push(i.dataIndex)
                }
            }
        })
        setData(newC)
        setSelectedRowKeys(showKeys)
    }

    const [data, setData] = useState<DataType[]>([])

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    }

    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragRow = data[dragIndex]
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                })
            )
        },
        [data]
    )

    const onClose = () => {
        setVisible(false)
    }
    const onReset = () => {
        const newC: DataType[] = []
        let showKeys: string[] = []
        defaultColumns.forEach((i) => {
            if (i.isSet) {
                newC.push({
                    key: i.dataIndex,
                    name: i.copyTitle || i.title,
                    isSort: i.sorter,
                    isShow: true,
                    isDisabled: i.sorter === undefined ? true : false,
                })
                showKeys.push(i.dataIndex)
            }
        })
        setData(newC)
        setSelectedRowKeys(showKeys)
    }
    const onOk = () => {
        data.map((i) => {
            if (typeof i.name == 'string') {
                return i
            } else {
                return { ...i, name: 'html' }
            }
        })
        commonService.userCustomizeConfigSave({ key: sortKey, val: JSON.stringify(data) }).then((res) => {
            onSuccess()
        })
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys as string[])
            setData(data.map((i) => ({ ...i, isShow: selectedRowKeys.includes(i.key) })))
        },
    }

    return (
        <YHRightModal
            title={modalTitle}
            width={500}
            visible={visible}
            onCancel={onClose}
            showFooter={true}
            footerButs={[
                <Button onClick={onReset} key="3">
                    恢复默认
                </Button>,
                <Button onClick={onClose} key="1">
                    取消
                </Button>,
                <Button onClick={onOk} type="primary" key="2">
                    确定
                </Button>,
            ]}
            modalBodyStyle={{ padding: 0 }}
        >
            <div style={{ height: '40px', fontSize: '12px', color: '#999', lineHeight: '40px', textIndent: '20px' }}>上下拖动调整字段顺序</div>
            <DndProvider backend={HTML5Backend}>
                <Table
                    rowKey={(r) => r.key}
                    columns={column}
                    dataSource={data}
                    components={components}
                    onRow={(_, index) => {
                        const attr = {
                            index,
                            moveRow,
                        }
                        return attr as React.HTMLAttributes<any>
                    }}
                    bordered
                    pagination={false}
                    rowSelection={{
                        ...rowSelection,
                        selectedRowKeys: selectedRowKeys,
                    }}
                />
            </DndProvider>
        </YHRightModal>
    )
}
