import { useEffect, useState } from 'react'
import { DataTable } from './DataTable'
import { YhBox } from '@/components/YhBox'
import { Button } from 'antd'
import useGlobalModel from '@/model/globalModel'
import { useModel } from '../model'

export const DataBox = () => {
    const { exportData } = useModel()
    const { menuAuth } = useGlobalModel()

    const [isHas7, setIsHas7] = useState(false)

    useEffect(() => {
        if (menuAuth?.indexOf('/exportRecord') !== -1) {
            setIsHas7(true)
        }
    }, [menuAuth])

    const action = (
        <>
            {isHas7 && (
                <Button className="mr-5" type="link" shape="round" onClick={() => exportData()}>
                    导出
                </Button>
            )}
        </>
    )

    return (
        <YhBox action={action} style={{ height: 'calc(100vh - 185px)' }}>
            <DataTable />
        </YhBox>
    )
}
