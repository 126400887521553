import { useEffect, useRef, useState } from 'react'
import { createModel } from 'hox'
import { TablePaginationConfig } from 'antd/es/table/interface'
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants'
import { IDetail, ISearchFields, service } from './service'
import useGlobalModel, { transPgToPagination } from '@/model/globalModel'
import { useDictModel } from '@/views/base/dict/dictModel'
import { DictCodeEnmu } from '@/types/AppDictEnums'
import { ISearchPage } from '@/types/AppTypes'
import useNavModel, { RouterParamType } from '@/model/navModel'
import { message } from 'antd'
import { cloneDeep } from 'lodash';

export const useModel = createModel(function () {
    const {
        user: { shopId, branch },
        setLoadingInfo,
    } = useGlobalModel()

    const { getSelectedNavState, navTabs, selectedNavId } = useNavModel()

    const { getSelectedDictOne } = useDictModel(() => [])

    /**
     * 列表
     */
    //列表数据源
    const [dataSource, setDataSource] = useState<IDetail[]>([])
    //查询条件
    const [searchParam, setSearchParam] = useState<ISearchFields>({})
    const orders = useRef<{ column: string; asc: boolean }[]>([])
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg)
    const pgCfgRef = useRef<TablePaginationConfig>(initPgCfg)

    const [loading, setLoading] = useState(false)

    const [stockType, setStockType] = useState('in')
    const [pageMode, setPageMode] = useState('one')

    useEffect(() => {
        pgCfgRef.current = pgCfg
    }, [pgCfg])
    useEffect(() => {
        let _info: RouterParamType = getSelectedNavState() as RouterParamType

        if (_info?.tab) {
            setStockType(_info.tab)
        }
        if (_info?.type) {
            setPageMode(_info.type)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navTabs, selectedNavId])

    //列表查询
    const loadData = async (page: ISearchPage = pgCfgRef.current, searchValue: ISearchFields = searchParam) => {
        setLoading(true)

        let _stockType = stockType
        let _status: string | undefined = ''
        let _type: string | undefined = ''
        _type = getSelectedDictOne(pageMode === 'all' ? 'all' : branch, 'unfinished', DictCodeEnmu.TRADE_TYPE).find(
            (dict) => dict.label.indexOf('调拨单') !== -1
        )?.label as string | undefined
        if (stockType === 'allotIn') {
            _status = getSelectedDictOne(pageMode === 'all' ? 'all' : branch, 'unfinished', DictCodeEnmu.DOCUMENT_STSTUS).find(
                (dict) => dict.label.indexOf('待调入') !== -1
            )?.value as string | undefined
            updateSearchParam({
                status: [_status],
                type: _type,
            })
            searchValue.status = _status
            searchValue.type = _type
            _stockType = 'in'
        }
        if (stockType === 'allotOut') {
            _status = getSelectedDictOne(pageMode === 'all' ? 'all' : branch, 'unfinished', DictCodeEnmu.DOCUMENT_STSTUS).find(
                (dict) => dict.label.indexOf('待调出') !== -1
            )?.value as string | undefined
            updateSearchParam({
                status: [_status],
                type: _type,
            })
            searchValue.status = _status
            searchValue.type = _type
            _stockType = 'out'
        }
        if (typeof searchValue.status === 'object') {
            searchValue.status = searchValue.status.join(',')
        }
        const searchParams = {
            shopId: pageMode === 'one' ? shopId : undefined,
            stockType: _stockType,
            ...searchValue,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? DEFAULT_FIRST_PAGE_NUM,
            orders: orders.current,
        }
        let { retData } = await service.page(searchParams)
        const { records, ...pg } = retData
        setPgCfg(transPgToPagination(pg))
        records?.forEach((item, index) => {
            item.index = index
        })
        setDataSource(records)
        setLoading(false)
    }
    //设置搜索value
    const updateSearchParam = (updateParam: ISearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({})
        orders.current = []
        loadData(initPgCfg, {})
    }

    const [columnsSetVisible, setColumnsSetVisible] = useState<boolean>(false)

    //导出
    const exportData = async (searchValue: ISearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: '快速导出中' })
        try {
            const params = cloneDeep<any>(JSON.parse(JSON.stringify(searchValue)));
            if (typeof params.status === 'object') {
                params.status = params.status.join(',')
            }
            const searchParams = {
                shopId: pageMode === 'one' ? shopId : undefined,
                stockType,
                ...params,
                pageSize: -1,
                pageNum: 1,
                orders: orders.current,
            }
            let result = await service.export(searchParams)
            result && setLoadingInfo({ loadingVisible: false, txt: '' })
            message.success('导出进行中。请稍后去导出记录列表查看')
        } catch {
            setLoadingInfo({ loadingVisible: false, txt: '' })
        }
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        pgCfg,
        columnsSetVisible,
        loading,
        orders,
        stockType,
        pageMode,
        /***method***/
        setDataSource,
        resetSearchParam,
        updateSearchParam,
        loadData,
        setColumnsSetVisible,
        exportData,
    }
})
