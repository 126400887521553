import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'

//详情
export interface IDetail {
    //分支编码
    branch?: string
    //门店名称
    shopName?: string
    //单据类型
    type?: string
    //单据编码
    code?: string
    //单位名称
    bentityName?: string
    //单据状态
    status?: string
    //制单员
    createUser?: string
    //制单时间
    createTime?: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    shopName?: string
    code?: string
    type?: string
    createUser?: string
    status?: any
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
}

class Service {
    async page(searchVo: ISearch, config: object = {}) {
        const apiUrl = '/ims/homePage/unfinishedStatisticsDeatil'
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, config)
    }
    //导出
    async export(searchVo: ISearch) {
        let apiUrl = '/yh-export/unfinished/export'
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{} })
    }
}

export const service = new Service()
