import { Button } from 'antd'
import React from 'react'
import './modals.less'

interface IModalProps {
    visible: boolean
    title?: string | JSX.Element
    closable?: boolean
    mask?: boolean
    maskClosable?: boolean
    width?: number | string
    bodyHeight?: number
    children?: React.ReactNode
    showFooter?: boolean
    modalBodyStyle?: React.CSSProperties
    okText?: string
    onOk?: () => void
    cancelText?: string
    onCancel?: () => void
    footerButs?: React.ReactNode[]
}

export const YHRightModal = ({
    visible,
    title = '标题',
    onCancel = () => null,
    maskClosable = true,
    mask = true,
    width = 360,
    bodyHeight = 300,
    closable = true,
    children = null,
    showFooter = false,
    okText = '确定',
    onOk,
    cancelText = '取消',
    footerButs = [
        <Button onClick={onCancel} key="1">
            取消
        </Button>,
        <Button onClick={onOk} type="primary" key="2">
            确定
        </Button>,
    ],
    modalBodyStyle = undefined,
}: IModalProps): JSX.Element => {
    const handleClose = () => {
        onCancel?.()
    }

    return (
        <div className="modals" style={{ width: visible ? '100%' : '0%' }}>
            <div
                className={['modal-mask-warp', mask && visible ? 'modal-mask-show' : 'modal-mask-hide'].join(' ')}
                onClick={() => maskClosable && handleClose()}
            ></div>
            {visible ? (
                <div className="modal-main" style={{ width: typeof width === 'string' ? width : width + 'px', height: '100vh' }}>
                    <div className="modal-head">
                        <span>{title}</span>
                        {closable && (
                            <span className="close-btn" onClick={handleClose}>
                                X
                            </span>
                        )}
                    </div>
                    <div className="modal-body" style={modalBodyStyle}>
                        {children}
                    </div>
                    <div className="modal-footer" hidden={!showFooter}>
                        {footerButs && footerButs.map((item) => item)}
                    </div>
                </div>
            ) : null}
        </div>
    )
}
