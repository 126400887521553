import { httpRequest } from '@/utils/HttpRequest'
import { Key } from 'react'

/***************************fms************************* */
interface ITreeDs<T> {
    enable?: number
    code?: string
    children?: T[]
}

export interface ITreeData<T> {
    key: Key
    title?: string
    children?: ITreeData<T>[] | undefined
}

export interface IFieldValue<T> {
    [key: string]: T[keyof T]
}

export function trasDsToTreeData<T extends ITreeDs<T>>(ds: T[], keyField: keyof T, titleField: keyof T): ITreeData<T>[] {
    return ds.map((el) => {
        const key = el[keyField] as any
        const title = el[titleField] as any
        const children = el.children && el.children.length > 0 ? trasDsToTreeData(el.children, keyField, titleField) : undefined
        const disabled = el.enable === 0
        return {
            el,
            key,
            title,
            disabled,
            value: el['code'],
            selectable: !disabled,
            children,
        }
    })
}
/*********************************************************************************** */

export interface IDictSearch {
    page?: {
        pageSize: number
        pageNum: number
    }
    value?: string
    sourceApp?: string
    [key: string]: any
}

export interface IDictDetail {
    note: string
    ver: number
    code: string
    sourceAuthKey?: string
    pcode: string | null
    children: IDictDetail[]
    enable: number | boolean
    name: string
    nameEn?: string
    branch?: string
    value: string
    key: string
    status?: number | boolean
    [key: string]: any
}

export interface IDictInsert {
    sourceApp?: string
    branch?: string
    code: string
    name: string
    value?: string
    pcode?: string | null
    note?: string
}
export interface IDictUpdate {
    sourceApp?: string
    branch?: string
    code: string
    value?: string
}

export interface IDictOne {
    sourceApp?: string
    branch?: string
    code: string
}

export interface IDictSync {
    sourceApp?: string
    branch?: string
    branchList?: string[]
}

class DictService {
    //树列表
    async page(searchVo: IDictSearch) {
        const apiUrl = '/ims/dict/allTree'
        return await httpRequest.post<IDictDetail[]>(apiUrl, searchVo)
    }
    //新增
    async insert(insertVo: IDictInsert) {
        const apiUrl = '/ims/dict/insert'
        return await httpRequest.post<boolean>(apiUrl, insertVo)
    }
    //编辑
    async update(updateVo: IDictUpdate) {
        const apiUrl = '/ims/dict/update'
        return await httpRequest.post<boolean>(apiUrl, updateVo)
    }
    //获取一条
    async one(oneVo: IDictOne) {
        const apiUrl = '/ims/dict/one'
        return await httpRequest.post<IDictDetail>(apiUrl, oneVo)
    }

    //禁用
    async disable(oneVo: IDictOne) {
        const apiUrl = '/ims/dict/disable'
        return await httpRequest.post<boolean>(apiUrl, oneVo)
    }

    async remove(oneVo: IDictOne) {
        const apiUrl = '/ims/dict/remove'
        return await httpRequest.post<boolean>(apiUrl, oneVo)
    }
    async sync(obj: IDictSync) {
        const apiUrl = '/ims/base/dict/sync'
        return await httpRequest.post<boolean>(apiUrl, obj)
    }
}

export const dictService = new DictService()
