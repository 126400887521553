import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useNavModel from '../model/navModel'
import menuData from '../data/menuData.json'
import { Popover, Tag } from 'antd'
import { DownOutlined, DoubleLeftOutlined, DoubleRightOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { IMenuNode } from './AppMenu'
import FontIcon from '@/components/YhIcon'

function getNavSelectedMenu(menuData: IMenuNode[], menuId: String) {
    function filterMenus(menus: IMenuNode[]): IMenuNode | undefined {
        for (const menu of menus) {
            if (menu.id === menuId) return menu
            if (menu.children && menu.children.length > 0) {
                const ret = filterMenus(menu.children)
                if (ret) return ret
            }
        }
    }

    return filterMenus(menuData)
}

function AppNav() {
    const history = useHistory()
    const location = useLocation()

    const { navTabs, selectedNavId, getSelectedNavState, selectNav, closeNav, initNav, closeOtnerNav } = useNavModel()

    useEffect(() => {
        const page = getNavSelectedMenu(menuData, selectedNavId)
        if (page) {
            //路由是否变化
            if (location.pathname !== page.path && location.pathname !== '/login')
                // console.log(Nav.getSelectNode('state'), history, "nav切换")
                history.push({ pathname: page.path, state: getSelectedNavState() })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNavId])

    let scroll = (val: 'left' | 'right') => {
        let scroolEle = document.getElementById('nav-center-box') as HTMLElement
        let scrollWidth = scroolEle.scrollWidth as number
        let scrollLeft = scroolEle.scrollLeft as number
        let moveVal = 200
        let scrollVal = val === 'left' ? scrollLeft - moveVal : scrollLeft + moveVal
        if (scrollVal < 0) {
            scrollVal = 0
        } else if (scrollVal > scrollWidth) {
            scrollVal = scrollWidth
        }
        scroolEle.scrollTo({
            left: scrollVal,
            behavior: 'smooth',
        })
    }

    const onCloseAll = () => {
        initNav()
    }
    const onCoseThis = () => {
        closeNav(selectedNavId)
    }
    const onCoseOther = () => {
        closeOtnerNav(selectedNavId)
    }

    const content = (
        <>
            <div onClick={onCloseAll} style={{ cursor: 'pointer', fontSize: '14px', lineHeight: '30px', color: 'rgba(0,0,0,0.65)' }}>
                <CloseCircleOutlined style={{ marginRight: '3px' }} />
                <span>关闭所有标签</span>
            </div>
            <div onClick={onCoseOther} style={{ cursor: 'pointer', fontSize: '14px', lineHeight: '30px', color: 'rgba(0,0,0,0.65)' }}>
                <CloseCircleOutlined style={{ marginRight: '3px' }} />
                <span>关闭其他标签</span>
            </div>
            <div onClick={onCoseThis} style={{ cursor: 'pointer', fontSize: '14px', lineHeight: '30px', color: 'rgba(0,0,0,0.65)' }}>
                <CloseCircleOutlined style={{ marginRight: '3px' }} />
                <span>关闭当前标签</span>
            </div>
        </>
    )

    return (
        // <NavBox
        //     distance={20}
        //     transitionDuration={200}
        //     axis="x"
        //     onSortEnd={sortNav}>
        //     {
        //         navTabs.map((item, index) => (
        //             <Nav
        //                 key={item.id}
        //                 index={index}
        //             >
        //                 <Tag
        //                     key={item.id}
        //                     closable={item.closable}
        //                     className={["non-copyable", item.id === selectedNavId ? "tag-checked" : ""].join(' ')}
        //                     onClick={() => selectNav(item.id)}
        //                     onClose={() => closeNav(item.id)}
        //                 >
        //                     {item.name}
        //                 </Tag>
        //             </Nav>
        //         ))
        //     }
        // </NavBox>
        <div className="nav-box">
            <div
                className="nav-left nav-icon"
                onClick={() => {
                    scroll('left')
                }}
            >
                {/* <img src={navLeftIcon} /> */}
                <DoubleLeftOutlined />
            </div>
            <div className="nav-center" id="nav-center-box">
                <div className="nav-center-box">
                    {navTabs.map((item, index) => (
                        <Tag
                            key={item.id}
                            closable={item.closable}
                            className={['non-copyable', 'nav', item.id === selectedNavId ? 'tag-checked' : ''].join(' ')}
                            onClick={() => selectNav(item.id)}
                            onClose={() => closeNav(item.id)}
                            closeIcon={
                                <FontIcon
                                    type="icon-yeqian-yiruguanbi"
                                    style={{ fontSize: '10px', cursor: 'pointer', color: '#8a8a8a', position: 'relative', bottom: '11px' }}
                                ></FontIcon>
                            }
                        >
                            {item.name}
                        </Tag>
                    ))}
                </div>
            </div>
            <div
                className="nav-right nav-icon"
                onClick={() => {
                    scroll('right')
                }}
            >
                {/* <img src={navRightIcon} /> */}
                <DoubleRightOutlined />
            </div>
            <Popover placement="bottomRight" content={content}>
                <div className="nav-right nav-icon">
                    <DownOutlined />
                </div>
            </Popover>
        </div>
    )
}

export default AppNav
