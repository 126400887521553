import React from 'react'
import { ISearchField, ISearchProp } from '../Types'
import { DatePicker } from 'antd'
import moment from 'moment'

type PropType = ISearchProp & ISearchField

const { RangePicker } = DatePicker
export const SearchRangePicker = ({ searchValues, rangePickerFieldName, ...props }: PropType) => {
    const fieldName = rangePickerFieldName ?? ['startTime', 'endTime']

    const handleStrLength = (str: string): boolean => {
        if (!str) return false
        return str.length > 10 ? false : true
    }

    return (
        <RangePicker
            size={props.size}
            showTime={props.showTime}
            placeholder={props.placeholder as [string, string]}
            className="ant-picker-round"
            format={props.format || 'YYYY-MM-DD HH:mm:ss'}
            value={
                !searchValues?.[fieldName[0]] || !searchValues?.[fieldName[1]] || handleStrLength(searchValues?.[fieldName[0]] as string)
                    ? null
                    : [moment(searchValues?.[fieldName[0]]), moment(searchValues?.[fieldName[1]])]
            }
            onChange={(value, timeStr) => {
                let obj = {}
                if (timeStr[0] && timeStr[1]) {
                    obj[fieldName[0]] = props.format ? (timeStr[0] && timeStr[0].length === 10 ? timeStr[0] + ' 00:00:00' : '') : timeStr[0]
                    obj[fieldName[1]] = props.format ? (timeStr[1] && timeStr[1].length === 10 ? timeStr[1] + ' 23:59:59' : '') : timeStr[1]
                } else {
                    obj[fieldName[0]] = undefined
                    obj[fieldName[1]] = undefined
                }
                props.onChange?.(obj)
                if (props.triggerSearch) props.handleSearch?.(obj)
            }}
            disabled={props.disabled}
        />
    )
}
