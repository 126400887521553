import { useEffect, useState } from 'react'
import { Table } from 'antd'
import { useModel } from '../model'
import useGlobalModel from '@/model/globalModel'
import YhTootip from '@/components/YhTootip'
import { YhTableSort } from '@/components/YhTableSort'
import { commonService } from '@/service/common'
import { SettingOutlined } from '@ant-design/icons'

const sortKey = 'inventoryStatistics'

export function DataTable() {
    const {
        user: { branch },
    } = useGlobalModel()
    const { dataSource, pgCfg, loadData, columnsSetVisible, setColumnsSetVisible, orders, loading, tableTotal } = useModel()

    useEffect(() => {
        loadData()
        getColumns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRegularColumns = [
        {
            title: (
                <>
                    <span>
                        <SettingOutlined
                            onClick={() => {
                                setColumnsSetVisible(true)
                            }}
                        />
                    </span>
                    <span>序号</span>
                </>
            ),
            width: 80,
            fixed: 'left',
            align: 'center',
            render: (t, r, i) => i + 1,
        },
    ]
    const defaultColumns = [
        {
            title: '分支编码',
            width: 100,
            dataIndex: 'branch',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '门店名称',
            width: 140,
            dataIndex: 'shopName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '商品编码',
            width: 140,
            dataIndex: 'materialCode',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '商品名称',
            width: 200,
            dataIndex: 'materialName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '200px' }} />,
        },
        {
            title: '替换编号',
            width: 140,
            dataIndex: 'replaceCode',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '仓库',
            width: 100,
            dataIndex: 'warehouseName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '货位',
            width: 140,
            dataIndex: 'shelfCode',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryQuantity',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '投放库存',
            width: 100,
            dataIndex: 'tfNum',
            isSet: true,
            render: (text) => <YhTootip text={text || 0} style={{ width: '100px' }} />,
        },
        {
            title: '占用库存',
            width: 100,
            dataIndex: 'occupyNum',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '可用库存',
            width: 100,
            dataIndex: 'availableNum',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '最新进价',
            width: 100,
            dataIndex: 'latestPurPrice',
            isSet: true,
            render: (text) => <YhTootip text={'￥' + (text || 0)} style={{ width: '100px' }} />,
        },
        {
            title: '库存成本（明细汇总）',
            width: 180,
            dataIndex: 'inventoryAmount',
            isSet: true,
            render: (text) => <YhTootip text={'￥' + (text || 0)} style={{ width: '180px' }} />,
        },
        {
            title: '库存成本（最新采购价）',
            width: 180,
            dataIndex: 'inventoryAmountByLatestPurPrice',
            isSet: true,
            render: (text) => <YhTootip text={'￥' + (text || 0)} style={{ width: '180px' }} />,
        },
        {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '160px' }} />,
        },
    ]

    const [columns, setColumns] = useState<any[]>([])

    const getColumns = () => {
        commonService.userCustomizeConfigOne(sortKey).then((res) => {
            if (!res.retData) {
                setColumns([...getRegularColumns, ...defaultColumns])
            } else {
                let newColumns: any[] = [...getRegularColumns]
                let data = JSON.parse(res.retData.val)
                data.forEach((element) => {
                    if (element.isShow) {
                        newColumns.push({ ...defaultColumns.find((i) => i.dataIndex === element.key), sorter: element.isSort })
                    }
                })
                setColumns(newColumns)
            }
        })
    }

    const onSuccess = () => {
        setColumnsSetVisible(false)
        getColumns()
    }

    const onChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            if (sorter.order) {
                orders.current = [{ column: sorter.field, asc: sorter.order === 'ascend' }]
            } else {
                orders.current = []
            }
            loadData()
        }
    }

    return (
        <>
            <Table
                rowKey={(record) => record.id || record.index}
                onChange={onChange}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                pagination={{
                    ...pgCfg,
                    position: ['bottomRight'],
                    showTotal: () => `共${pgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    },
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 'max-content', y: 'calc(100vh - 380px)' }}
                summary={() => (
                    <Table.Summary fixed>
                        <Table.Summary.Row className="table-total-box">
                            <Table.Summary.Cell index={0}>
                                <div className="title">合计</div>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} />
                            <Table.Summary.Cell index={2} />
                            <Table.Summary.Cell index={3} />
                            <Table.Summary.Cell index={4} />
                            <Table.Summary.Cell index={5} />
                            <Table.Summary.Cell index={6} />
                            <Table.Summary.Cell index={7} />
                            {branch === 'scm' ? (
                                <Table.Summary.Cell index={8}>
                                    <span className="green">{tableTotal?.totalInventoryNum}</span>
                                </Table.Summary.Cell>
                            ) : (
                                <Table.Summary.Cell index={8}>
                                    <span className="green">{tableTotal?.stockNum}</span>
                                </Table.Summary.Cell>
                            )}
                            <Table.Summary.Cell index={9} />
                            <Table.Summary.Cell index={10} />
                            <Table.Summary.Cell index={11} />
                            <Table.Summary.Cell index={12} />
                            {branch === 'scm' ? (
                                <Table.Summary.Cell index={13}>
                                    <span className="red">￥{tableTotal?.totalInventoryAmount || 0}</span>
                                </Table.Summary.Cell>
                            ) : (
                                <Table.Summary.Cell index={13}>
                                    <span className="red">￥{tableTotal?.stockAmount || 0}</span>
                                </Table.Summary.Cell>
                            )}
                            {branch === 'scm' ? (
                                <Table.Summary.Cell index={14}>
                                    <span className="red">￥{tableTotal?.totalInventoryAmountByLatestPurPrice || 0}</span>
                                </Table.Summary.Cell>
                            ) : (
                                <Table.Summary.Cell index={14}>
                                    <span className="red">￥{tableTotal?.stockAmountByLatestPurPrice || 0}</span>
                                </Table.Summary.Cell>
                            )}
                            <Table.Summary.Cell index={15} />
                            <Table.Summary.Cell index={16} />
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
            <YhTableSort
                sortKey={sortKey}
                visible={columnsSetVisible}
                setVisible={setColumnsSetVisible}
                defaultColumns={defaultColumns}
                onSuccess={onSuccess}
            />
        </>
    )
}
