import { useEffect, useRef, useState } from 'react'
import { Button } from 'antd'
import { YhFormBox } from '@/components/YhFormBoxVertical'
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef } from '@/components/YhFormBoxVertical/Types'
import useNavModel, { RouterParamType } from '@/model/navModel'
import { YHDetailNav } from '@/components/YHDetailNav'
import { TabsBox } from './TabsBox'
import { useModel } from '../../model'
import { setStatusTag } from '@/App'

export const OutWarehouseListDetails = ({ mode }) => {
    const { editFormVal, updateEditFormVal, getOne } = useModel()
    const { selectedNavId, replaceNav, getSelectedNavState } = useNavModel(({ selectedNavId }) => [selectedNavId])
    const formRef = useRef<IYhFormRef>()
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType)

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        getOne(JSON.parse(window.sessionStorage.getItem('outWarehouseDetails') as string)?.code)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.sessionStorage.getItem('outWarehouseDetails')])

    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, '3-1')
    }

    //保留缓存及回显表单
    const saveCache = (val: { [key: string]: any }, isFormItem: boolean = true) => {
        isFormItem && formRef.current?.setFieldsValue(val)
        updateEditFormVal(val)
    }

    const onChange = (changedValues: { [key: string]: any }) => {
        saveCache(changedValues, false)
    }

    const baseFormItem: IYhFormItemsBox = {
        title: '基础信息',

        style: { padding: '20px', marginBottom: '10px' },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '单位名称',
                fieldName: 'supplierName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '联系人',
                fieldName: 'contact',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '联系电话',
                fieldName: 'phone',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '对应实体',
                fieldName: 'bentityName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '单据类型',
                fieldName: 'tradeTypeName',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '备注',
                fieldName: 'note',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '创建人',
                fieldName: 'createUser',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '创建日期',
                fieldName: 'createTime',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '编辑人',
                fieldName: 'updateUser',
                disable: true,
                span: 4,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '编辑时间',
                fieldName: 'updateTime',
                disable: true,
                span: 4,
            },
        ],
    }

    const AccountingFromConfig: IYhFormBox<any> = {
        formRef,
        labelAlign: 'right',
        formValues: editFormVal,
        onChange,
        boxs: [baseFormItem],
    }
    const action = (
        <>
            <Button shape="round" onClick={onClose} style={{ float: 'right' }}>
                返回
            </Button>
        </>
    )

    return (
        <>
            <YHDetailNav
                title={
                    <span>
                        单据编号:
                        <span style={{ margin: '0 30px 0 10px', fontWeight: 600, color: '#3e67f4' }}>{editFormVal.code}</span>
                        <span style={{ marginRight: '10px' }}>单据状态:</span>
                        {setStatusTag(editFormVal.status)}
                    </span>
                }
                btns={action}
                style={{ marginBottom: '-5px' }}
            />
            <div style={{ maxHeight: 'calc(100vh - 180px)', overflowY: 'auto', paddingBottom: '20px' }}>
                <YhFormBox {...AccountingFromConfig} />
                <TabsBox mode={detailsInfo.mode} />
            </div>
        </>
    )
}
