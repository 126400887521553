import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { IRet } from '../types/AppTypes'
import { INTERNAL_SERVER_ERROR_CODE, getToken, getBaseRequestUrl, DEFAULT_REQUEST_TIMEOUT } from './constants'
import { message } from 'antd'

import { closeWebPage } from '@/utils/utils'
interface IAxiosRequestConfig extends AxiosRequestConfig {
    token?: string
}

class HttpRequest {
    private inst: AxiosInstance

    constructor(config: IAxiosRequestConfig = {}) {
        let defaultConfig = {
            baseURL: getBaseRequestUrl(),
            timeout: DEFAULT_REQUEST_TIMEOUT,
        }
        this.inst = axios.create({ ...defaultConfig, ...config })
        this.interceptRequest()
        this.interceptResponse()
    }

    private interceptRequest() {
        this.inst.interceptors.request.use(
            (config) => {
                config.headers.Authorization = getToken()
                return config
            },
            (error) => Promise.reject(error)
        )
    }

    private interceptResponse() {
        this.inst.interceptors.response.use(
            (response) => {
                let _errorCode = response.data.retCode
                if (_errorCode && _errorCode !== 200) {
                    message.error(`${response.data.retMsg}`)
                    return Promise.reject(response)
                }
                return response
            },
            (error) => {
                let res = error.response as AxiosResponse
                // if (res && res.status && res.status === INTERNAL_SERVER_ERROR_CODE) {
                if (res && res.status && (res.status === 400 || res.status === INTERNAL_SERVER_ERROR_CODE)) {
                    //处理自定义业务异常
                    //....
                    let retData = res.data as IRet<any>
                    message.error(`${retData.retMsg}[${retData.retData}]`)

                    if (res?.data?.retMsg.indexOf('登录信息失效') !== -1) {
                        if (sessionStorage.getItem('loginType') === 'account') {
                            setTimeout(() => {
                                const _user = JSON.parse(sessionStorage.getItem('ims.user') as string)
                                sessionStorage.setItem('ims.user', JSON.stringify({ ..._user, shopId: '', shopName: '', shopCode: '', token: '' }))
                                sessionStorage.setItem('token', '')
                                window.location.href = '/'
                            }, 1500)
                        } else {
                            closeWebPage()
                        }
                    }
                } else {
                    message.error('请求超时')
                }
                return Promise.reject(error)
            }
        )
    }

    private responseTypeCheck(res: AxiosResponse) {
        const resType = res.headers['content-type']
        if (resType !== 'application/json') {
            console.log('只支持json返回数据!')
            //.....
        }
    }

    async request<T>(config: IAxiosRequestConfig) {
        let ret = await this.inst.request<IRet<T>>(config)
        this.responseTypeCheck(ret)
        return ret.data
    }

    async post<T>(url: string, data?: any, config?: IAxiosRequestConfig) {
        let ret = await this.inst.post<IRet<T>>(url, data, config)
        this.responseTypeCheck(ret)
        return ret.data
    }

    async postFile<T>(url: string, data?: any, config?: IAxiosRequestConfig) {
        let ret = await this.inst.post<IRet<T>>(url, data, { ...config, responseType: 'blob' })
        return ret
    }

    async get<T>(url: string, config?: IAxiosRequestConfig) {
        let ret = await this.inst.get<IRet<T>>(url, config)
        this.responseTypeCheck(ret)
        return ret.data
    }
    async getFile<T>(url: string, config?: IAxiosRequestConfig) {
        let ret = await this.inst.get<T>(url, { ...config, responseType: 'blob' })
        return ret
    }

    async put<T>(url: string, data?: any, config?: IAxiosRequestConfig) {
        let ret = await this.inst.put<IRet<T>>(url, data, config)
        return ret.data
    }

    async delete<T>(url: string, config?: IAxiosRequestConfig) {
        let ret = await this.inst.delete<IRet<T>>(url, config)
        return ret.data
    }

    async head<T>(url: string, config?: IAxiosRequestConfig) {
        let ret = await this.inst.head<IRet<T>>(url, config)
        return ret.data
    }

    async options<T>(url: string, config?: IAxiosRequestConfig) {
        let ret = await this.inst.options<IRet<T>>(url, config)
        return ret.data
    }

    async patch<T>(url: string, data?: any, config?: IAxiosRequestConfig) {
        let ret = await this.inst.patch<IRet<T>>(url, data, config)
        return ret.data
    }
}

export const httpRequest = new HttpRequest()
