import { getBaseRequestUrl, getToken } from './constants'
import { message } from 'antd'

interface FilrResponse {
    retCode: number
    retData: string
    retMsg: string
    success: boolean
}
interface File {
    uid?: string
    name?: string
    status?: string
    response: FilrResponse
    linkProps?: any
}
interface Upload {
    file: File
    fileList: any
    event: any
}
//callback  上传返回的数据，  返回undefined 是为拉清楚loading。

export function importPropsConfig(url: string, callback: any, loadingFn?: () => void): object {
    let cfg = {
        name: 'file',
        action: `${getBaseRequestUrl()}${url}`,
        timeout: 300000,
        headers: {
            authorization: getToken(),
            timeout: 300000,
        },
        data: {
            appCode: 'ims',
            fileMode: 0, //0私有  1是共有
        },
        onChange(info: Upload) {
            loadingFn?.()
            try {
                if (info.file.status === 'done' && (info.file.response.retCode === 0 || info.file.response.retCode === 200)) {
                    message.info({
                        content: `${info.file.name}导入${
                            typeof info.file.response.retData === 'string' ? info.file.response.retData : info.file.response.retMsg
                        }`,
                        duration: 10,
                    })
                    callback?.(info.file)
                } else if (info.file.status === 'error' && info.file.response && info.file.response.retData) {
                    message.error(`${info.file.response.retData}：${info.file.name}导入异常，请尝试重新导入`)
                    callback?.(undefined)
                } else if (info.file.status === 'error') {
                    message.error('导入错误')
                    callback?.(undefined)
                } else if (!info.file.status) {
                    callback?.(undefined)
                }
            } catch (error) {
                message.warning('导出异常，请重新尝试')
                callback?.(undefined)
            }
        },
    }
    return cfg
}
