import { useState } from 'react'
import { createModel } from 'hox'
import useStorageState from '../hooks/useStorageState'
import { PaginationProps } from 'antd/es/pagination'
import { ISelectOption } from '@/components/YhFormBox/Types'
import { IPgProps, ISelectOptions, IWarehouseDetail } from '@/types/AppTypes'
import { publicService } from '@/views/public/publicService'
import { commonService } from '@/service/common'

export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.current,
    }
}
export function transPgToPaginationTotal(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.current,
    }
}

export function fn(): Promise<ISelectOption[]> {
    let arr: ISelectOption[] = []
    return Promise.resolve(arr)
}

export type RoleType = {
    id: string
    name: String
}
export type UserType = {
    id: string
    userName: string
    password: String
    token: string
    shopId: string
    shopName: string
    shopCode: string
    loginType?: 'account' | 'token'
    [key: string]: any
}

export interface ICiStaticResourcesType {
    image: string
    video: string
}

export type LoadingType = { loadingVisible: boolean; txt: string }

type GlobalModelType = {
    user: UserType
    setUser: (e: UserType) => void
    resetUser: () => void

    toggleMenu: () => void
    cm: CmType
    resetCm: () => void
    loadingInfo: LoadingType
    setLoadingInfo: (origin: LoadingType) => void

    whDs: ISelectOptions[]
    // warehouseloadDataAll: (shopCode?: string) => void,
    warehouseList: IWarehouseDetail[]
    setWarehouseList: (value: IWarehouseDetail[]) => void

    beDs: ISelectOptions[]
    setBeDs: (value: ISelectOptions[] | ((previousState?: ISelectOptions[] | undefined) => ISelectOptions[])) => void
    // beLoadDataAll: () => void,
    requestCacheAll: (shopCode?: string) => void

    beShopDs: ISelectOptions[]
    setBeShopDs: (value: ISelectOptions[] | ((previousState?: ISelectOptions[] | undefined) => ISelectOptions[])) => void

    shopDs: ISelectOptions[]
    setShopDs: (origin: ISelectOptions[]) => void

    CustomerData: ISelectOptions[]
    setCustomerData: (origin: ISelectOptions[]) => void
    clearCustomerData: (origin: ISelectOptions[]) => void

    supplierDs: ISelectOptions[]
    // supplierLoadDataAll: (shopCode?: string) => void,

    resourceAuth: string[]
    setResourceAuth: (origin: string[]) => void

    menuAuth: string[]
    setMenuAuth: (origin: string[]) => void

    // fetchSupplierList: (value: string) => Promise<ISelectOption[]>,
    // fetchOfferList: (value: string) => Promise<ISelectOption[]>,
    // fetchSalesList: (value: string) => Promise<ISelectOption[]>,
    // fetchSaleBySupplier: (value: string) => Promise<ISelectOption[]>,
}

const initUserState = {
    id: '',
    userName: '',
    password: '',
    token: '',
    shopId: '',
    shopName: '',
    shopCode: '',
    branch: '',
}

type CmType = {
    collapsed: boolean
}
const initCmState = {
    collapsed: false,
}
//返回列表韦options的格式
export const transOptions = (list: any[]) => {
    return list.length !== 0 ? list.map((item) => ({ label: item.name, value: item.code, disabled: item.enable === 0 ? true : false })) : []
}
function useModel(): GlobalModelType {
    //客户数据
    const [CustomerData, setCustomerData, clearCustomerData] = useStorageState<ISelectOptions[]>('customerList', [])
    //所有仓库
    const [whDs, setwhDs] = useStorageState<ISelectOptions[]>('scm.waerhouseDs', [])
    //仓库数据
    const [warehouseList, setWarehouseList] = useStorageState<IWarehouseDetail[]>('warehouseList', [])
    //经营实体
    const [beDs, setBeDs] = useStorageState<ISelectOptions[]>('beDs', [])
    //对应门店的经营实体
    const [beShopDs, setBeShopDs] = useStorageState<ISelectOptions[]>('scm.beShopDs', [])
    //所有门店
    const [shopDs, setShopDs] = useStorageState<ISelectOptions[]>('ims.shopDs', [])
    //用户所有菜单权限
    const [menuAuth, setMenuAuth] = useStorageState<string[]>('ims.user.menus', [])
    //用户所有资源权限
    const [resourceAuth, setResourceAuth] = useStorageState<string[]>('ims.user.resource', [])
    //用户所有信息
    const [user, setUser, resetUser] = useStorageState<UserType>('ims.user', initUserState)
    // //所有供应商
    const [supplierDs] = useStorageState<ISelectOptions[]>('ims.supplierDs', [])
    const [cm, setCm, resetCm] = useStorageState<CmType>('ims.cm', initCmState)

    //初始页数
    const searchParams = { pageSize: -1, pageNum: 1 }

    //全局loading
    const [loadingInfo, setLoadingInfo] = useState<LoadingType>({ loadingVisible: false, txt: '' })

    const toggleMenu = () => {
        setCm({
            ...cm,
            collapsed: !cm.collapsed,
        })
    }

    // //初始所有实体
    const beloadDataAll = async () => {
        let { retData } = await commonService.bePage({})
        setBeDs(
            retData.map((item) => ({
                label: item.name,
                value: item.id,
                id: item.id,
                phone: item.phone,
                address: item.address,
            }))
        )
    }

    //初始所有仓库
    const warehouseloadDataAll = async (shopId = user.shopId) => {
        let { retData } = await publicService.getWarehouseList({ ...searchParams, shopId })
        setwhDs(
            retData.map((item) => ({
                id: item.id,
                label: item.name,
                value: item.code,
            }))
        )
    }

    //所有缓存加载
    const requestCacheAll = (shopId = user.shopId) => {
        beloadDataAll()
        warehouseloadDataAll(shopId)
    }

    return {
        user,
        setUser,
        resetUser,
        cm,
        toggleMenu,
        resetCm,
        loadingInfo,
        setLoadingInfo,
        whDs,
        warehouseList,
        setWarehouseList,
        requestCacheAll,
        supplierDs,
        // beDs,
        beDs,
        setBeDs,
        beShopDs,
        setBeShopDs,
        shopDs,
        setShopDs,
        resourceAuth,
        setResourceAuth,
        menuAuth,
        setMenuAuth,
        // warehouseloadDataAll,
        // fetchSupplierList,
        // fetchOfferList,
        // fetchSalesList,
        // fetchSaleBySupplier,
        CustomerData,
        setCustomerData,
        clearCustomerData,
    }
}

export const useGlobalModel = createModel(useModel)
export default useGlobalModel
