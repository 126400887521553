import { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import useGlobalModel from './model/globalModel'
import { Login } from './views/login/Login'
import Index from './layout'
import { useDictModel } from './views/base/dict/dictModel'
import { setToken } from './utils/constants'
import TokenLogin from './views/login/TokenLogin'
import { urlParse } from '@/utils/utils'

function App() {
    const {
        user: { token },
        requestCacheAll,
    } = useGlobalModel(({ user }) => [user])
    const { loadData: dictLoadData } = useDictModel(() => [])

    useEffect(() => {
        if (token) {
            dictLoadData() //初始字典缓存
            requestCacheAll()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    function loginCheck() {
        // const url = 'https://localhost:3000?token=90d587565ba24df99d4f804fb931d2c8'
        const url = window.location.href
        let urlObj = urlParse(url)

        if (urlObj.token) {
            setToken(urlObj.token)
            return <TokenLogin token={urlObj.token} />
        }
        return <Login />
    }

    return (
        <Switch>
            <Route path="/login" component={() => loginCheck()} />
            <Route path="/" render={() => (token ? <Index /> : <Redirect to="/login" />)} />
        </Switch>
    )
}

export default App

export const setStatusTag = (status: string) => {
    let _class: string = 'table-status-tag '
    let _DarkGreen: string[] = ['已结款', '已完结']
    let _ForestGreen: string[] = ['已入库', '全部入库']
    let _OliveDrab: string[] = ['已出库', '全部出库']
    let _Chartreuse: string[] = ['已结算']
    let _Green: string[] = ['已开单', '已完成']

    let _Brown: string[] = ['已作废']
    let _IndianRed: string[] = ['驳回待结算']

    let _DarkGoldenrod: string[] = ['已补单']

    let _Orange: string[] = ['部分入库']
    let _Chocolate: string[] = ['已驳回']

    let _Maroon: string[] = ['待审核']
    let _DarkViolet: string[] = ['调拨采']
    let _DeepPink: string[] = ['调拨销']

    let _CornflowerBlue: string[] = ['待结算']
    let isHas: boolean = false
    if (_DarkGreen.indexOf(status) !== -1) {
        _class = _class + 'DarkGreen'
        isHas = true
    }
    if (_ForestGreen.indexOf(status) !== -1) {
        _class = _class + 'ForestGreen'
        isHas = true
    }
    if (_OliveDrab.indexOf(status) !== -1) {
        _class = _class + 'OliveDrab'
        isHas = true
    }
    if (_Chartreuse.indexOf(status) !== -1) {
        _class = _class + 'Chartreuse'
        isHas = true
    }
    if (_Green.indexOf(status) !== -1) {
        _class = _class + 'Green'
        isHas = true
    }
    if (_Brown.indexOf(status) !== -1) {
        _class = _class + 'Brown'
        isHas = true
    }
    if (_IndianRed.indexOf(status) !== -1) {
        _class = _class + 'IndianRed'
        isHas = true
    }

    if (_DarkGoldenrod.indexOf(status) !== -1) {
        _class = _class + 'DarkGoldenrod'
        isHas = true
    }

    if (_Orange.indexOf(status) !== -1) {
        _class = _class + 'Orange'
        isHas = true
    }
    if (_Chocolate.indexOf(status) !== -1) {
        _class = _class + 'Chocolate'
        isHas = true
    }

    if (_Maroon.indexOf(status) !== -1) {
        _class = _class + 'Maroon'
        isHas = true
    }
    if (_DarkViolet.indexOf(status) !== -1) {
        _class = _class + 'DarkViolet'
        isHas = true
    }
    if (_DeepPink.indexOf(status) !== -1) {
        _class = _class + 'DeepPink'
        isHas = true
    }

    if (_CornflowerBlue.indexOf(status) !== -1) {
        _class = _class + 'CornflowerBlue'
        isHas = true
    }

    if (!isHas) {
        _class = _class + 'Blue'
    }
    return <span className={_class}>{status}</span>
}
