import { useState, useEffect } from 'react'
import { Menu } from 'antd'
import menuData from '../data/menuData.json'
import useNavModel from '../model/navModel'
import useGlobalModel from '../model/globalModel'
import FontIcon from '../components/YhIcon'
import { forFind } from '@/utils/utils'
import type { MenuProps } from 'antd'

//定义菜单参数类型
export interface IMenuNode {
    id: string
    name: string
    path: string
    icon: string
    parent: string
    closable: boolean
    isShow: boolean
    children: IMenuNode[]
}

function AppMenu() {
    const { addNav, selectedNavId } = useNavModel()
    const { menuAuth } = useGlobalModel()
    const [selectedMenuKey, setSelectedMenuKey] = useState<string>('')
    const [openKeys, setOpenKeys] = useState<string[]>(['1'])
    const [menusTree, setMenusTree] = useState<MenuProps['items']>([]) //设置权限后得菜单树

    // //设置菜单权限
    useEffect(() => {
        let originTree = [...menuData]
        function setMenuShow(array: IMenuNode[]) {
            array.forEach((item) => {
                //有权限就设置为展示
                item.isShow = menuAuth.indexOf(item.path) >= 0 ? true : false
                if (item.children && item.children.length > 0) setMenuShow(item.children)
            })
        }
        setMenuShow(originTree)
        let _newMenu = originTree.map((node) => newMenu(node))
        setMenusTree(_newMenu as MenuProps['items'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuAuth])

    //对应详情展示及选中菜单展开
    useEffect(() => {
        let menuInfo = forFind(menuData, selectedNavId) ?? ({} as IMenuNode)
        let selectedMenuId: string = menuInfo.isShow ? menuInfo.id : menuInfo.parent ?? ''
        let newOpenKey: string = selectedMenuId.split('-')[0]
        if (openKeys.indexOf(newOpenKey) === -1) {
            // let newOpenKeys: string[] = [...openKeys];
            let newOpenKeys: string[] = []
            newOpenKeys.push(newOpenKey)
            setOpenKeys(newOpenKeys)
        }
        setSelectedMenuKey(selectedMenuId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNavId])

    //选中的submenu Key
    const onOpenChange = (keys: React.Key[]) => {
        setOpenKeys(keys as string[])
    }

    const newMenu = (node) => {
        if (node.isShow && node['children'].length > 0) {
            let filterNode = node['children'].filter((item) => item.isShow)
            return filterNode.length > 0
                ? {
                      key: node.id,
                      icon: <FontIcon type={node.icon} style={{ fontSize: '14px', color: '#8a8a8a' }} />,
                      label: node.name,
                      children: node.children.map((el) => newMenu(el)),
                  }
                : {
                      key: node.id,
                      icon: <FontIcon type={node.icon} style={{ fontSize: '14px', color: '#8a8a8a' }} />,
                      label: node.name,
                  }
        } else if (node.isShow) {
            return {
                key: node.id,
                icon: <FontIcon type={node.icon} style={{ fontSize: '14px', color: '#8a8a8a' }} />,
                label: node.name,
            }
        }
    }

    return (
        <>
            <Menu
                mode="inline"
                // theme="light"
                onOpenChange={onOpenChange}
                openKeys={openKeys}
                selectedKeys={[selectedMenuKey]}
                style={{ overflowX: 'hidden', overflowY: 'auto', height: 'calc(100vh - 84px)' }}
                items={menusTree}
                onClick={(item) => {
                    addNav(item.key)
                }}
            />
            {/* <Button onClick={() => toggleMenu()} className={`block  ${cm.collapsed ? 'layout-sider-menu-button--small' : 'layout-sider-menu-button'}`} type="link" shape="round" icon={<FontIcon type="icon-mulu" />} /> */}
        </>
    )
}

export default AppMenu
