import { FormInstance, Rule } from 'antd/es/form'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { ColProps } from 'antd/lib/grid/col'
import { FormLabelAlign } from 'antd/lib/form/interface'
import { IYhBox } from '../YhBox/Types'
import { MutableRefObject } from 'react'
import { NamePath } from 'rc-field-form/es/interface'
import { ISelectOptions, ITreeSelectData } from '@/types/AppTypes'

export enum FormItemTypeEnum {
    Input,
    Select,
    Datepicker,
    Switch,
    Checkbox,
    Radio,
    FileImage,
    Button,
    TreeSelect,
    AsyncSelect,
    TextArea,
    Other,
    TimePicker,
    InputNumber,
}

export interface ITreeData {
    value: string
    title: string
    children: ITreeData[]
}

export interface ISelectOption {
    label: string
    value: string | number
    [key: string]: string | number
}

export interface fileInfo {
    md5: string
    fileName: string
}

export interface IFormItem {
    type: FormItemTypeEnum
    fieldLabel: string | JSX.Element
    fieldName: string
    placeholder?: string
    ds?: ITreeData[] | ISelectOption[]
    labelCol?: { span?: number; offset?: number }
    hidden?: boolean
    allowClear?: boolean
    size?: SizeType
    disable?: boolean
    multiple?: boolean
    labelInValue?: boolean
    rules?: Rule[]
    span?: number
    colClassName?: string
    onSetFieldsValue?: (formValues: { [key: string]: any }) => void
    onSelectChange?: (value: string, option: ISelectOption) => void
    onSelectFocus?: () => void
    onTreeSelectChange?: (value: string, node: ITreeSelectData) => void
    onInputBlur?: (fieldName: string) => void
    md5List?: fileInfo[]
    treeData?: ITreeSelectData[]
    options?: ISelectOptions[]
    radioDs?: ISelectOptions[]
    selectMode?: 'multiple' | 'tags'
    selectfetchList?: (search: string) => Promise<ISelectOption[]>
    [key: string]: any
}

export interface IYhFormItemsBox extends IYhBox {
    disabled?: boolean
    items: IFormItem[]
}

export interface IYhFormBox<T = any> {
    formRef?: MutableRefObject<IYhFormRef<T> | undefined>
    colon?: boolean
    name?: string
    labelCol?: ColProps
    wrapperCol?: ColProps
    labelAlign?: FormLabelAlign
    onChange?: (changedValues: { [key: string]: any }, values: T) => void
    onFinish?: (values: T) => void
    onReset?: () => {}
    formValues?: {}
    disabled?: boolean
    boxs: IYhFormItemsBox[]
}

export interface IYhFormRef<T = any> {
    validateFields: () => Promise<T>
    getFieldsValue: () => T
    setFieldsValue: (changedValues: { [key: string]: any }) => void
    resetFields: (fields?: NamePath[]) => void
    inst: () => FormInstance
}

export interface IYhForm<T = any> {
    formRef?: MutableRefObject<IYhFormRef<T> | undefined>
    colon?: boolean
    name?: string
    labelCol?: ColProps
    labelAlign?: FormLabelAlign
    onChange?: (changedValues: { [key: string]: any }, values?: T) => void
    onFinish?: (values: T) => void
    onReset?: () => {}
    formValues?: any
    disabled?: boolean
    items: IFormItem[]
}
export interface IForm<T = any> {
    formRef?: MutableRefObject<IYhFormRef<T> | undefined>
    colon?: boolean
    name?: string
    labelCol?: ColProps
    labelAlign?: FormLabelAlign
    onChange?: (changedValues: { [key: string]: any }, values?: T) => void
    onFinish?: (values: T) => void
    onReset?: () => {}
    formValues?: any
    disabled?: boolean
}
