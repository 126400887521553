import { useEffect, useState, useRef } from 'react'
import * as echarts from 'echarts'
import styled from '@emotion/styled'
import { DatePicker, Spin } from 'antd'
import type { Moment } from 'moment'
import { EChartOption } from 'echarts'
import { useHomeModel } from '../homeModel'
import { moneyFormat } from '@/utils/utils'

const { RangePicker } = DatePicker

type RangeValue = [Moment | null, Moment | null] | null
type DataAll = any[]

export default function LineChart(props) {
    const {
        stockStatisticsTab,
        handleStockStatisticsTab,
        stockStatisticsLoading,
        stockStatisticsDetails,
        handleStockStatisticsDate,
        stockStatisticsType,
        setStockStatisticsType,
    } = useHomeModel()

    const chartRef = useRef<HTMLDivElement>(null)
    const [chart, setChart] = useState<echarts.ECharts>()
    let chartDataAll: DataAll = []
    const handleResize = () => chart?.resize()

    const [value, setValue] = useState<RangeValue>(null)
    const onChange = (val: RangeValue) => {
        handleStockStatisticsTab('')
        setValue(val)
        handleStockStatisticsDate(val)
    }

    let color1 = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(90, 216, 166, 0.2)',
        },
        {
            offset: 1,
            color: '#fff',
        },
    ])
    let color2 = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: 'rgba(91, 143, 249, 0.2)',
        },
        {
            offset: 1,
            color: '#fff',
        },
    ])
    let option: EChartOption = {
        grid: {
            top: 60,
            right: 30,
            bottom: 30,
            left: 50,
        },
        color: ['#5AD8A6', '#5B8FF9'],
        tooltip: {
            trigger: 'axis',
            formatter: (params: any) => {
                let _html = ''
                params?.forEach((item: any, index: number) => {
                    _html =
                        _html +
                        '<div><span style="width: 8px;height: 8px;border-radius: 50%;margin-right: 5px;display: inline-block;background: ' +
                        item.color +
                        '"></span><span style="margin-right: 5px;">' +
                        item.seriesName +
                        '：' +
                        chartDataAll![index]![item.dataIndex].num +
                        '</span><span style="margin-right: 5px;">' +
                        (index === 0 ? '入库数量：' : '出库数量：') +
                        chartDataAll![index]![item.dataIndex].stockIoNum +
                        '</span></div>'
                })
                return _html
            },
        },
        legend: {
            itemWidth: 11,
            itemHeight: 8,
            itemGap: 20,
            left: 10,
            icon: 'rect',
            data: ['入库单', '出库单'],
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: [],
            },
        ],
        yAxis: [
            {
                type: 'value',
                minInterval: 1,
            },
        ],
        series: [
            {
                name: '入库单',
                type: 'line',
                lineStyle: {
                    width: 2,
                },
                areaStyle: {
                    color: color1 as any,
                },
                data: [],
            },
            {
                name: '出库单',
                type: 'line',
                lineStyle: {
                    width: 2,
                },
                areaStyle: {
                    color: color2 as any,
                },
                data: [],
            },
        ],
    }

    const init = () => {
        if (chart) {
            window.removeEventListener('resize', handleResize)
        }

        let _dataAll: DataAll = []
        option!.xAxis![0]!.data = stockStatisticsDetails?.dates
        option?.series?.forEach((item, index) => {
            _dataAll.push([])
            item.data = stockStatisticsDetails?.dates?.map(() => {
                _dataAll[index].push({ num: 0, stockIoNum: 0 })
                return 0
            })
        })

        if (stockStatisticsDetails?.indetailList?.length !== 0) {
            stockStatisticsDetails?.indetailList?.forEach((item) => {
                stockStatisticsDetails?.dates?.forEach((date, index) => {
                    if (date === item.time) {
                        option!.series![0]!.data![index] = item.num || 0
                        _dataAll[0][index].num = item.num || 0
                        _dataAll[0][index].stockIoNum = item.stockIoNum || 0
                    }
                })
            })
        }
        if (stockStatisticsDetails?.outdetailList?.length !== 0) {
            stockStatisticsDetails?.outdetailList?.forEach((item) => {
                stockStatisticsDetails?.dates?.forEach((date, index) => {
                    if (date === item.time) {
                        option!.series![1]!.data![index] = item.num || 0
                        _dataAll[1][index].num = item.num || 0
                        _dataAll[1][index].stockIoNum = item.stockIoNum || 0
                    }
                })
            })
        }
        chartDataAll = _dataAll

        let chartInstance = echarts.getInstanceByDom(chartRef.current as HTMLDivElement)
        if (!chartInstance) {
            chartInstance = echarts.init(chartRef.current as HTMLDivElement)
        }
        chartInstance.setOption(option)
        window.addEventListener('resize', () => chartInstance?.resize())
        setChart(chartInstance)
    }

    useEffect(() => {
        if (stockStatisticsTab) {
            setValue(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stockStatisticsTab])
    useEffect(() => {
        if (stockStatisticsType === props.type) {
            handleStockStatisticsTab('week')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stockStatisticsType])
    useEffect(() => {
        setStockStatisticsType(props.type)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (stockStatisticsDetails.isInit) {
            return
        }

        init()

        return () => {
            window.removeEventListener('resize', handleResize)
            chart?.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stockStatisticsDetails])

    return (
        <Box className="index-content lineChart">
            <div className="index-content-title-bar">
                <span className="index-content-title-bar-title">出入库单统计</span>
                <div className="index-content-title-bar-more">
                    <div className="home-title-menu">
                        <div className="tabs">
                            <div
                                className={stockStatisticsTab === 'day' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleStockStatisticsTab('day')
                                }}
                            >
                                今日
                            </div>
                            <div
                                className={stockStatisticsTab === 'week' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleStockStatisticsTab('week')
                                }}
                            >
                                本周
                            </div>
                            <div
                                className={stockStatisticsTab === 'month' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleStockStatisticsTab('month')
                                }}
                            >
                                本月
                            </div>
                        </div>
                        <div className="select">
                            <RangePicker value={value} onChange={onChange} />
                        </div>
                    </div>
                </div>
            </div>

            <Spin tip="Loading..." spinning={stockStatisticsLoading}>
                <div className="content">
                    <div className="top">
                        <div className="list">
                            <div className="item">
                                <div className="inner">
                                    <div className="title">入库单数</div>
                                    <div className="main">{stockStatisticsDetails.inallNum}</div>
                                    <div className="info">
                                        <span>同比上周</span>
                                        <span className="red">0%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="inner">
                                    <div className="title">入库产品数量</div>
                                    <div className="main">{stockStatisticsDetails.inallStockIoNum}</div>
                                    <div className="info">
                                        <span>同比上周</span>
                                        <span className="red">0%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="inner">
                                    <div className="title">入库金额</div>
                                    <div className="main">{moneyFormat(stockStatisticsDetails.inallStockIoAmount as number)}</div>
                                    <div className="info">
                                        <span>同比上周</span>
                                        <span className="red">0%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="inner">
                                    <div className="title">出库单数</div>
                                    <div className="main">{stockStatisticsDetails.outallNum}</div>
                                    <div className="info">
                                        <span>同比上周</span>
                                        <span className="red">0%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="inner">
                                    <div className="title">出库产品数量</div>
                                    <div className="main">{stockStatisticsDetails.outallStockIoNum}</div>
                                    <div className="info">
                                        <span>同比上周</span>
                                        <span className="red">0%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="inner">
                                    <div className="title">出库金额</div>
                                    <div className="main">{moneyFormat(stockStatisticsDetails.outallStockIoAmount as number)}</div>
                                    <div className="info">
                                        <span>同比上周</span>
                                        <span className="red">0%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={chartRef} className="chart"></div>
                </div>
            </Spin>
        </Box>
    )
}

const Box = styled.div`
    height: 536px;

    .content {
        .top {
            height: 114px;
            border-bottom: 1px solid #f4f4f4;
            display: flex;
            margin-bottom: 16px;
            .list {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 30px;
                .item {
                    position: relative;
                    flex: 1;
                    &:after {
                        content: '';
                        width: 1px;
                        height: 30px;
                        background-color: #e8e8e8;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                    &:last-child:after {
                        display: none;
                    }
                    .inner {
                        text-align: center;
                        .title {
                            height: 17px;
                            font-weight: 400;
                            font-size: 12px;
                            color: #9698a3;
                            line-height: 17px;
                        }
                        .main {
                            height: 18px;
                            font-weight: 600;
                            font-size: 16px;
                            color: #182c4f;
                            line-height: 18px;
                            margin-top: 6px;
                        }
                        .info {
                            height: 14px;
                            font-size: 10px;
                            color: #9698a3;
                            line-height: 14px;
                            margin-top: 6px;
                            .red {
                                color: #ff4d4d;
                                position: relative;
                                &::before {
                                    content: '-';
                                    margin-left: 3px;
                                }
                            }
                            .green {
                                color: #34bf4a;
                                position: relative;
                                &::before {
                                    content: '+';
                                    margin-left: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .chart {
            width: 100%;
            height: 340px;
        }
    }
`
