import { Table } from 'antd'
import { useModel } from '@/views/receipt/outWarehouse/model'

export const YCOoutWarehouseDetailTable = ({ code }) => {
    const { editFormVal } = useModel()

    const columns = [
        {
            title: '序号',
            width: 60,
            dataIndex: 'code',
            render: (text, r, i) => i + 1,
        },
        {
            title: '商品编码',
            width: 180,
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            width: 100,
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'num',
        },
        {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            render: (text: string) => `￥${+text}`,
        },
        {
            title: '金额',
            width: 100,
            dataIndex: 'amount',
            render: (text: string) => `￥${+text}`,
        },
        {
            title: '仓库',
            width: 100,
            dataIndex: 'warehouseName',
        },
        {
            title: '货位',
            width: 100,
            dataIndex: 'shelfCode',
        },
    ]

    return (
        <>
            <Table
                rowClassName="editable-row"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={editFormVal?.details}
                scroll={{ x: 'max-content' }}
                bordered
            />
        </>
    )
}
