import React, { FC } from "react";
import { IDescription } from "./Types";
import copy from 'copy-to-clipboard';
import { message } from "antd";

export const Description: FC<IDescription> = (props) => {
    const copyUrl = (value: any) => {
        if (typeof value !== "string") return;
        copy(value);
        message.success('复制成功');
    };
    return (
        <>
            {
                props.status ?
                    <span className="list-header-content-statu-item" hidden={props.hidden}>
                        {props.label}：
                        <span className={'list-header-content-statu cur-copy statu-' + props.color} onClick={() => copyUrl(props.value)}>{props.value}</span>
                    </span> :
                    <span className="list-header-content-item" hidden={props.hidden}>
                        {props.label}：
                        <span className={'list-header-content-money money-' + props.color}>{props.value}</span>
                    </span>
            }
        </>
    )
}

