import { useEffect, useState } from 'react'
import { Table } from 'antd'
import { useModel } from '../model'
import YhTootip from '@/components/YhTootip'
import { momentFormat } from '@/utils/utils'
import { YhTableSort } from '@/components/YhTableSort'
import { commonService } from '@/service/common'
import { SettingOutlined } from '@ant-design/icons'

const sortKey = 'salesInventoryScale'

export function DataTable() {
    const { dataSource, pgCfg, loadData, columnsSetVisible, setColumnsSetVisible, orders, loading } = useModel()

    useEffect(() => {
        loadData()
        getColumns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRegularColumns = [
        {
            title: (
                <>
                    <span>
                        <SettingOutlined
                            onClick={() => {
                                setColumnsSetVisible(true)
                            }}
                        />
                    </span>
                    <span>序号</span>
                </>
            ),
            width: 80,
            fixed: 'left',
            align: 'center',
            render: (t, r, i) => i + 1,
        },
    ]
    const defaultColumns = [
        {
            title: '分支编码',
            width: 100,
            dataIndex: 'branch',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '门店名称',
            width: 140,
            dataIndex: 'shopName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '商品编码',
            width: 140,
            dataIndex: 'materialCode',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '商品名称',
            width: 200,
            dataIndex: 'materialName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '200px' }} />,
        },
        {
            title: '仓库',
            width: 100,
            dataIndex: 'warehouseName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '120px' }} />,
        },
        {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryQuantity',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '占用库存',
            width: 100,
            dataIndex: 'occupyNum',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '可用库存',
            width: 100,
            dataIndex: 'availableNum',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '最新进价',
            width: 100,
            dataIndex: 'lastPurchasePrice',
            isSet: true,
            render: (text) => <YhTootip text={'￥' + (text || 0)} style={{ width: '100px' }} />,
        },
        {
            title: '库存成本',
            width: 100,
            dataIndex: 'inventoryAmount',
            isSet: true,
            render: (text) => <YhTootip text={'￥' + (text || 0)} style={{ width: '100px' }} />,
        },
        {
            title: '最初入库日期',
            width: 160,
            dataIndex: 'lastStockIoTime',
            isSet: true,
            render: (text) => momentFormat(text),
        },
        {
            title: '是否存在积压',
            width: 120,
            dataIndex: 'overstockFlag',
            isSet: true,
            render: (text) => <YhTootip text={text ? '是' : '否'} style={{ width: '120px' }} />,
        },
        {
            title: '库龄',
            width: 100,
            dataIndex: 'overstock',
            isSet: true,
            render: (text) => <YhTootip text={text + '天'} style={{ width: '100px' }} />,
        },
        {
            title: '最近一个月是否销售',
            width: 160,
            dataIndex: 'saleRecent',
            isSet: true,
            render: (text) => <YhTootip text={text ? '是' : '否'} style={{ width: '160px' }} />,
        },
        {
            title: '最近一年是否销售',
            width: 160,
            dataIndex: 'saleRecentYear',
            isSet: true,
            render: (text) => <YhTootip text={text ? '是' : '否'} style={{ width: '160px' }} />,
        },
        {
            title: '年周转率',
            width: 100,
            dataIndex: 'turnoverRate',
            isSet: true,
            render: (text) => <YhTootip text={text ? text + '%' : '-'} style={{ width: '100px' }} />,
        },
        {
            title: '积压率',
            width: 100,
            dataIndex: 'overstockRate',
            isSet: true,
            render: (text) => <YhTootip text={text ? text + '%' : '-'} style={{ width: '100px' }} />,
        },
        {
            title: '销存率',
            width: 100,
            dataIndex: 'saleOverstockRate',
            isSet: true,
            render: (text) => <YhTootip text={text ? text + '%' : '-'} style={{ width: '100px' }} />,
        },
    ]

    const [columns, setColumns] = useState<any[]>([])

    const getColumns = () => {
        commonService.userCustomizeConfigOne(sortKey).then((res) => {
            if (!res.retData) {
                setColumns([...getRegularColumns, ...defaultColumns])
            } else {
                let newColumns: any[] = [...getRegularColumns]
                let data = JSON.parse(res.retData.val)
                data.forEach((element) => {
                    if (element.isShow) {
                        newColumns.push({ ...defaultColumns.find((i) => i.dataIndex === element.key), sorter: element.isSort })
                    }
                })
                setColumns(newColumns)
            }
        })
    }

    const onSuccess = () => {
        setColumnsSetVisible(false)
        getColumns()
    }

    const onChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            if (sorter.order) {
                orders.current = [{ column: sorter.field, asc: sorter.order === 'ascend' }]
            } else {
                orders.current = []
            }
            loadData()
        }
    }

    return (
        <>
            <Table
                rowKey={(record) => record.id || record.index}
                onChange={onChange}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                pagination={{
                    ...pgCfg,
                    position: ['bottomRight'],
                    showTotal: () => `共${pgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    },
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 'max-content', y: 'calc(100vh - 380px)' }}
            />
            <YhTableSort
                sortKey={sortKey}
                visible={columnsSetVisible}
                setVisible={setColumnsSetVisible}
                defaultColumns={defaultColumns}
                onSuccess={onSuccess}
            />
        </>
    )
}
