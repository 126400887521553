import { FC } from 'react'
import { ISearchField, ISearchProp, SearchTypeEnum } from '../Types'
import { SearchInput } from './SearchInput'
import { SearchInputNumber } from './SearchInputNum'
import { SearchDatepicker } from './SearchDatepicker'
import { SearchRangePicker } from './SearchRangePicker'
import { SearchFmsRangePicker } from './SearchFmsRangePicker'
import { SearchSelect } from './SearchSelect'
import { SearchCheckBox } from './SearchCheckBox'
import { SearchTreeSelect } from './SearchTreeSelect'
import { Col } from 'antd'

type PropType = ISearchProp & ISearchField
export const SearchItem: FC<PropType> = (props) => {
    // return <Col span={props.span ?? 8} xxl={props.xxl ?? 4} key={props.fieldName} className="ant-col-h-auto">
    return (
        <Col span={props.span ?? 6} key={props.fieldName} className="ant-col-h-auto">
            {(() => {
                switch (props.type) {
                    case SearchTypeEnum.Input:
                        return <SearchInput {...props} />
                    case SearchTypeEnum.InputNumber:
                        return <SearchInputNumber {...props} />
                    case SearchTypeEnum.Datepicker:
                        return <SearchDatepicker {...props} />
                    case SearchTypeEnum.RangePicker:
                        return <SearchRangePicker {...props} />
                    case SearchTypeEnum.FmsRangePicker:
                        return <SearchFmsRangePicker {...props} />
                    case SearchTypeEnum.Select:
                        return <SearchSelect {...props} />
                    case SearchTypeEnum.CheckBox:
                        return <SearchCheckBox {...props} />
                    case SearchTypeEnum.TreeSelect:
                        return <SearchTreeSelect {...props} />
                    default:
                        return <></>
                }
            })()}
        </Col>
    )
}
