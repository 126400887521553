import { useModel } from '../model'
import { ISearchValue, SearchTypeEnum } from '@/components/YhPageHeader/Types'
import { YhPageHeader } from '@/components/YhPageHeader'
import { isOverStockOptions } from '@/types/TcoDs'

export function PageHeader() {
    const { searchParam, updateSearchParam, resetSearchParam, loadData } = useModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '商品编码',
            fieldName: 'materialCode',
            span: 4,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '商品名称',
            fieldName: 'materialName',
            span: 4,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '替换编号',
            fieldName: 'replaceCode',
            span: 4,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '货位',
            fieldName: 'shelfCode',
            span: 4,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '是否积压',
            fieldName: 'overstockFlag',
            span: 4,
            options: isOverStockOptions,
        },
    ]

    return (
        <YhPageHeader
            title=""
            size="small"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    )
}
