import { Layout, Avatar, Popover, Select, message } from 'antd'
import FontIcon from '../components/YhIcon'
import { useHistory } from 'react-router-dom'
import useGlobalModel from '../model/globalModel'
import useNavModel from '../model/navModel'
import { mdmService } from '@/views/public/mdmService'
import { ISelectOption } from '@/components/YhFormBox/Types'
import { UserOutlined } from '@ant-design/icons'
import { loginServiceAuth2 } from '@/views/login/LoginServiceAuth2'
import { closeWebPage } from '@/utils/utils'
const { Header } = Layout

function ScmHeader() {
    const history = useHistory()
    const { user, setUser, shopDs, setBeShopDs } = useGlobalModel()
    const { initNav } = useNavModel(({ selectedNavId }) => [selectedNavId])

    //退出登录
    const onLogout = () => {
        if (user.loginType === 'account') {
            setUser({ ...user, shopId: '', shopName: '', shopCode: '', token: '' })
            initNav()
            history.push('/login')
            loginServiceAuth2.Logout()
            // loginServiceAuth2.recordAppLogoutInfo(CLIENT_APP_CODE, user.userCode ?? '')
        } else {
            closeWebPage()
        }
    }

    //门店选择
    const onShopChang = async (value: string, options: ISelectOption) => {
        if (options && options.value) {
            setUser({ ...user, shopId: value, shopName: options.label, shopCode: options.code as string, branch: options.branch }) //用户信息门店信息改变

            message.success('切换门店成功')

            //切换门店， 设置对应门店下面的实体 缓存
            let { retData } = await mdmService.one({
                sourceApp: 'scm',
                branch: 'main',
                code: options.code,
            })
            if (!retData || !retData.details) {
                setBeShopDs([])
                history.go(0)
                return false
            }
            let { details } = retData
            if (details && details.length !== 0) {
                setBeShopDs(
                    details.map((item) => ({
                        label: item.bentityName ?? '',
                        value: item.bentityCode ?? '',
                        branch: item.branch ?? '',
                    }))
                )
            } else {
                setBeShopDs([])
            }

            history.go(0)
        }
    }

    const content = (
        <div onClick={() => onLogout()} style={{ cursor: 'pointer', fontSize: '14px', color: 'rgba(0,0,0,0.65)' }}>
            <FontIcon type="icon-tuichu" style={{ marginRight: '3px' }} />
            <span>安全退出</span>
        </div>
    )

    return (
        <Header className="layout-section-header">
            <div className="header-right" style={{ paddingRight: '15px' }}>
                <Select
                    showSearch
                    defaultValue={user.shopId}
                    style={{ width: '250px' }}
                    className="header-right-item"
                    onChange={(value, options) => onShopChang(value, options as ISelectOption)}
                    options={shopDs}
                    optionFilterProp="label"
                />
                {/* 登录用户信息 */}
                <Popover content={content}>
                    <span onClick={(e) => e.preventDefault()} className="header-right-item user-info white-space" style={{ marginLeft: '20px' }}>
                        <Avatar size="small" icon={<UserOutlined />} className="user-icon" />
                        <span className="account">{user.userName ?? '未获取到'}</span>
                    </span>
                </Popover>
            </div>
        </Header>
    )
}

export default ScmHeader
