import React, { useEffect, useState } from 'react'
import { Button, Tree, message, Input } from 'antd'
import { YhBox } from '@/components/YhBox'
import { FormBox } from './FormBox'
import { DataNode } from 'antd/lib/tree'
import { useDictModel } from '../dictModel'
import { IDictDetail, IDictSearch } from '../dictService'
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'

export const DataBox = () => {
    //查询条件
    const [searchParam, setSearchParam] = useState<IDictSearch>()
    const { treeSource, loadData, setMode, setSelectedNodes, selectedNodes, setFormVal, removeOneDict, setTreeSource } = useDictModel()

    const [searchData] = useState([...treeSource])

    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //字典选中事件
    const onSelect = (
        selectedKeys: React.Key[],
        info: {
            event: 'select'
            selected: boolean
            // node: EventDataNode
            selectedNodes: DataNode[]
            nativeEvent: MouseEvent
        }
    ) => {
        let selectNode = info.selectedNodes as IDictDetail[]
        setMode(info.selected ? 'edit' : '')
        setSelectedNodes(selectNode)
        setFormVal({ nameEn: '', ...selectNode[0] })
    }

    const handleSearch = (value: string) => {
        const searchText = value.toLowerCase()
        let data = JSON.parse(JSON.stringify(searchData))
        const filteredResults = data.filter((item) => {
            // 检查一级的 name 字段是否包含搜索文本
            if (item.name.toLowerCase().includes(searchText) || item.code.toLowerCase().includes(searchText)) {
                if (item.name.toLowerCase() === value || item.code.toLowerCase() === value) {
                    setMode('edit')
                    setSelectedNodes([item])
                    setFormVal({ nameEn: '', ...item })
                } else {
                    setMode('')
                    setSelectedNodes([])
                }
                return true
            }

            // 检查 children 中的 name 字段是否包含搜索文本
            if (item.children && item.children.length > 0) {
                const filteredChildren = item.children.filter((child) => {
                    let b = child.name.toLowerCase().includes(searchText) || child.code.toLowerCase().includes(searchText)
                    if (child.name.toLowerCase() === value || child.code.toLowerCase() === value) {
                        setMode('edit')
                        setSelectedNodes([child])
                        setFormVal({ nameEn: '', ...child })
                    }
                    return b
                })

                if (filteredChildren.length > 0) {
                    // 更新 item 的 children 为筛选后的结果
                    item.children = filteredChildren
                    return true
                }
            }

            return false
        })

        setTreeSource(filteredResults)
    }

    return (
        <YhBox style={{ marginTop: '16px' }}>
            <div className="dict-container flex">
                <div className="dict-list-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="dict-list-title">数据字典</span>
                        <div>
                            {treeSource.length === 0 && (
                                <Button className="mr-5" type="link" shape="round" onClick={() => setMode('root')}>
                                    增加根级
                                </Button>
                            )}
                            {treeSource.length > 0 && (
                                <>
                                    <Button
                                        className="mr-5"
                                        type="primary"
                                        shape="round"
                                        onClick={() => {
                                            if (!selectedNodes.length) {
                                                message.warning('请选择要增加的父级')
                                                return
                                            }
                                            message.success('增加下级模式开启')
                                            setMode('nextAdd')
                                        }}
                                    >
                                        {' '}
                                        增加下级{' '}
                                    </Button>
                                    <Button
                                        className="mr-5"
                                        type="primary"
                                        shape="round"
                                        onClick={() => {
                                            if (!selectedNodes.length) {
                                                message.warning('请选择要增加的同级')
                                                return
                                            }
                                            message.success('增加同级模式开启')
                                            setMode('add')
                                        }}
                                    >
                                        增加同级
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                    <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        <Input
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            placeholder="请输入回车检索"
                            size="small"
                            style={{ width: '100%' }}
                            value={searchParam?.value}
                            onChange={(e) => {
                                setSearchParam({ value: e.target.value })
                                handleSearch(e.target.value)
                            }}
                        />
                    </div>
                    {treeSource.length > 0 && (
                        <div className="dict-tree-box">
                            <Tree
                                defaultExpandedKeys={[selectedNodes[0]?.key]}
                                selectedKeys={[selectedNodes[0]?.key]}
                                //titleRender={(node: any) => `${node.name}`}
                                defaultExpandAll={treeSource.length !== searchData.length}
                                titleRender={(node: any) => (
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{node.name}</span>
                                        <span
                                            style={{ marginLeft: 'auto', fontSize: '14px' }}
                                            onClick={() => {
                                                removeOneDict(node)
                                            }}
                                        >
                                            <DeleteOutlined />
                                        </span>
                                    </div>
                                )}
                                blockNode
                                onSelect={onSelect}
                                treeData={treeSource}
                            />
                        </div>
                    )}
                </div>
                <div style={{ borderRight: '1px solid #e0e0e0', margin: '10px 0' }}></div>
                <div className="dict-from-box flex">
                    {selectedNodes.length > 0 ? <FormBox /> : <div className="dict-init-box flex flex-1 items-center justify-center">数据字典</div>}
                </div>
            </div>
        </YhBox>
    )
}
