import './home.less'
import styled from '@emotion/styled'
import { Header } from './Header'
import { MessageBox } from './MessageBox'
import { QuickEntrance } from './QuickEntrance'
import LineChart from './echarts/LineChart'
import BarChart from './echarts/BarChart'
import BarStackChart from './echarts/BarStackChart'

export function HomeAll() {
    return (
        <div
            className="home"
            style={{
                width: '100%',
                height: '100%',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <Header type="all" />
            <Box className="content-top">
                <div className="left">
                    <MessageBox type="all" />
                    <QuickEntrance />
                </div>
                <div className="right">
                    <LineChart type="all" />
                </div>
            </Box>
            <div className="content" style={{ flex: 1, width: '100%' }}>
                <BarChart />
                <BarStackChart />
            </div>
        </div>
    )
}
const Box = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    .left {
        width: 310px;
    }
    .right {
        flex: 1;
        margin-left: 16px;
        overflow: hidden;
    }
`
