import { DataTable } from './DataTable'
import { YhBox } from '@/components/YhBox'
import { Button } from 'antd'
import { useModel } from '../model'

export const DataBox = () => {
    const { exportData } = useModel()

    const action = (
        <>
            <Button className="mr-5" type="link" shape="round" onClick={() => exportData()}>
                导出
            </Button>
        </>
    )

    return (
        <YhBox action={action} style={{ height: 'calc(100vh - 185px)' }}>
            <DataTable />
        </YhBox>
    )
}
