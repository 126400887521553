import { useEffect, useState } from 'react'
import { Table, Popover } from 'antd'
import { useModel } from '../model'
import YhTootip from '@/components/YhTootip'
import { YhTableSort } from '@/components/YhTableSort'
import { commonService } from '@/service/common'
import { SettingOutlined, InfoCircleOutlined } from '@ant-design/icons'

const sortKey = 'inventoryWarning'

export function DataTable() {
    const { dataSource, pgCfg, loadData, columnsSetVisible, setColumnsSetVisible, orders, loading } = useModel()

    useEffect(() => {
        loadData()
        getColumns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const content = (
        <>
            <div style={{ fontSize: '12px' }}>
                <p>
                    <b>一、库存上限为0，不进行上限预警；库存下限为0，不进行下限预警；</b>
                </p>
                <p>
                    <b>二、库存上限、库存下限不为0时：</b>
                </p>
                <p style={{ textIndent: '2em' }}>1. 现有库存数量 &gt; 库存上限：</p>
                <p style={{ textIndent: '4em' }}>① 计算上限紧急度进行预警；</p>
                <p style={{ textIndent: '4em' }}>② 计算公式：（现有库存 - 库存上限）/ 库存上限 * 100 %；</p>
                <p style={{ textIndent: '2em' }}>2. 现有库存数量 &lt;= 库存下限：</p>
                <p style={{ textIndent: '4em' }}>① 计算下限紧急度进行预警；</p>
                <p style={{ textIndent: '4em' }}>② 计算公式：（库存下限 - 现有库存） / 库存下限 * 100 %；</p>
            </div>
        </>
    )
    const action = (
        <>
            <Popover content={content} title="预警规则" trigger="hover">
                <InfoCircleOutlined style={{ marginLeft: '15px', color: '#1677ff', cursor: 'pointer', verticalAlign: 'middle', fontSize: '20px' }} />
            </Popover>
        </>
    )
    const getRegularColumns = [
        {
            title: (
                <>
                    <span>
                        <SettingOutlined
                            onClick={() => {
                                setColumnsSetVisible(true)
                            }}
                        />
                    </span>
                    <span>序号</span>
                    {action}
                </>
            ),
            width: 100,
            fixed: 'left',
            align: 'center',
            render: (t, r, i) => i + 1,
        },
    ]
    const defaultColumns = [
        {
            title: '分支编码',
            width: 100,
            dataIndex: 'branch',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '门店名称',
            width: 140,
            dataIndex: 'shopName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '商品编码',
            width: 140,
            dataIndex: 'materialCode',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '商品名称',
            width: 200,
            dataIndex: 'materialName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '200px' }} />,
        },
        {
            title: '商品分类',
            width: 160,
            dataIndex: 'typeName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '160px' }} />,
        },
        {
            title: '现有库存',
            width: 100,
            dataIndex: 'inventoryQuantity',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '库存上限',
            width: 100,
            dataIndex: 'limitUpper',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '库存下限',
            width: 100,
            dataIndex: 'limitLower',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '下限紧急度',
            width: 100,
            dataIndex: 'limitLowerUrgency',
            isSet: true,
            render: (text) => <YhTootip text={(text || 0) + '%'} style={{ width: '100px' }} />,
        },
        {
            title: '上限紧急度',
            width: 100,
            dataIndex: 'limitUpperUrgency',
            isSet: true,
            render: (text) => <YhTootip text={(text || 0) + '%'} style={{ width: '100px' }} />,
        },
    ]

    const [columns, setColumns] = useState<any[]>([])

    const getColumns = () => {
        commonService.userCustomizeConfigOne(sortKey).then((res) => {
            if (!res.retData) {
                setColumns([...getRegularColumns, ...defaultColumns])
            } else {
                let newColumns: any[] = [...getRegularColumns]
                let data = JSON.parse(res.retData.val)
                data.forEach((element) => {
                    if (element.isShow) {
                        newColumns.push({ ...defaultColumns.find((i) => i.dataIndex === element.key), sorter: element.isSort })
                    }
                })
                setColumns(newColumns)
            }
        })
    }

    const onSuccess = () => {
        setColumnsSetVisible(false)
        getColumns()
    }

    const onChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            if (sorter.order) {
                orders.current = [{ column: sorter.field, asc: sorter.order === 'ascend' }]
            } else {
                orders.current = []
            }
            loadData()
        }
    }

    return (
        <>
            <Table
                rowKey={(record) => record.id || record.index}
                onChange={onChange}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                pagination={{
                    ...pgCfg,
                    position: ['bottomRight'],
                    showTotal: () => `共${pgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    },
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 'max-content', y: 'calc(100vh - 380px)' }}
            />
            <YhTableSort
                sortKey={sortKey}
                visible={columnsSetVisible}
                setVisible={setColumnsSetVisible}
                defaultColumns={defaultColumns}
                onSuccess={onSuccess}
            />
        </>
    )
}
