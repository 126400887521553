import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { getBaseCiRequestUrl } from '@/utils/constants'

//详情
export interface IDetail {
    /**
     * 分支编码
     */
    branch: string
    buyingPrice: number
    distributorPrice: number
    inventoryAmount: number
    /**
     * 库存成本
     */
    inventoryAmountByLatestPurPrice: number
    /**
     * 库存数
     */
    inventoryQuantity: number
    /**
     * 最新进价
     */
    latestPurPrice: number
    /**
     * 商品编码
     */
    materialCode: string
    materialName: string
    model: string
    /**
     * 占用库存
     */
    occupyNum: number
    repairPrice: number
    /**
     * 替换编号
     */
    replaceCode: string
    sellingPrice: number
    /**
     * 货位
     */
    shelfCode: string
    shopId: string
    /**
     * 门店名称
     */
    shopName: string
    stockIoAmount: number
    stockIoNum: number
    tfNum: number
    /**
     * 商品类型
     */
    typeName: string
    /**
     * 仓库编码
     */
    warehouseCode: string
    /**
     * 仓库
     */
    warehouseName: string
    /**
     * 库存数-占用库存
     */
    可用库存: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    materialCode?: string
    materialName?: string
    isOccupy?: string
    warehouseCode?: string
    shelfCode?: string
    tfFlag?: string
    searchItem?: {}
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
    branch: string
}

export interface Istatistics {
    /**
     * 库存金额（明细汇总）
     */
    totalInventoryAmount?: number
    /**
     * 库存金额（最新采购价）
     */
    totalInventoryAmountByLatestPurPrice?: number
    /**
     * 库存数量
     */
    totalInventoryNum?: number
    /**
     * 库存数量
     */
    stockNum?: number
    /**
     * 库存成本（明细汇总）
     */
    stockAmount?: number
    /**
     * 库存成本（最新采购价）
     */
    stockAmountByLatestPurPrice?: number
}

class Service {
    async page(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/inventory/summary/report/page'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/stock/findPage'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/stock/findPage'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-storage/stock/findPage'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }
    async total(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/inventory/summary/report/statistics'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/stock/details/total/amount'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/stock/details/total/amount'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-storage/stock/details/total/amount'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.post<Istatistics>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }
    //导出
    async export(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/yh-export/scm/stock/export'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/yh-export/bcm/stock/export'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/yh-export/vrm/stock/export'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-analyze/stock/statistics/export'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }
}

export const service = new Service()
