import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { getBaseCiRequestUrl } from '@/utils/constants'

//详情
export interface IDetail {
    /**
     * 分支
     */
    branch?: string
    /**
     * 商品品牌编码
     */
    brandCode?: string
    /**
     * 商品品牌名称
     */
    brandName?: string
    /**
     * ID
     */
    id: string
    /**
     * 最高下限
     */
    limitLower?: number
    /**
     * 最低上限
     */
    limitUpper?: number
    /**
     * 产地
     */
    madeFrom?: string
    /**
     * 商品编码
     */
    materialCode?: string
    /**
     * 商品名称
     */
    materialName?: string
    /**
     * 商品图片
     */
    materialImg?: string
    /**
     * 最小起订量
     */
    minOrderNum?: number
    /**
     * 规格型号
     */
    model?: string
    /**
     * 备注
     */
    note?: string
    /**
     * 图号
     */
    replaceCode?: string
    /**
     * 门店ID
     */
    shopId?: string
    /**
     * 门店名称
     */
    shopName?: string
    /**
     * 商品类型编码
     */
    typeCode?: string
    /**
     * 商品类型名称
     */
    typeName?: string
    /**
     * 单位编码
     */
    unitCode?: string
    /**
     * 单位名称
     */
    unitName?: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    /**
     * 商品名称
     */
    materialName?: string
    /**
     * 商品编码
     */
    materialCode?: string
    /**
     * 商品分类
     */
    typeName?: string
    /**
     * 品牌
     */
    brandName?: string
    searchItem?: {}
    [key: string]: any
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
}

/**
 * TaskBookUpdateVo
 */
export interface IlistUpdateVo {
    /**
     * 商品编码
     */
    code: string
    /**
     * 商品ID
     */
    id: string
    /**
     * 库存下限
     */
    limitLower?: number
    /**
     * 库存上限
     */
    limitUpper?: number
    /**
     * 门店ID
     */
    shopId: string
    /**
     * 门店名称
     */
    shopName: string
    /**
     * 来源(scm)，固定传scm，bcm，vrm，ci
     */
    sourceApp: string
    [property: string]: any
}

class Service {
    // 商品列表
    async page(searchVo: ISearch, config: object = {}) {
        const apiUrl = '/ims/material/page'
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, config)
    }
    // 修改库存上下限
    async listUpdate(data: IlistUpdateVo) {
        let apiUrl = ''
        if (data.sourceApp === 'scm') {
            apiUrl = '/scm2/base/material/update'
        }
        if (data.sourceApp === 'bcm') {
            apiUrl = '/bcm/base/material/update'
        }
        if (data.sourceApp === 'vrm') {
            apiUrl = '/vrm2/base/material/update'
        }
        if (data.sourceApp === 'ci') {
            apiUrl = '/ci-storage/base/material/update'
        }
        const baseURL = data.sourceApp === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.post<any>(apiUrl, data, { timeout: 300000, ...{ baseURL } })
    }

    //详情页
    async getDetailsList(id: string) {
        const apiUrl = '/ims/material/limit'
        return await httpRequest.post<any>(apiUrl, { materialCode: id })
    }
    // 导出
    async export(searchVo: ISearch) {
        let apiUrl = '/ims/material/export'
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 })
    }
}

export const service = new Service()
