import React, { FC } from 'react'
import { IFormItem } from '../Types'
import { Form, Input } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

export const __FormInput: FC<IFormItem> = ({ placeholder, fieldLabel, fieldName, addonAfter, ...props }) => {
    return (
        <Form.Item
            // label={fieldLabel}
            // label={<Tooltip title={title}>{fieldLabel} <CopyOutlined  style={{fontSize:'13px',color:"#4062d8"}}/></Tooltip>}
            label={
                <span>
                    {fieldLabel}{' '}
                    <CopyOutlined
                        style={{ fontSize: '13px', color: '#4062d8' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            props?.copyText?.(fieldName as string)
                        }}
                    />
                </span>
            }
            name={fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}
        >
            <Input
                disabled={props.disable}
                allowClear={props.allowClear}
                placeholder={placeholder ? placeholder : typeof fieldLabel === 'string' ? fieldLabel : ''}
                prefix={props.prefix}
                onBlur={() => props.onInputBlur?.(fieldName)}
                onFocus={() => props.onInputFocus?.()}
                suffix={props.suffix}
                addonAfter={addonAfter}
            />
        </Form.Item>
    )
}
