import { ITreeSelectData } from "@/views/base/dict/dictModel";
import { ISelectOptions } from "@/types/AppTypes";

export enum SearchTypeEnum {
    'Input' = 'input',
    'Select' = 'select',
    'Datepicker' = 'datepicker',
    'RangePicker' = 'rangePicker',
    'FmsRangePicker' = 'fmsRangePicker',
    'CheckBox' = 'checkBox',
    "TreeSelect" = 'treeSelect',
    "InputNumber" = 'inputNumber'
}


export interface ISearchValue {
    [propName: string]: string | number | [string, string] | object | string[]
}
export interface IFmsSearchValue {
    [propName: string]: string | undefined | [string, string] | { startTime: string, endTime: string }
}

export interface ISearchField {
    fieldName: string;
    type: SearchTypeEnum;
    placeholder?: string | string[];
    showTime?: Object | boolean;
    format?: string;
    xxl?: number;
    span?: number;
    disabled?: boolean;
    options?: ISelectOptions[],
    treeData?: ITreeSelectData[],
    rangePickerFieldName?: string[] | ["startTime", "endTime"],  //供应链专用字段
    // mode?: "multiple" | "tags"
    [key: string]: any
}

type Isize = 'large' | 'middle' | 'small';

export interface ISearchProp {
    title?: string | JSX.Element;
    triggerSearch?: boolean,
    searchFields?: ISearchField[],
    searchMoreFields?: ISearchField[],
    searchValues?: ISearchValue,
    onChange?: (arg: ISearchValue) => void
    onFmsChange?: (arg: IFmsSearchValue) => void
    handleReset?: () => void;
    handleSearch?: (arg?: ISearchValue) => void;
    size?: Isize,
    justify?: "start" | "end",
    style?: React.CSSProperties;
}

export interface IAdvanceSearchProp extends  ISearchProp {
    showAdvanced: boolean
    setShowAdvanced: React.Dispatch<React.SetStateAction<boolean>>
}