import { FC } from 'react'
import { YhBox } from '@/components/YhBox'
import { Tabs } from 'antd'
import { useModel } from '../../model'
import { ModeType } from '@/model/navModel'
import { YCOinventoryCountingDetailTable } from '@/components/YCOinventoryCountingDetailTable'

export const TabsBox: FC<ModeType> = ({ mode }) => {
    const { editFormVal } = useModel()
    const items = [{ label: '盘点明细', key: 'all', children: <YCOinventoryCountingDetailTable list={editFormVal.details} /> }]
    return (
        <YhBox style={{ margin: '8px auto', padding: '0 20px', position: 'relative', minHeight: '500px' }}>
            <Tabs defaultActiveKey={'all'} items={items} />
            <div style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'end', position: 'absolute', right: '20px', top: '0' }}></div>
        </YhBox>
    )
}
