import { message, Tooltip } from 'antd'
import { FC } from 'react'
import copy from 'copy-to-clipboard'
import { CopyOutlined } from '@ant-design/icons'

export interface IYhTootip {
    placement?: object
    isCopy?: boolean
    text: string
    onClick?: (e: any) => void
    onMouseOver?: (e: any) => void
    onMouseLeave?: (e: any) => void
    style?: object
}

const YhTootip: FC<IYhTootip> = ({ placement, isCopy = true, text, onClick, onMouseOver, onMouseLeave, style }) => {
    const oSpan = (
        <span
            style={{ cursor: 'copy' }}
            onClick={(e) => {
                e.stopPropagation()
                if (isCopy) {
                    copy(text || ' ')
                    message.success('复制成功')
                }
            }}
        >
            <span>{text || '-'}</span>&nbsp;&nbsp;{isCopy && <CopyOutlined />}
        </span>
    )

    return (
        <Tooltip placement="topLeft" title={oSpan}>
            <p
                className={`ellipsis ${onClick ? 'click-span' : ''}`}
                onClick={(e) => {
                    e.stopPropagation()
                    onClick?.(e)
                }}
                style={style}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
            >
                {text || (text as any) === 0 ? text : '-'}{' '}
            </p>
        </Tooltip>
    )
}
export default YhTootip
