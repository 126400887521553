import { AxiosResponse } from 'axios/index'
import { message } from 'antd'

//导出文件
export function convertRes2Blob(response: AxiosResponse, fileNames?: string) {
    // debugger;
    try {
        const fileName = fileNames || response.headers['content-disposition']?.match(/filename=(.*)/)?.[1] || '文件.pdf'

        // 将二进制流转为blob
        const blob = new Blob([response.data], { type: 'application/octet-stream' })
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', decodeURI(fileName))
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
        message.success('已导出')
    } catch (error) {
        message.warning('导出异常，请重新尝试')
    }
}
