import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useHomeModel } from './homeModel'
import { useNavModel } from '@/model/navModel'
import useGlobalModel from '@/model/globalModel'

export function MessageBox(props) {
    const { pending, getPending } = useHomeModel()
    const { addNav, findNav, switchNavState } = useNavModel(() => [])
    const { menuAuth } = useGlobalModel()

    const [isHas36, setIsHas36] = useState(false)

    const handleDetail = (tab) => {
        if (!isHas36) {
            return false
        }

        let _key = '3-6'
        let isFindNav = findNav(_key)
        let _params = { type: props.type, tab: '', id: '', mode: 'look' }
        if (tab === '待入库') {
            _params = { type: props.type, tab: 'in', id: '', mode: 'look' }
        }
        if (tab === '待出库') {
            _params = { type: props.type, tab: 'out', id: '', mode: 'look' }
        }
        if (tab === '待审核') {
            _params = { type: props.type, tab: 'audit', id: '', mode: 'look' }
        }
        if (tab === '调拨待调入') {
            _params = { type: props.type, tab: 'allotIn', id: '', mode: 'look' }
        }
        if (tab === '调拨待调出') {
            _params = { type: props.type, tab: 'allotOut', id: '', mode: 'look' }
        }

        if (!isFindNav) {
            addNav(_key, _params)
        } else {
            switchNavState(_key, _params)
        }
    }

    useEffect(() => {
        getPending(props.type)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (menuAuth?.indexOf('/receipt/unfinished') !== -1) {
            setIsHas36(true)
        }
    }, [menuAuth])

    return (
        <Box className="index-content messageBox">
            <div className="index-content-title-bar">
                <span className="index-content-title-bar-title">待处理事务</span>
            </div>

            <div className="list">
                <div
                    className="item"
                    onClick={() => {
                        handleDetail('待入库')
                    }}
                >
                    <div className="item-info">{pending?.stockInNum}</div>
                    <div className="item-name">待入库</div>
                </div>
                <div
                    className="item"
                    onClick={() => {
                        handleDetail('待出库')
                    }}
                >
                    <div className="item-info">{pending?.stockOutNum}</div>
                    <div className="item-name">待出库</div>
                </div>
                <div
                    className="item"
                    onClick={() => {
                        handleDetail('待审核')
                    }}
                >
                    <div className="item-info">{pending?.auditNum}</div>
                    <div className="item-name">待审核</div>
                </div>
            </div>
            <div className="list">
                <div
                    className="item"
                    onClick={() => {
                        handleDetail('调拨待调入')
                    }}
                >
                    <div className="item-info">{pending?.allotInNum}</div>
                    <div className="item-name">调拨待调入</div>
                </div>
                <div
                    className="item"
                    onClick={() => {
                        handleDetail('调拨待调出')
                    }}
                >
                    <div className="item-info">{pending?.allotOutNum}</div>
                    <div className="item-name">调拨待调出</div>
                </div>
            </div>
        </Box>
    )
}

const Box = styled.div`
    height: 200px;
    margin-bottom: 16px;
    .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .item {
            cursor: pointer;
            min-width: 55px;
            text-align: center;
            .item-info {
                height: 33px;
                font-weight: 600;
                font-size: 24px;
                color: #47c98a;
                line-height: 33px;
            }
            .item-name {
                height: 20px;
                font-weight: 400;
                font-size: 14px;
                color: #9698a3;
                line-height: 20px;
            }
            &:nth-of-type(1) .item-info {
                color: #47c98a;
            }
            &:nth-of-type(2) .item-info {
                color: #2d71f8;
            }
            &:nth-of-type(3) .item-info {
                color: #ff6b35;
            }
        }
    }
`
