import { DataTable } from './DataTable'
import { YhBox } from '@/components/YhBox'
import { useModel } from '../model'
import { Button } from 'antd'

export const DataBox = () => {
    const { setImportVisible } = useModel()

    const action = (
        <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => {
                    setImportVisible(true)
                }}
            >
                识别导入
            </Button>
        </>
    )

    return (
        <>
            <YhBox action={action} style={{ height: 'calc(100vh - 185px)' }}>
                <DataTable />
            </YhBox>
        </>
    )
}
