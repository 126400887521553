import { useEffect, useState, useRef } from 'react'
import * as echarts from 'echarts'
import styled from '@emotion/styled'
import { DatePicker, Select, Spin } from 'antd'
import type { Moment } from 'moment'
import { EChartOption } from 'echarts'
import { useHomeModel } from '../homeModel'

const { RangePicker } = DatePicker

type RangeValue = [Moment | null, Moment | null] | null

export default function BarChart() {
    const {
        allWarehouseStatisticsStart,
        allWarehouseStatisticsEnd,
        allWarehouseStatisticsTab,
        handleAllWarehouseStatisticsTab,
        allWarehouseStatisticsLoading,
        allWarehouseStatisticsDetails,
        handleAllWarehouseStatisticsDate,
        allWarehouseStatisticsBranch,
        setAllWarehouseStatisticsBranch,
        getAllWarehouseStatistics,
    } = useHomeModel()

    const chartRef = useRef<HTMLDivElement>(null)
    const [chart, setChart] = useState<echarts.ECharts>()
    const handleResize = () => chart?.resize()

    const [value, setValue] = useState<RangeValue>(null)
    const onChange = (val: RangeValue) => {
        handleAllWarehouseStatisticsTab('')
        setValue(val)
        handleAllWarehouseStatisticsDate(val)
    }

    const handleChange = (val: string) => {
        setAllWarehouseStatisticsBranch(val)
        getAllWarehouseStatistics(val, allWarehouseStatisticsStart, allWarehouseStatisticsEnd)
    }

    const option: EChartOption = {
        grid: {
            top: 10,
            right: 30,
            bottom: 30,
            left: 80,
        },
        color: ['#5B8FF9', '#5AD8A6', '#FF9D4D', '#E8684A'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
            formatter: (params: any) => {
                let _html = ''
                params?.forEach((item: any) => {
                    if (item.data.isHas) {
                        _html =
                            _html +
                            '<div><span style="width: 8px;height: 8px;border-radius: 50%;margin-right: 5px;display: inline-block;background: ' +
                            item.color +
                            '"></span><b style="margin-right: 10px;">' +
                            item.data.name +
                            '</b><span style="margin-right: 5px;">数量：' +
                            item.data.value +
                            '</span><span style="margin-right: 5px;">金额：' +
                            item.data.amount +
                            '</span></div>'
                    }
                })
                return _html
            },
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: [],
            },
        ],
        yAxis: [
            {
                type: 'value',
                minInterval: 1,
            },
        ],
        dataZoom: [
            {
                type: 'slider',
                start: 0,
                end: 50,
            },
        ],
        series: [],
    }

    const init = () => {
        if (chart) {
            window.removeEventListener('resize', handleResize)
        }

        option!.xAxis![0]!.data = []
        option!.series = []

        option!.xAxis![0]!.data = allWarehouseStatisticsDetails?.shops
        if (allWarehouseStatisticsDetails?.warehouses?.length !== 0) {
            allWarehouseStatisticsDetails?.warehouses?.forEach((warehouse) => {
                option!.series!.push({
                    name: warehouse.name,
                    type: 'bar',
                    barGap: '0',
                    barWidth: 12,
                    data: warehouse.info.map((ware) => {
                        return { value: ware.num, name: warehouse.name, amount: ware.amount, isHas: ware.isHas }
                    }),
                })
            })
        }

        let chartInstance = echarts.getInstanceByDom(chartRef.current as HTMLDivElement)
        if (!chartInstance) {
            chartInstance = echarts.init(chartRef.current as HTMLDivElement)
        }
        chartInstance.setOption(option)
        window.addEventListener('resize', () => chartInstance?.resize())
        setChart(chartInstance)
    }

    useEffect(() => {
        if (allWarehouseStatisticsTab) {
            setValue(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouseStatisticsTab])
    useEffect(() => {
        handleAllWarehouseStatisticsTab('all')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (allWarehouseStatisticsDetails.isInit) {
            return
        }

        init()

        return () => {
            window.removeEventListener('resize', handleResize)
            chart?.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouseStatisticsDetails, allWarehouseStatisticsBranch])

    return (
        <Box className="index-content">
            <div className="index-content-title-bar">
                <span className="index-content-title-bar-title">仓库数据统计</span>
                <div className="index-content-title-bar-more">
                    <div className="home-title-menu">
                        <Select
                            value={allWarehouseStatisticsBranch}
                            style={{ width: 180, height: 32 }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'scm',
                                    label: '配件进销存',
                                },
                                {
                                    value: 'bcm',
                                    label: '客车管理',
                                },
                                {
                                    value: 'vrm',
                                    label: '维保管理',
                                },
                                {
                                    value: 'ci',
                                    label: '云修系统',
                                },
                            ]}
                        />
                        <div className="tabs">
                            <div
                                className={allWarehouseStatisticsTab === 'day' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleAllWarehouseStatisticsTab('day')
                                }}
                            >
                                今日
                            </div>
                            <div
                                className={allWarehouseStatisticsTab === 'week' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleAllWarehouseStatisticsTab('week')
                                }}
                            >
                                本周
                            </div>
                            <div
                                className={allWarehouseStatisticsTab === 'month' ? 'tab active' : 'tab'}
                                onClick={() => {
                                    handleAllWarehouseStatisticsTab('month')
                                }}
                            >
                                本月
                            </div>
                        </div>
                        <div className="select">
                            <RangePicker value={value} onChange={onChange} />
                        </div>
                    </div>
                </div>
            </div>

            <Spin tip="Loading..." spinning={allWarehouseStatisticsLoading}>
                <div className="content">
                    <div ref={chartRef} className="chart"></div>
                </div>
            </Spin>
        </Box>
    )
}

const Box = styled.div`
    height: 300px;
    margin-bottom: 16px;

    .content {
        position: relative;
        .chart {
            width: 100%;
            height: 190px;
            margin-top: 40px;
        }
    }
`
