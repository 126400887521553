import { useEffect } from 'react'
import styled from '@emotion/styled'
import IconOption from '@/assets/images/icon-option.png'
import { Empty, Modal } from 'antd'

import { useHomeModel } from './homeModel'
import useNavModel from '@/model/navModel'
export function QuickEntrance() {
    const { getQuickEntrance, open, iconModalList, handleSelectIcon, showModal, handleOk, handleCancel, iconListState, iconList } = useHomeModel()
    const { addNav } = useNavModel(() => [])

    const handleDetails = (route: string) => {
        addNav(route)
    }

    useEffect(() => {
        getQuickEntrance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box className="index-content quickEntrance">
            <div className="index-content-title-bar">
                <span className="index-content-title-bar-title">快捷方式</span>
                <div className="index-content-title-bar-more">
                    <div className="icon-more" onClick={showModal}>
                        <img src={IconOption} alt="设置" />
                    </div>
                </div>
            </div>
            {iconListState === '2' && (
                <div className="list">
                    {iconList.map((item, index) => (
                        <div
                            className="item"
                            key={index}
                            onClick={() => {
                                handleDetails(item.url)
                            }}
                        >
                            <div>
                                <div className="icon">
                                    <svg className="icon" aria-hidden="true">
                                        <use xlinkHref={item.icon}></use>
                                    </svg>
                                </div>
                                <div className="name">{item.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {iconListState === '1' && <Empty description="暂无设置" style={{ marginTop: '40px', color: '#ddd' }} />}

            <Modal
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                        }}
                    >
                        设置快捷方式
                    </div>
                }
                width={558}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="quickEntrance-modal-list">
                    {iconModalList.map((item, index) => (
                        <div
                            className={item.select ? 'item select' : 'item'}
                            key={index}
                            onClick={() => {
                                handleSelectIcon(item)
                            }}
                        >
                            <div>
                                <div className="icon">
                                    <svg className="icon" aria-hidden="true">
                                        <use xlinkHref={item.icon}></use>
                                    </svg>
                                </div>
                                <div className="name">{item.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
        </Box>
    )
}

const Box = styled.div`
    height: 320px;

    .icon-more {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            display: block;
            width: 16px;
            height: 15px;
        }
    }

    .icon {
        width: 1em;
        height: 1em;
    }
    .list {
        margin-top: 20px;
        max-height: 240px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        .item {
            width: 33.333%;
            max-height: 60px;
            text-align: center;
            cursor: pointer;
            margin-bottom: 20px;
            .icon {
                width: 36px;
                height: 36px;
                margin: 0 auto;
                vertical-align: -0.15em;
                fill: currentColor;
                overflow: hidden;
                background-color: #fff;
                border-radius: 12px;
            }
            .name {
                height: 18px;
                margin-top: 6px;
                font-weight: 400;
                font-size: 12px;
                color: #333333;
                line-height: 18px;
            }
        }
    }
`
