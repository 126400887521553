import { Button } from 'antd'
import { YhForm } from '@/components/YhFormBoxVertical'
import { FormItemTypeEnum, IYhForm } from '@/components/YhFormBox/Types'
import { useDictModel } from '../dictModel'

export const FormBox = () => {
    const { mode, setSelectedNodes, onSubmit, formVal, onChangeFormVal, selectedNodes } = useDictModel()

    const formConfig: IYhForm = {
        labelCol: { span: 8 },
        formValues: formVal,
        onChange: onChangeFormVal,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '分支编码',
                fieldName: 'branch',
                span: 13,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '父级编码',
                fieldName: 'pcode',
                span: 13,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '字典编码',
                fieldName: 'code',
                span: 13,
                disable: mode === 'edit' ? false : false,
                rules: [{ required: true, message: '该项为必填项' }],
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '字典名称',
                fieldName: 'name',
                span: 13,
                rules: [{ required: true, message: '该项为必填项' }],
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '英文名称',
                fieldName: 'nameEn',
                span: 13,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '字典值',
                fieldName: 'value',
                span: 13,
            },
            {
                type: FormItemTypeEnum.TextArea,
                fieldLabel: '备注',
                fieldName: 'note',
                span: 13,
            },
            {
                type: FormItemTypeEnum.Switch,
                fieldLabel: '状态',
                fieldName: 'status',
                checkedChildrenL: '开启',
                unCheckedChildren: '禁用',
                defaultChecked: true,
                span: 13,
            },
        ],
    }

    return (
        <>
            <div className="dict-list-header" style={{ paddingBottom: '20px' }}>
                <span className="dict-from-title">{selectedNodes[0].name === '' ? '一级字典' : selectedNodes[0].name} </span>
            </div>
            <div>
                <YhForm {...formConfig} />
            </div>
            <div style={{ float: 'right', marginTop: '25px', width: '55%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button type="link" shape="round" onClick={onSubmit}>
                    保存
                </Button>
                <Button type="link" shape="round" onClick={() => setSelectedNodes([])}>
                    取消
                </Button>
            </div>
        </>
    )
}
