export enum MdEnableStatusEnum {
    DISABLE,
    ENABLE,
}

export enum PartnerTypeEnum {
    CUSTOMER = 'PARTNER-TYPE.CUS',
    SUPPLIER = 'PARTNER-TYPE.SUP',
}

export enum UserTypeEnum {
    EMPLOYEE = 'user-type.employee', // "内部员工"
    MICRO_BOSS = 'user-type.micro-boss', // "微老板"
    DRIVER = 'user-type.driver', //"司机"
    HEADMAN = 'user-type.headman', //"队长"
    BOSS = 'user-type.boss', // "老板"
    SUPPLIER = 'user-type.supplier', //"供应商"
    CUSTOMER = 'user-type.customer', //"客户"
}

export enum TradeTypeEnum {
    SALE = 'trade-type.sale', //销售,
    PURCHASE = 'trade-type.purchase', //采购
    SALE_RETURN = 'trade-type.sale-return', //销售退货
    PURCHASE_RETURN = 'trade-type.purchase-return', //采采购退货
    PURCHASE_PLAN = 'trade-type.purchase-plan', //采购计划
    SALE_ORDER = 'trade-type.sale-order', //销售订单
    APPOINTMENT = 'trade-type.appointment', //预约
    MAINTENANCE = 'trade-type.maintenance', //维修
    DISPATCH = 'trade-type.dispatch', //派工
    SETTLE = 'trade-type.settle', //结算
    RETURN_VISIT = 'trade-type.return-visit', //回访
    STOCKTAKING = 'trade-type.stocktaking', //盘库
    LOSS = 'trade-type.loss', //损失
    BILL_DISCOUNT = 'trade-type.bill-discount', //账单抹零
}

export enum ReviewStatusEnum {
    INIT,
    REVIEWING,
    REVIEWED,
    REJECTED = 9,
}

export enum AccountStatusEnum {
    INIT,
    DISABLED = 9,
}

export enum CreditFlagEnum {
    NON_CREDIT,
    CREDIT,
}

export enum AdjustStatusEnum {
    INIT,
    REVIEWING,
    ADJUSTED,
    REJECTED = 8,
    FAIL,
}

export enum RechargeStatusEnum {
    INIT,
    RECHARGED,
    FAIL = 9,
}

export enum TransferStatusEnum {
    INIT,
    RECHARGED,
    FAIL = 9,
}

export enum AccountLogTypeEnum {
    OPEN = 'OPEN',
    DISABLE = 'DISABLE',
    ENABLE = 'ENABLE',
    ADJUST = 'ADJUST',
    ADJUST_APPROVAL = 'ADJUST_APPROVAL',
    RECHARGE = 'RECHARGE',
    TRANSFER_IN = 'TRANSFER_IN',
    TRANSFER_OUT = 'TRANSFER_OUT',
    BALANCE = 'BALANCE',
    UPDATE = 'UPDATE',
}

export enum BillIoFlagEnum {
    IN = 1,
    OUT,
}

export enum BillPayTypeEnum {
    CASH = 1,
    CREDIT_ACCOUNT,
}

export enum BillStatusEnum {
    INIT,
    BOOKED,
    BALANCED,
}

export enum BillCashStatusEnum {
    INIT,
    CASHED,
    RECHARGED,
    BALANCED,
    FAIL = 9,
}

export enum BillCancelStatusEnum {
    INIT,
    REVIEWING,
    CANCELLED,
    FAIL,
}

export enum BillInvoiceStatusEnum {
    INIT,
    APPLYING,
    PARTIAL,
    INVOICED,
}

export enum BillBalanceStatusEnum {
    INIT,
    BALANCED,
    FAIL = 9,
}

export enum BillLogTypeEnum {
    INSERT = 'INSERT',
    APPROVAL = 'DISABLE',
    CREDIT_BOOK = 'CREDIT_BOOK',
    CASH_BOOK = 'CASH_BOOK',
    BALANCE = 'BALANCE',
    INVOICE = 'INVOICE',
    INVOICE_CANCEL = 'INVOICE',
    INVOICE_APPROVAL = 'INVOICE_APPROVAL',
    CANCEL = 'CANCEL',
    CANCEL_APPROVAL = 'CANCEL_APPROVAL',
}

export enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2,
}

//记录各种单价的上浮
export enum RATES {
    RATE = 1.02,
    OU_HAO = 1.04,
    YUN_HU = 1.04,
}

export enum calculateStatusEnum {
    'calculate.draft' = '草拟',
    'calculate.init' = '已开单',
    'calculate.finish' = '已完结',
    'calculate.transferred' = '已转接',
}

export enum userQuotationStatusEnum {
    'quotation.draft' = '草拟',
    'quotation.init' = '已开单',
    'quotation.finish' = '已完结',
    'calculate.transferred' = '已转接',
}

export enum breakRepairStatusEnum {
    'repair.init' = '已开单',

    'repair.transferred' = '已转接',
}
export enum sendWorkStatusEnum {
    'send.init' = '已开单',

    'send.sendwork' = '已派工',
}

//消息提醒次数
export enum messageInformPeriodEnum {
    INFORMPERIOD_ONE = 'informPeriod.one', //一次
    INFORMPERIOD_MORE = 'informPeriod.more', //多次
}

//消息提醒类型
export enum messageInformModeEnum {
    INFORMMODE_SMS = 'informMode.sms', //短信
    INFORMMODE_OFFICIALACCOUNT = 'informMode.officialAccount', //公众号
    INFORMMODE_INSTATION = 'informMode.instation', //站内
}

//消息状态
export enum messageStatusEnum {
    MESSAGE_INIT = 'message.init', //未读
    MESSAGE_READ = 'message.read', //已读
    MESSAGE_END = 'message.end', //完成
}
