import React, { FC, useEffect, useImperativeHandle } from 'react'
import { Form, message } from 'antd'
import { __FormItemsBox as FormItemsBox } from './FormItemsBox'
import { IYhFormBox, ISelectOption } from './Types'
import './index.less'
import copy from 'copy-to-clipboard'

export const __FormBox: FC<IYhFormBox> = ({ formRef, boxs, ...props }) => {
    const [form] = Form.useForm()
    const { labelCol, labelAlign, disabled, formValues, onChange, onFinish, isShowHeader } = props
    const onValuesChange = (changedValues: any, values: any) => {
        onChange?.(changedValues, values)
    }

    const copyText = (fieldName: string, option?: ISelectOption[]) => {
        if (!!!option) {
            let value = form.getFieldsValue([fieldName])
            if (typeof value[fieldName] !== 'string') return
            copy(value[fieldName])
        } else {
            let value = form.getFieldsValue([fieldName])
            if (typeof value[fieldName] !== 'string') return
            copy(option?.find((item) => item.value === value[fieldName])?.label as string)
        }
        message.success('复制成功')
    }
    useEffect(() => {
        form.setFieldsValue(formValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues])

    useImperativeHandle(formRef, () => ({
        validateFields: async () => {
            return await form.validateFields()
        },
        setFieldsValue: (formValues) => {
            form.setFieldsValue(formValues)
        },
        getFieldsValue: () => {
            return form.getFieldsValue()
        },
        resetFields: form.resetFields,
        inst: () => {
            return form
        },
    }))
    return (
        <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish} labelCol={labelCol} labelAlign={labelAlign} layout="vertical">
            {boxs.map((el, index) => (
                <FormItemsBox
                    key={index}
                    {...el}
                    disabled={el.disabled ?? disabled}
                    copyText={(fieldName: string, option?: ISelectOption[]) => copyText(fieldName, option)}
                    isShowHeader={isShowHeader ?? true}
                />
            ))}
        </Form>
    )
}
