import { Table } from 'antd'

export const YCOinventoryCountingDetailTable = ({ list }) => {
    const columns = [
        {
            title: '序号',
            width: 60,
            dataIndex: 'code',
            render: (text, r, i) => i + 1,
        },
        {
            title: '商品编码',
            dataIndex: 'replaceCode',
            render: (text) => <>{text || '-'}</>,
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
        },
        {
            title: '货位',
            dataIndex: 'shelfCode',
        },
        {
            title: '最新采购价格',
            dataIndex: 'averagePurchasePrice',
            render: (text) => <>{'￥' + (text || 0)}</>,
        },
        {
            title: '系统库存',
            dataIndex: 'inventoryNum',
        },
        {
            title: '实际库存',
            dataIndex: 'realNum',
        },
        {
            title: '盘点结果',
            dataIndex: 'result',
        },
        {
            title: '差异量',
            dataIndex: 'differenceNum',
        },
        {
            title: '差异金额',
            dataIndex: 'differenceAmount',
            render: (text) => <>{'￥' + (text || 0)}</>,
        },
        {
            title: '报废数量',
            dataIndex: 'scrapNum',
        },
        {
            title: '报废金额',
            dataIndex: 'scrapAmount',
            render: (text) => <>{'￥' + (text || 0)}</>,
        },
        {
            title: '差异原因',
            dataIndex: 'differenceReason',
            render: (text) => <>{text || '-'}</>,
        },
        {
            title: '报废原因',
            dataIndex: 'scrapReason',
            render: (text) => <>{text || '-'}</>,
        },
    ]

    return (
        <>
            <Table rowClassName="editable-row" rowKey={(record) => record.id} columns={columns} dataSource={list} scroll={{ x: 'max-content' }} bordered />
        </>
    )
}
