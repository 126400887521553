import { useEffect, useState } from 'react'
import { DataTable } from './DataTable'
import { YhBox } from '@/components/YhBox'
import useGlobalModel from '@/model/globalModel'
import { useModel } from '../model'
import { useNavModel } from '@/model/navModel'
import { Row, Col, Select, Button } from 'antd'
import { pendingOptions, shopTypeOptions } from '@/types/TcoDs'

export const DataBox = () => {
    const { stockType, pageMode, exportData } = useModel()
    const { switchNavState } = useNavModel(() => [])
    const { menuAuth } = useGlobalModel()

    const [isHas10, setIsHas10] = useState(false)
    const [isHas7, setIsHas7] = useState(false)

    const handleDetail = (option = 'type', value: string) => {
        let _key = '3-6'
        let _params = { type: pageMode, tab: stockType, id: '', mode: 'look' }

        if (option === 'type') {
            _params.tab = value
        }
        if (option === 'mode') {
            _params.type = value
        }
        switchNavState(_key, _params)
    }

    useEffect(() => {
        if (menuAuth?.indexOf('/homeAll') !== -1) {
            setIsHas10(true)
        }
        if (menuAuth?.indexOf('/exportRecord') !== -1) {
            setIsHas7(true)
        }
    }, [menuAuth])

    const action = (
        <>
            <Row gutter={[20, 10]} justify="start" style={{ marginRight: '146px' }}>
                <Col span="1.5" key="name" className="ant-col-h-auto" style={{ fontSize: '14px', color: '#666', display: 'flex', alignItems: 'center' }}>
                    选择切换查看类型
                </Col>
                <Col span="4" key="type" className="ant-col-h-auto">
                    <Select
                        size="small"
                        className="ant-select-round"
                        value={stockType}
                        placeholder="切换待处理类型"
                        onChange={(value) => {
                            handleDetail('type', value)
                        }}
                        options={pendingOptions}
                        showSearch
                        optionFilterProp="label"
                    />
                </Col>
                {isHas10 && (
                    <Col span="4" key="mode" className="ant-col-h-auto">
                        <Select
                            size="small"
                            className="ant-select-round"
                            value={pageMode}
                            placeholder="切换门店类型"
                            onChange={(value) => {
                                handleDetail('mode', value)
                            }}
                            options={shopTypeOptions}
                            showSearch
                            optionFilterProp="label"
                        />
                    </Col>
                )}
                {isHas7 && (
                    <Col span="4" key="export" className="ant-col-h-auto">
                        <Button className="mr-5" type="link" shape="round" onClick={() => exportData()}>
                            导出
                        </Button>
                    </Col>
                )}
            </Row>
        </>
    )
    return (
        <YhBox action={action} style={{ height: 'calc(100vh - 185px)' }}>
            <DataTable />
        </YhBox>
    )
}
