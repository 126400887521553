import { useEffect, useState } from 'react'
import { Table } from 'antd'
import useGlobalModel from '@/model/globalModel'
import { useModel } from '../model'
import { YhTableSort } from '@/components/YhTableSort'
import { commonService } from '@/service/common'
import YhTootip from '@/components/YhTootip'
import { momentFormat } from '@/utils/utils'
import { SettingOutlined } from '@ant-design/icons'
import { useDictModel } from '@/views/base/dict/dictModel'
import { setStatusTag } from '@/App'
import { DictCodeEnmu } from '@/types/AppDictEnums'

const sortKey = 'unfinished'

export function DataTable() {
    const {
        user: { branch },
    } = useGlobalModel()
    const { dataSource, pgCfg, loadData, columnsSetVisible, setColumnsSetVisible, orders, loading, pageMode, resetSearchParam } = useModel()
    const { getSelectedDictOne } = useDictModel(() => [])

    useEffect(() => {
        if (pageMode) {
            resetSearchParam()
            getColumns()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageMode])

    const getRegularColumns = [
        {
            title: (
                <>
                    <span>
                        <SettingOutlined
                            onClick={() => {
                                setColumnsSetVisible(true)
                            }}
                        />
                    </span>
                    <span>序号</span>
                </>
            ),
            width: 80,
            fixed: 'left',
            align: 'center',
            render: (t, r, i) => i + 1,
        },
    ]
    const defaultColumns = [
        {
            title: '分支编码',
            width: 100,
            dataIndex: 'branch',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '门店名称',
            width: 140,
            dataIndex: 'shopName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '单据类型',
            width: 100,
            dataIndex: 'type',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '单据编号',
            width: 200,
            dataIndex: 'code',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '200px' }} />,
        },
        {
            title: '单位名称',
            width: 220,
            dataIndex: 'partnerName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '220px' }} />,
        },
        {
            title: '单据状态',
            width: 160,
            dataIndex: 'status',
            isSet: true,
            render: (text) => {
                let findNode = getSelectedDictOne(pageMode === 'all' ? 'all' : branch, 'unfinished', DictCodeEnmu.DOCUMENT_STSTUS).find((item) => {
                    return item.value.toString().indexOf(text) > -1
                })
                return setStatusTag(findNode ? findNode.label.split('（')[0] : text ?? '-')
            },
        },
        {
            title: '制单人',
            width: 100,
            dataIndex: 'createUser',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
            isSet: true,
            render: (text) => momentFormat(text),
        },
    ]

    const [columns, setColumns] = useState<any[]>([])

    const getColumns = () => {
        commonService.userCustomizeConfigOne(sortKey).then((res) => {
            if (!res.retData) {
                setColumns([...getRegularColumns, ...defaultColumns])
            } else {
                let newColumns: any[] = [...getRegularColumns]
                let data = JSON.parse(res.retData.val)
                data.forEach((element) => {
                    if (element.isShow) {
                        newColumns.push({ ...defaultColumns.find((i) => i.dataIndex === element.key), sorter: element.isSort })
                    }
                })
                setColumns(newColumns)
            }
        })
    }

    const onSuccess = () => {
        setColumnsSetVisible(false)
        getColumns()
    }

    const onChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            if (sorter.order) {
                orders.current = [{ column: sorter.field, asc: sorter.order === 'ascend' }]
            } else {
                orders.current = []
            }
            loadData()
        }
    }

    return (
        <>
            <Table
                rowKey={(record) => record.id || record.index}
                onChange={onChange}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                pagination={{
                    ...pgCfg,
                    position: ['bottomRight'],
                    showTotal: () => `共${pgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    },
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 'max-content', y: 'calc(100vh - 380px)' }}
            />
            <YhTableSort
                sortKey={sortKey}
                visible={columnsSetVisible}
                setVisible={setColumnsSetVisible}
                defaultColumns={defaultColumns}
                onSuccess={onSuccess}
            />
        </>
    )
}
