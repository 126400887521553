import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'

//详情
export interface IDetail {
    /**
     * 渠道：export(yh-export)、analyze(ci-analyze)
     */
    channel?: string
    /**
     * 分支
     */
    branch?: string
    /**
     * 菜单名称
     */
    menu?: string
    /**
     * 文件名称
     */
    fileName?: string
    /**
     * 创建时间
     */
    createTime?: string
    /**
     * 开始时间
     */
    startTime?: string
    /**
     * 结束时间
     */
    endTime?: string
    /**
     * 单据状态
     */
    status?: string
    /**
     * 失败原因
     */
    failMsg?: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    fileName?: string
    menu?: string
    status?: string
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    branch?: string
}

class Service {
    async page(searchVo: ISearch) {
        let apiUrl = '/yh-export/export/record/list'
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, { timeout: 300000, ...{} })
    }
}

export const service = new Service()
