import React, { FC } from "react";
import { IYHDetailNav } from "./Types";
import "./box.less";

export const YHDetailNav: FC<IYHDetailNav> = (props) => {
    const {
        title,
        width,
        style,
        btns,
        action
    } = props;
    return (
        <div
            className={`list-container detail-nav ${width ? 'inline-block' : ''}`}
            style={{ width: width ?? 'auto', ...style }}
        >
            {
                title && <div className={`detail-nav-title`}>{title}</div>
            }
            {
                action && action
            }
            {
              btns && (<div className={`detail-nav-btn`}>{btns}</div>)  
            }
        </div>
    )
}