import React from "react";
import { ISearchField, ISearchProp } from "../Types";
import { Checkbox } from "antd";

type PropType = ISearchProp & ISearchField;
export const SearchCheckBox = (props: PropType) => {
    return <Checkbox
        checked={Number(props.searchValues?.[props.fieldName]) ? true : false}
        onChange={(e) => {
            props.onChange?.({ [props.fieldName]: e.target.checked ? "1" : "0" })
            if (props.triggerSearch) props.handleSearch?.({ [props.fieldName]: e.target.checked ? "1" : "0" });
        }}
        disabled={props.disabled}><span className="ant-checkbox-inner-text" style={{ color: props.color }}>{props.placeholder}</span></Checkbox>
}