import './home.less'
import styled from '@emotion/styled'
import { Header } from './Header'
import { MessageBox } from './MessageBox'
import { QuickEntrance } from './QuickEntrance'
import LineChart from './echarts/LineChart'
import PieChart from './echarts/PieChart'
import PieCavityChart from './echarts/PieCavityChart'

export function Home() {
    let isFullSize: boolean = false
    let windowHeight = window.outerHeight
    if (windowHeight > 1080) {
        isFullSize = true
    }
    return (
        <div className={isFullSize ? 'home full' : 'home'}>
            <Header type="one" />
            <Box className="content-top">
                <div className="left">
                    <MessageBox type="one" />
                    <QuickEntrance />
                </div>
                <div className="right">
                    <LineChart type="one" />
                </div>
            </Box>
            <div className="content-bottom" style={{ flex: 1, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <PieChart />
                <PieCavityChart />
            </div>
        </div>
    )
}
const Box = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    .left {
        width: 310px;
    }
    .right {
        flex: 1;
        margin-left: 16px;
        overflow: hidden;
    }
`
