import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { getBaseCiRequestUrl } from '@/utils/constants'

//详情
export interface IDetail {
    /**
     * 分支
     */
    branch: string
    /**
     * 单据编号
     */
    bussCode: string
    /**
     * 业务类型，text === "usd" ? "非关联销售退货明细" : "采购明细"
     */
    bussType: string
    buyingPrice: null
    /**
     * 入库单价
     */
    costPrice: number
    createTime: number[]
    distributorPrice: null
    giftsNum: number
    id: string
    /**
     * 库存成本
     */
    inventoryAmount: number
    /**
     * 库存数量
     */
    inventoryQuantity: number
    latestPurPrice: number
    /**
     * 商品编码
     */
    materialCode: string
    /**
     * 商品名称
     */
    materialName: string
    num: number
    /**
     * 占用数量
     */
    occupyNum: number
    orgCode: string
    /**
     * 单位名称
     */
    orgName: string
    repairPrice: null
    /**
     * 替换编号
     */
    replaceCode: string
    returnNum: string
    sellingPrice: null
    /**
     * 货号
     */
    shelfCode: string
    shelfCodeFZf: null
    shelfCodeTf: null
    shelfCodeZf: null
    shelfIdFZf: null
    shelfIdTf: null
    shelfIdZf: null
    shopId: string
    /**
     * 门店名称
     */
    shopName: string
    /**
     * 入库金额
     */
    stockIoAmount: number
    /**
     * 入库数量
     */
    stockIoNum: number
    /**
     * 入库日期
     */
    stockIoTime: string
    tfFlag: number
    typeCode: string
    typeName: string
    unitCode: string
    unitName: string
    warehouseCode: string
    warehouseCodeFZf: null
    warehouseCodeTf: null
    warehouseCodeZf: null
    /**
     * 仓库
     */
    warehouseName: string
    warehouseNameFZf: null
    warehouseNameTf: null
    warehouseNameZf: null
    /**
     * (text, record) => +(record?.inventoryQuantity ?? 0) - +(record?.occupyNum ?? 0)
     */
    可用数量: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    result?: string
    offset?: string
    module?: string
    ip?: string
    operationType?: string
    startTime?: string
    endTime?: string
    operator?: string
    searchItem?: {}
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
    branch: string
}

export interface Istatistics {
    /**
     * 库存金额（明细汇总）
     */
    totalInventoryAmount?: number
    /**
     * 库存金额（最新采购价）
     */
    totalInventoryAmountByLatestPurPrice?: number
    /**
     * 库存数量
     */
    totalInventoryNum?: number
    /**
     * 库存金额
     */
    stockAmount?: number
    /**
     * 库存数量
     */
    stockNum?: number
}

class Service {
    async page(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/inventory/details/inventoryDetailsSearch'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/purchase/stock'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/purchase/stock'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-storage/stock/details/findPage'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }
    async total(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/inventory/details/statistics'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/stock/details/total/amount'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/stock/details/total/amount'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-storage/stock/details/total/amount'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.post<Istatistics>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }

    //导出
    async export(searchVo: ISearch) {
        let apiUrl = ''
        if (searchVo.branch === 'scm') {
            apiUrl = '/scm2/inventory/details/export'
        }
        if (searchVo.branch === 'bcm') {
            apiUrl = '/bcm/stock/details/exports'
        }
        if (searchVo.branch === 'vrm') {
            apiUrl = '/vrm2/stock/details/exports'
        }
        if (searchVo.branch === 'ci') {
            apiUrl = '/ci-analyze/stock/export'
        }
        const baseURL = searchVo.branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{ baseURL } })
    }
}

export const service = new Service()
