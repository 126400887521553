import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { getBaseCiRequestUrl } from '@/utils/constants'

//详情
export interface IDetail {
    bentityCode: string
    bentityName: string
    /**
     * 单据数量
     */
    billNum: number
    /**
     * 分支编码
     */
    branch: null
    /**
     * 入库单号
     */
    code: string
    courier: null
    courierEndTime: null
    courierStartTime: null
    courierTime: null
    /**
     * 制单时间
     */
    createTime: string
    /**
     * 制单员
     */
    createUser: string
    deleteFlag: number
    details: null
    detailsStockTask: null
    id: string
    inspector: string
    inspectorEndTime: null
    inspectorStartTime: null
    inspectorTime: null
    ioFlag: string
    isDirect: null
    note: string
    openingAmount: null
    openingNum: null
    operator: null
    prepareEndTime: null
    prepareStartTime: null
    prepareTime: null
    shopId: string
    /**
     * 门店名称
     */
    shopName: string
    /**
     * 单据状态
     */
    status: null
    stockIoStaff: null
    /**
     * 入库时间
     */
    stockIoTime: string
    stockIoTypeCode: string
    /**
     * 出入库类型
     */
    stockIoTypeName: string
    /**
     * 库管员
     */
    storekeeper: string
    /**
     * 入库金额
     */
    totalAmount: number
    /**
     * 入库数量
     */
    totalNum: number
    tradeCode: string
    tradeId: string
    tradeTypeCode: null
    tradeTypeName: null
    updateTime: string
    updateUser: null
    userCode: string
    userName: string
    /**
     * 暂无
     */
    打印次数: string
    [property: string]: any
}

export interface IListDetail {
    /**
     * 总金额
     */
    amount?: number
    /**
     * 分支
     */
    branch?: string
    /**
     * 单号
     */
    code: string
    /**
     * 制单时间
     */
    createTime?: string
    /**
     * 制单员
     */
    createUser?: string
    /**
     * 数量
     */
    num?: number
    /**
     * 单价
     */
    price?: number
    /**
     * 门店ID
     */
    shopId?: string
    /**
     * 门店名称
     */
    shopName?: string
    /**
     * 单据状态
     */
    status: string
    /**
     * 出入库时间
     */
    stockIoTime?: string
    /**
     * 库管员
     */
    storekeeper?: string
    /**
     * 出入库类型编码
     */
    tradeTypeCode?: string
    /**
     * 出入库类型
     */
    tradeTypeName: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    code?: string
    tradeTypeCode?: string
    status?: any
    startTime?: string
    endTime?: string
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
    branch: string
}

export interface IpositionSearch extends ISearchFields, ISearchPage {}

//编辑
export interface IMaintenanceArchivesUpdate extends IDetail {
    id: string
}

class Service {
    async page(searchVo: ISearch, config: object = {}) {
        let apiUrl = '/ims/stockIo/in'
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, config)
    }

    //详情页
    async one(id: string, branch: string, type: string) {
        let apiUrl = ''
        if (branch === 'scm') {
            if (type === '采购') {
                apiUrl = `scm2/purchase/po/findByIdOrCode/${id}`
            }
            if (type === '采购退货') {
                apiUrl = `/scm2/purchase/ro/findByIdOrCode/${id}`
            }
            if (type === '销售') {
                apiUrl = `/scm2/sale/so/findByIdOrCode/${id}`
            }
            if (type === '销售退货') {
                apiUrl = `/scm2/sale/ro/findByIdOrCode/${id}`
            }
            if (type === '非关联退货') {
                apiUrl = `/scm2/unrelate/sreturn/one/${id}`
            }
        }
        if (branch === 'bcm') {
            if (type === '采购') {
                apiUrl = `/bcm/purchase/findById/${id}`
            }
            if (type === '采购退货') {
                apiUrl = `/bcm/preturn/findById/${id}`
            }
            if (type === '销售') {
                apiUrl = `/bcm/sale/findById/${id}`
            }
            if (type === '销售退货') {
                apiUrl = `/bcm/sreturn/findById/${id}`
            }
            //公交销售
            if (type === '预售单') {
                apiUrl = `/bcm/transit/sale/findById/${id}`
            }
            if (type === '公交销退') {
                apiUrl = `/bcm/transit/sreturn/findById/${id}`
            }
            if (type === '维修') {
                apiUrl = `/bcm/maintenance/findById/${id}`
            }
        }
        if (branch === 'vrm') {
            if (type === '采购') {
                apiUrl = `/vrm2/purchase/findById/${id}`
            }
            if (type === '采购退货') {
                apiUrl = `/vrm2/preturn/findById/${id}`
            }
            if (type === '销售') {
                apiUrl = `/vrm2/sale/findById/${id}`
            }
            if (type === '销售退货') {
                apiUrl = `/vrm2/sreturn/findById/${id}`
            }
            if (type === '维修') {
                apiUrl = `/vrm2/maintenance/findById/${id}`
            }
            if (type === '维修退货') {
                apiUrl = `/vrm2/repair/return/findById/${id}`
            }
        }
        if (branch === 'ci') {
            if (type === '采购') {
                apiUrl = `/ci-storage/purchase/findById/${id}`
            }
            if (type === '采购退货') {
                apiUrl = `/ci-storage/preturn/findById/${id}`
            }
            if (type === '销售') {
                apiUrl = `/ci-storage/sale/findById/${id}`
            }
            if (type === '销售退货') {
                apiUrl = `/ci-storage/sreturn/findById/${id}`
            }
            if (type === '维修') {
                apiUrl = `/ci/order/return/oneCode/${id}`
            }
            if (type === '维修退货') {
                apiUrl = `/ci/order/return/oneCode/${id}`
            }
        }
        const baseURL = branch === 'ci' ? getBaseCiRequestUrl() : undefined
        return await httpRequest.get<IDetail>(apiUrl, { timeout: 300000, ...{ baseURL } })
    }

    //导出
    async export(searchVo: ISearch) {
        let apiUrl = '/yh-export/stockIo/in/export'
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{} })
    }
}

export const service = new Service()
