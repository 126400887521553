import React, { FC } from 'react'
import { Button, Row, Col } from 'antd'
import { IAdvanceSearchProp } from './Types'
import { SearchItem } from './item'
import { YHRightModal } from '../YHRightModal'
import './header.less'

export const AdvancedSearch: FC<IAdvanceSearchProp> = (props) => {
    const { handleReset = () => {}, handleSearch = () => {}, searchMoreFields, size, showAdvanced, setShowAdvanced } = props
    const onCancel = () => {
        setShowAdvanced(false)
    }
    const onOk = () => {
        handleSearch()
        setShowAdvanced(false)
    }
    const onReset = () => {
        handleReset()
    }

    return (
        <YHRightModal
            visible={showAdvanced}
            onOk={() => {}}
            onCancel={onCancel}
            title="高级搜索"
            width={360}
            bodyHeight={700}
            showFooter={true}
            footerButs={[
                <Button onClick={onReset} key="1">
                    恢复默认
                </Button>,
                <Button onClick={onCancel} key="2">
                    取消
                </Button>,
                <Button onClick={onOk} type="primary" key="3">
                    确定
                </Button>,
            ]}
        >
            {searchMoreFields?.map((el, index) => (
                <Row key={index}>
                    <Col span={24}>
                        <span>{el.title}</span>
                    </Col>
                    <Col span={24} style={{ margin: '5px 0 15px 0' }}>
                        <SearchItem {...{ ...props, ...el, size, span: 24 }} />
                    </Col>
                </Row>
            ))}
        </YHRightModal>
    )
}
