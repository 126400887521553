import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Select, Spin, Empty } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
    defaultQuery?: string
}

function YhDebounceSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any>
    ({ fetchOptions, debounceTimeout = 800, defaultQuery, ...props }: DebounceSelectProps) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<ValueType[]>([]);
    const fetchRef = useRef(0);

    //默认有查询条件 会请求赋值,  只请求一次
    useEffect(() => {
        defaultQuery && !!!fetchRef.current && fetchOptions(defaultQuery).then((newOptions) => setOptions(newOptions ?? []))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultQuery]);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1
            const fetchId = fetchRef.current
            setOptions([])
            setFetching(true)

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return
                }

                setOptions(newOptions ?? [])
                setFetching(false)
            })
        }

        return debounce(loadOptions, debounceTimeout)
    }, [fetchOptions, debounceTimeout]);

    return (
        <Select<ValueType>
            labelInValue={false}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据,请输入正确查询的条件" />}
            {...props}
            options={options}
        />
    );
}

export default YhDebounceSelect;


// async function fetchUserList(username: string): Promise<UserValue[]> {
// 	console.log('fetching user', username);

// 	return fetch('https://randomuser.me/api/?results=5')
// 		.then(response => response.json())
// 		.then(body =>
// 			body.results.map(
// 				(user: { name: { first: string; last: string }; login: { username: string } }) => ({
// 					label: `${user.name.first} ${user.name.last}`,
// 					value: user.login.username,
// 				}),
// 			),
// 		);
// }

// const Demo = () => {
// 	const [value, setValue] = React.useState<UserValue[]>([]);

// 	return (
// 		<DebounceSelect
// 			mode="multiple"
// 			value={value}
// 			placeholder="Select users"
// 			fetchOptions={fetchUserList}
// 			onChange={newValue => {
// 				setValue(newValue);
// 			}}
// 			style={{ width: '100%' }}
// 		/>
// 	);
// };