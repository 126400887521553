import { useModel } from '../model'
import { ISearchValue, SearchTypeEnum } from '@/components/YhPageHeader/Types'
import { YhPageHeader } from '@/components/YhPageHeader'
import useGlobalModel from '@/model/globalModel'
import { useDictModel } from '@/views/base/dict/dictModel'
import { DictCodeEnmu } from '@/types/AppDictEnums'
import { arrayNonRepeatfy } from '@/utils/utils'

export function PageHeader() {
    const {
        user: { branch },
        whDs,
    } = useGlobalModel()
    const { getSelectedDictOne } = useDictModel(() => [])
    const { searchParam, updateSearchParam, resetSearchParam, loadData } = useModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '盘库单号',
            fieldName: 'stocktakingCode',
            span: 4,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '商品编码',
            fieldName: 'materialCode',
            span: 4,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '请选择盘点仓库',
            fieldName: 'warehouseCode',
            span: 4,
            options: whDs,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '盘点状态',
            fieldName: 'status',
            span: 5,
            options: arrayNonRepeatfy(getSelectedDictOne(branch, 'inventoryCounting', DictCodeEnmu.STOCK_CHECK_STATUS), 'value'),
            mode: 'multiple',
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: '',
            title: '入库时间',
            rangePickerFieldName: ['startTime', 'endTime'],
            placeholder: ['盘点开始时间', '盘点结束时间'],
            format: 'YYYY-MM-DD',
            span: 5,
        },
    ]

    return (
        <YhPageHeader
            title=""
            size="small"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    )
}
