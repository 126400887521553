import { useEffect, useRef, useState } from 'react'
import { createModel } from 'hox'
import { TablePaginationConfig } from 'antd/es/table/interface'
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants'
import { IDetail, ISearchFields, service } from './service'
import useGlobalModel, { transPgToPagination } from '@/model/globalModel'
import { ISearchPage } from '@/types/AppTypes'
import { exportStatusOptions } from '@/types/TcoDs'
import { getBaseCiDownLoadUrl } from '@/utils/constants'

export const useModel = createModel(function () {
    const {
        user: { shopId, branch, userCode },
    } = useGlobalModel()

    /**
     * 列表
     */
    //列表数据源
    const [dataSource, setDataSource] = useState<IDetail[]>([])
    //查询条件
    const [searchParam, setSearchParam] = useState<ISearchFields>({})
    const orders = useRef<{ column: string; asc: boolean }[]>([])
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg)
    const pgCfgRef = useRef<TablePaginationConfig>(initPgCfg)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        pgCfgRef.current = pgCfg
    }, [pgCfg])

    //列表查询
    const loadData = async (page: ISearchPage = pgCfgRef.current, searchValue: ISearchFields = searchParam) => {
        setLoading(true)

        const searchParams = {
            shopId,
            branch,
            searchItem: { ...searchValue, userCode: userCode, appName: branch, shopId: shopId },
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? DEFAULT_FIRST_PAGE_NUM,
            orders: orders.current,
        }
        let { retData } = await service.page(searchParams)
        const { records, ...pg } = retData
        setPgCfg(transPgToPagination(pg))
        records?.forEach((item, index) => {
            item.index = index
            item.branch = branch
            item.status = exportStatusOptions.find((option) => option.value === item.status)?.label || '-'
            if (item.channel === 'analyze') {
                item.downloadUrl = getBaseCiDownLoadUrl() + '/excel-export/' + item.fileName
            }
        })
        setDataSource(records)
        setLoading(false)
    }
    //设置搜索value
    const updateSearchParam = (updateParam: ISearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({})
        orders.current = []
        loadData(initPgCfg, {})
    }

    const [columnsSetVisible, setColumnsSetVisible] = useState<boolean>(false)

    return {
        /***field***/
        searchParam,
        dataSource,
        pgCfg,
        columnsSetVisible,
        loading,
        orders,
        /***method***/
        setDataSource,
        resetSearchParam,
        updateSearchParam,
        loadData,
        setColumnsSetVisible,
    }
})
