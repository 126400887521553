import React from 'react'
import { ISearchField, ISearchProp } from '../Types'
import { InputNumber, Tooltip } from 'antd'

type PropType = ISearchProp & ISearchField
export const SearchInputNumber = (props: PropType) => {
    return (
        <Tooltip title="我支持回车检索哦">
            <InputNumber
                size={props.size}
                min={1}
                max={12}
                precision={0}
                className="border-r-16 w-100"
                placeholder={props.placeholder as string}
                onPressEnter={() => (props.triggerSearch ? props.handleSearch?.() : null)}
                value={props.searchValues?.[props.fieldName] as number}
                onChange={(value: number | null) => {
                    props.onChange?.({ [props.fieldName]: value ?? '' })
                    // if (props.triggerSearch) props.handleSearch?.();
                }}
                // onStep={(value: number | string) => props.triggerSearch ? props.handleSearch?.({ [props.fieldName]: value }) : null}
                disabled={props.disabled}
            />
        </Tooltip>
    )
}
