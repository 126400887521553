import { ValidateErrorEntity } from 'rc-field-form/es/interface'
import { message } from 'antd'
import moment from 'moment'
import BigNumber from 'bignumber.js'
type InitBody = {
    [key: string]: any
}

type IobjItem = {
    id: string
    [key: string]: unknown
}
/**
 * url参数解析
 * @example String "https://mp.csdn.net?id=123456&a=b"
 * @return Object {id:"123456",a:"b"}
 */
export function urlParse(url: string): InitBody {
    let obj: InitBody = {}
    /*
     * 正则匹配
     * [?&]:匹配?或&其一
     * [^?&]:非?或非&
     * */
    let reg = /[?&][^?&]+=[^?&]+/g
    let arr = url.match(reg)
    if (arr) {
        arr.forEach((item) => {
            let tempArr = item.substring(1).split('=')
            // console.log(tempArr)
            let key = tempArr[0]
            let val = tempArr[1]
            obj[key] = val
        })
    }
    return obj
}

/**
 *根据要查找的key返回这一项 （递归查找）
 * @param {Array}  array 要查找的源数组
 * @param {string} code   唯一标识
 * @param {string} key   要查找的键名， 和code对应的  默认id
 * @param {Boolean} filter   查找是否过滤掉不要在左侧菜单展示的页面
 */

export function forFind(array: any[], code: string, key: string = 'id', filter: boolean = false) {
    for (const item of array) {
        if (item[key] === code && (filter ? item.isShow : true)) {
            return item
        }
        if (item.children && item.children.length > 0) {
            let forFindRet: any = forFind(item.children, code, key, filter)
            if (forFindRet) {
                return forFindRet
            }
        }
    }
}

//re-from 错误校验提示信息方法
export function getFormValidatorErrText(error: ValidateErrorEntity) {
    let { errorFields } = error
    for (let key in errorFields) {
        if (errorFields[key]?.errors?.length > 0) {
            return errorFields[key]?.errors[0]
        }
    }
    return '未知问题!'
}

/**
 * 数组里面（过滤数组对象id为重复的）
 *    let arr = [
            { id: "11", value: 11 },
            { id: "11", value: 11 },
            { id: "22", value: 22 },
        ]
      console.log(filterArrId(arr)) 
 */

export function filterArrId(arr: IobjItem[]): IobjItem[] {
    let obj: InitBody = {}
    let newArr: IobjItem[] = []
    arr.forEach((item) => {
        if (!obj[item.id]) {
            newArr.push(item)
            obj[item.id] = true
        }
    })
    return newArr
}

/**
 * 判断对象的值是不是全为空
 */
export function objectValueIsEmpty(object: InitBody): boolean {
    var isEmpty = true //值全为空
    Object.keys(object).forEach(function (x) {
        if (object[x] != null && object[x] !== '') {
            isEmpty = false
        }
    })
    return isEmpty
}

/**
 * 判断是否是对象
 */
export function isObject(obj: any): boolean {
    return Object.prototype.toString.call(obj) === '[object Object]'
}

// 判断两个对象(包括数组)是否相等
export function isObjectEqual(a: any, b: any) {
    let aProps = Object.getOwnPropertyNames(a)
    let bProps = Object.getOwnPropertyNames(b)
    if (aProps.length !== bProps.length) {
        return false
    }
    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i]
        let propA = a[propName]
        let propB = b[propName]
        if (typeof propA === 'object') {
            if (!isObjectEqual(propA, propB)) {
                return false
            }
        } else if (propA !== propB) {
            return false
        }
    }
    return true
}

/**
 * 递归去除参数的前后空格
 */
export const trimParams = (data: any) => {
    if (typeof data === 'string') return data.trim()
    if (data && typeof data === 'object') {
        for (let key in data) {
            data[key] = trimParams(data[key])
        }
    }
    return data
}

/**
 * 数字补零   返回num字符串
 */
export function formatNum(num: string | number | undefined): string {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.00'
    }
    if (typeof num === 'number') {
        num = String(num)
    }

    num = num.replace(/[^0-9|.]/g, '') //清除字符串中的非数字非.字符

    if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += '.00'
    if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = '0' + num
    num += '001' //在字符串末尾补零
    return (+num).toFixed(2)
}

/**
 * 返回几位小数
 */
export function formatNumDigit(num: string | number | undefined, digit?: number): string {
    digit = digit ? digit : 2
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.' + '0'.repeat(digit)
    }
    if (typeof num === 'number') {
        num = String(num)
    }

    num = num.replace(/[^0-9|.]/g, '') //清除字符串中的非数字非.字符

    if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += '.00'
    return (+num).toFixed(digit)
}
/**
 * 数字补零 4位   返回num字符串
 */
export function formatNum4(num: string | number | undefined): string {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.0000'
    }
    if (typeof num === 'number') {
        num = String(num)
    }

    num = num.replace(/[^0-9|.]/g, '') //清除字符串中的非数字非.字符

    if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += '.0000'
    if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = '0' + num
    num += '0000001' //在字符串末尾补零
    return (+num).toFixed(4)
}

/**
 * 数字补零   返回num字符串
 */
export function formatNum5(num: string | number | undefined): string {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.000'
    }
    if (typeof num === 'number') {
        num = String(num)
    }

    num = num.replace(/[^0-9|.]/g, '') //清除字符串中的非数字非.字符

    if (/^0+/)
        //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num))
        //为整数字符串在末尾添加.00
        num += '.00'
    if (/^\./.test(num))
        //字符以.开头时,在开头添加0
        num = '0' + num
    num += '000001' //在字符串末尾补零
    return (+num).toFixed(5)
}

/**
 * 整数数字转换  返回num字符串
 */
export function formatInt(num: string | number | undefined, contrast: number | null = null, msg: string = '错误警告'): string {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0'
    }
    num = Math.abs(+num)
    //对比数值， 不对返回错误
    if (typeof contrast === 'number' && contrast < +num) {
        message.warning(msg)
        return '0'
    }
    return `${num}`.split('.')[0]
}
/**
 * 整数数字转换  返回num字符串
 */
export function formatDiscount(num: string | number | undefined): string {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0'
    }
    num = `${num}`
    if (/^0\.[0-9]{1,2}$|^0{1}$|^1{1}$|^1\.[0]{1,2}$/.test(num)) {
        return num
    } else {
        return '0'
    }
}

/**
 * 数字字符串转换数字
 */
export function switchNum(val: string | undefined): number | null {
    if (val && typeof val === 'string' && !isNaN(+val)) {
        return Number(val)
    }
    return null
}
/**
 * 数字字符串转换数字
 */
export function switchNum1(val: string | number | undefined, initNum?: string): string {
    if ((typeof val === 'string' || typeof val === 'number') && !isNaN(+val)) {
        return `${val}`
    }
    return initNum ?? '0'
}

//判断是否为数字
export function isNumber(val: string | number | undefined): number {
    val = `${val}`
    if (/^[+-]?\d*\.?\d*$/.test(val)) {
        return +val
    }
    return 0
}
/**
 * 生成指定区间的随机整数
 */
export function randomNum(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min)
}

//阿拉伯数字转换成大写汉字
export function numberParseChina(money: number | string) {
    //汉字的数字
    var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
    //基本单位
    var cnIntRadice = ['', '拾', '佰', '仟']
    //对应整数部分扩展单位
    var cnIntUnits = ['', '万', '亿', '兆']
    //对应小数部分单位
    var cnDecUnits = ['角', '分', '毫', '厘']
    //整数金额时后面跟的字符
    var cnInteger = '整'
    //整型完以后的单位
    var cnIntLast = '圆'
    //最大处理的数字
    var maxNum = 999999999999999.9999
    //金额整数部分
    var integerNum
    //金额小数部分
    var decimalNum
    //输出的中文金额字符串
    var chineseStr = ''
    //分离金额后用的数组，预定义
    var parts
    if (money === '') return ''
    money = parseFloat(money as string)
    if (money >= maxNum) {
        //超出最大处理数字
        return ''
    }
    if (money === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger
        return chineseStr
    }
    //转换为字符串
    money = money.toString()
    if (money.indexOf('.') === -1) {
        integerNum = money
        decimalNum = ''
    } else {
        parts = money.split('.')
        integerNum = parts[0]
        decimalNum = parts[1].substr(0, 4)
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0
        var IntLen = integerNum.length
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1)
            var p = IntLen - i - 1
            var q = p / 4
            var m = p % 4
            if (n === '0') {
                zeroCount++
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0]
                }
                //归零
                zeroCount = 0
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
            }
            if (m === 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q]
            }
        }
        chineseStr += cnIntLast
    }
    //小数部分
    if (decimalNum !== '') {
        var decLen = decimalNum.length >= 2 ? 2 : decimalNum.length
        for (var j = 0; j < decLen; j++) {
            var num = decimalNum.substr(j, 1)
            if (num !== '0') {
                chineseStr += cnNums[Number(num)] + cnDecUnits[j]
            }
        }
    }
    if (chineseStr === '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger
    } else if (decimalNum === '') {
        chineseStr += cnInteger
    }
    return chineseStr
}

//关闭浏览器页签
export function closeWebPage() {
    if (navigator.userAgent.indexOf('MSIE') > 0) {
        // IE
        if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
            // IE6
            window.opener = null
            window.close()
        } else {
            // IE6+
            window.open('', '_top')
            window.top?.close()
        }
    } else if (navigator.userAgent.indexOf('Firefox') > 0 || navigator.userAgent.indexOf('Presto') > 0) {
        // FF和Opera
        window.location.href = 'about:blank'
        window.close() // 火狐默认状态非window.open的页面window.close是无效的
    } else {
        window.opener = null
        window.open('', '_self', '')
        window.close()
    }
}

//获取随机字符串
export function getRandomStr(length = 32) {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let maxPos = chars.length
    let _string = ''
    for (let i = 0; i < length; i++) {
        _string += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return _string
}

//返回日期格式为YYYY-MM-DD HH:mm
export function momentFormat(text: string | number[], format = 'YYYY-MM-DD HH:mm') {
    if (Array.isArray(text)) {
        text = `${text[0]}-${text[1]}-${text[2]} ${text[3]}:${text[4]}`
    }

    return text ? moment(text).format(format) : text
}

type bigNumberType = number | string | BigNumber
//计算
export const bigNumber = {
    // 加
    add(x: bigNumberType, y: bigNumberType) {
        x = BigNumber(x).toFixed(2)
        y = BigNumber(y).toFixed(2)
        return BigNumber(x).plus(y).toNumber()
    },
    // 减
    minus(x: bigNumberType, y: bigNumberType) {
        x = BigNumber(x).toFixed(2)
        y = BigNumber(y).toFixed(2)
        return BigNumber(x).minus(y).toNumber()
    },
    // 乘
    times(x: bigNumberType, y: bigNumberType) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.times(y).toNumber()
    },
    // 多个相乘
    multiTimes(...params: bigNumberType[]) {
        let num = BigNumber(1)
        params.forEach((x) => (num = num.times(BigNumber(x))))
        return num.toNumber()
    },
    // 除
    dividedBy(x: bigNumberType, y: bigNumberType) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.dividedBy(y).toNumber()
    },
    // 多个相加
    multiAdd(...params: bigNumberType[]) {
        let num = BigNumber(0)
        params.forEach((x) => (num = num.plus(BigNumber(x))))
        return num.toNumber()
    },
    //保留小数
    toFixed(num: bigNumberType, x: number) {
        return BigNumber(num).toFixed(x)
    },
}
export function moneyFormat(num: number, decimal = 2, split = ',') {
    /*
		parameter：
		num：格式化目标数字
		decimal：保留几位小数，默认2位
		split：千分位分隔符，默认为,
		moneyFormat(123456789.87654321, 2, ',') // 123,456,789.88
	*/
    function thousandFormat(numstr: string): string {
        const len = numstr.length
        return len <= 3 ? numstr : thousandFormat(numstr.slice(0, len - 3)) + split + numstr.slice(len - 3, len)
    }

    if (isFinite(num)) {
        if (num === 0) {
            // 为0
            return num.toFixed(decimal)
        } else {
            // 非0
            var res = ''
            var dotIndex = String(num).indexOf('.')
            if (dotIndex === -1) {
                // 整数
                if (decimal === 0) {
                    res = thousandFormat(String(num))
                } else {
                    res = thousandFormat(String(num)) + '.' + '0'.repeat(decimal)
                }
            } else {
                // 非整数
                // 保留decimals位小数
                const numStr = String((Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal)) // 四舍五入，然后固定保留2位小数
                const decimals = numStr.slice(dotIndex, dotIndex + decimal + 1) // 截取小数位
                res = thousandFormat(numStr.slice(0, dotIndex)) + decimals
            }
            return res
        }
    } else {
        return '--'
    }
}

export const arrayNonRepeatfy = (arr: any[], name: string) => {
    const newArr = arr.reduce((prev, element) => {
        if (!prev.find((el: any) => el[name] === element[name])) {
            prev.push(element)
        }
        return prev
    }, [])
    return newArr
}
