import { useState, useEffect, useRef } from 'react'
import { createModel } from 'hox'
import useStorageState from '@/hooks/useStorageState'
import { globalPrompt } from '@/components/message'
import { dictService, IDictDetail, IDictSearch } from './dictService'
import { forFind } from '@/utils/utils'
import { DictCodeEnmu } from '@/types/AppDictEnums'
import { ISelectOptions } from '@/types/AppTypes'
import { defaultMdCmParam } from '@/utils/constants'
import { message } from 'antd'
import { debounce } from 'lodash'

export type ITreeSelectData = {
    title: string
    value: string
    selectable?: boolean
    children: ITreeSelectData[]
}

const arrayNonRepeatfy = (arr: any[], name: string) => {
    const newArr = arr.reduce((prev, element) => {
        if (!prev.find((el: any) => el[name] === element[name])) {
            prev.push(element)
        }
        return prev
    }, [])
    return newArr
}

function treeChangeList(list: any) {
    let res: any = []
    flatten(list)
    function flatten(list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].children.length > 0) {
                const { children, ...newChild } = list[i]
                res.push({ ...newChild, children: [] })
                flatten(children)
            } else {
                res.push(list[i])
            }
        }
    }
    return res
}
function serializeTreeChangeList(data: any) {
    let list = JSON.parse(JSON.stringify(data))
    let _arr: string[] = ['all', 'scm', 'bcm', 'vrm', 'ci']
    let _newList: any[] = []
    let setItemChild = (children) => {
        let _childList: any[] = []
        children.forEach((child) => {
            if (_arr.indexOf(child.code.split('|')[0]) !== -1) {
                _childList.push({
                    ...child,
                    children: child.children.filter((item) => {
                        // if (item?.name.indexOf('（') !== -1) {
                        //     item.name = item.name.split('（')[0]
                        // }
                        return item.status
                    }),
                })
            }
        })
        return _childList
    }
    list.forEach((item) => {
        if (item.children?.length !== 0) {
            if (item.code === 'unfinished|trade-type') {
                let _isHasAll = item.children.every((child) => {
                    return !(child.code.indexOf('all') === -1)
                })
                if (!_isHasAll) {
                    let _children: any[] = []
                    item.children.forEach((i) => {
                        _children = [..._children, ...i.children]
                    })
                    _children = arrayNonRepeatfy(_children, 'code')
                    item.children.push({
                        ...item.children[0],
                        code: 'all|unfinished|trade-type',
                        name: '总门店未闭环单据单据类型',
                        children: _children,
                    })
                }
            }
            if (item.code === 'unfinished|document-status') {
                let _isHasAll = item.children.every((child) => {
                    return !(child.code.indexOf('all') === -1)
                })
                if (!_isHasAll) {
                    let _children: any[] = []
                    item.children.forEach((i) => {
                        _children = [..._children, ...i.children]
                    })
                    _children = arrayNonRepeatfy(_children, 'code')
                    item.children.push({
                        ...item.children[0],
                        code: 'all|unfinished|document-status',
                        name: '总门店未闭环单据单据状态',
                        children: _children,
                    })
                }
            }
            let _child = setItemChild(item.children)
            _newList.push({ ...item, children: _child })
        }
    })
    return _newList
}

export const useDictModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam
    const [treeSource, setTreeSource] = useStorageState<IDictDetail[]>('dictTree', []) //字典数据源
    const [originDictList, setOriginDictList] = useStorageState<IDictDetail[]>('dictList', []) //字典数据源
    const [serializeDictTree, setSerializeDictTree] = useStorageState<IDictDetail[]>('serializeDictTree', []) //字典二级树
    const [selectedNodes, setSelectedNodes] = useState<IDictDetail[]>([]) //树选中的行信息
    const [mode, setMode] = useState<'add' | 'nextAdd' | 'edit' | 'root' | ''>('') //模式
    const [formVal, setFormVal] = useState<IDictDetail>(selectedNodes[0]) //表单缓存

    const treeSourceRef = useRef<IDictDetail[]>([])

    useEffect(() => {
        treeSourceRef.current = treeSource
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeSource])

    //实时缓存
    const onChangeFormVal = (changedValues: { [key: string]: any }) => {
        setFormVal({ ...formVal, ...changedValues })
    }

    //字典初始
    const initDictFormFields = {
        pcode: '',
        code: '',
        name: '',
        nameEn: '',
        note: '',
        ver: 0,
        sourceAuthKey: '',
        children: [],
        enable: 0,
        branch,
        value: '',
        key: '',
        // status:0
    }

    useEffect(() => {
        if (mode === 'add') {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].pcode, code: selectedNodes[0].pcode as any })
        } else if (mode === 'nextAdd') {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].code, code: selectedNodes[0].code })
        } else if (mode === 'root') {
            setSelectedNodes([initDictFormFields])
            setFormVal(initDictFormFields)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode])

    //字典列表请求
    const loadData = async () => {
        let data: IDictSearch = {
            sourceApp,
            page: {
                pageSize: -1,
                pageNum: 1,
            },
        }
        let { retData } = await dictService.page(data)
        setTreeSource(retData)
        setOriginDictList(treeChangeList(retData))
        setSerializeDictTree(serializeTreeChangeList(retData))
    }

    const sync = async () => {
        let { retData } = await dictService.sync({
            sourceApp: defaultMdCmParam.sourceApp,
            // branch: "main",
            branchList: ['tco', 'main'],
        })
        if (retData) {
            loadData()
            globalPrompt('message', { type: 'success', text: '同步成功。。。' })
        }
    }
    //表单提交
    const onSubmit = async () => {
        let { id,code, pcode, note, name, branch, value, nameEn, enable, status } = formVal
        let data = {
            id,
            sourceApp,
            branch,
            value,
            nameEn,
            code,
            pcode,
            note,
            name,
            enable: enable === false ? 1 : 0,
            status: status === false ? 0 : 1,
        }
        let nameList = false
        treeSource
            .find((item) => item.code === pcode)
            ?.children.forEach((list) => {
                if (list.name === name && (mode === 'nextAdd' || mode === 'root')) {
                    message.warning('您不可添加重复字典名称！')
                    nameList = true
                }
                if (list.name === name && list.code !== code && mode === 'edit') {
                    message.warning('您不可修改为重复字典名称！')
                    nameList = true
                }
            })
        if (nameList) {
            return
        }
        if (branch !== defaultMdCmParam.branch) {
            message.warning('您无权修改main公用分支的字典')
            return
        }

        if (!data.code || !data.name) {
            globalPrompt('message', { text: '请检查必填项', type: 'warning' })
            return
        }
        if (mode === 'nextAdd' || mode === 'root' || mode === 'add') {
            let { retData } = await dictService.insert(data)
            retData && globalPrompt('message', { type: 'success', text: '字典增加成功' })
            loadData()
            setSelectedNodes([])
        } else if (mode === 'edit') {
            let { retData } = await dictService.update(data)
            retData && globalPrompt('message', { type: 'success', text: '字典编辑成功' })
            loadData()
            setSelectedNodes([])
        }
    }

    //删除一条数据
    const removeOneDict = async (node: IDictDetail) => {
        let data = {
            sourceApp,
            branch,
            code: node.code,
            pcode: node.pcode,
        }
        globalPrompt('modal', {
            title: '删除',
            type: 'warning',
            text: '您是否删除此项数据字典？',
            okText: '确认',
            onOk: debounce(async () => {
                let { retData } = await dictService.remove(data)
                retData && globalPrompt('message', { type: 'success', text: '字典删除成功' })
                loadData()
                setSelectedNodes([])
            }, 500),
            cancelText: '取消',
            onCancel: () => {},
        })
    }

    //根据code返回 select框的options 格式；
    const getSelectedDictOne = (branch: string = 'all', menu: string = 'home', code: DictCodeEnmu): ISelectOptions[] => {
        let _code = `${branch}|${menu}|${code}`
        let findTree: IDictDetail | undefined = forFind(serializeDictTree, _code, 'code')
        return findTree ? findTree.children.map((item) => ({ label: item.name, value: item.code, type: item.value ?? '' })) : []
    }
    //根据code返回 select框的options 格式；
    const getSelectedDictValueOne = (code: DictCodeEnmu): ISelectOptions[] => {
        let findTree: IDictDetail | undefined = forFind(treeSource, code, 'code')
        return findTree ? findTree.children.map((item) => ({ label: item.name, value: item.code, type: item.value ?? '' })) : []
    }

    //根据code 返回对应的node节点名称
    const getTreeNodeName = (code?: string): string => {
        if (!code) return code ?? ''
        let findNode: IDictDetail | undefined = originDictList.find((item) => item.code === code)
        return findNode ? findNode['name'] : code ?? ''
    }
    //根据code 返回对应的node节点名称
    const getTreeNodeNameFine = (code?: string, pcode?: string): string => {
        if (!code) return code ?? ''
        let findNode: IDictDetail | undefined = originDictList.find((item) => (pcode ? item.code === code && item.pcode === pcode : item.code === code))
        return findNode ? findNode['name'] : code ?? ''
    }
    //根据code 返回对应的node节点英文名称
    const getTreeNodeNameEn = (code?: string): string => {
        if (!code) return code ?? ''
        let findNode: IDictDetail | undefined = originDictList.find((item) => item.code === code)
        return findNode ? findNode.nameEn ?? '' : code ?? ''
    }

    //根据code 返回对应的value 字典值 （例如， 币种的符号）
    const getTreeNodeValue = (code?: string): string => {
        if (!code) return ''
        let findNode: IDictDetail | undefined = originDictList.find((item) => item.code === code)
        return findNode ? findNode['value'] ?? '' : ''
    }
    //根据code 返回对应的value 字典值 （例如， 币种的符号）
    const getTreeNodeNameValue = (name?: string): string => {
        if (!name) return ''
        let findNode: IDictDetail | undefined = originDictList.find((item) => item.name === name)
        return findNode ? findNode['value'] ?? '' : ''
    }

    //根据code 返回对应树节点
    const getTreeNodeOne = (code: DictCodeEnmu): IDictDetail[] => {
        let findTree: IDictDetail | undefined = forFind(treeSourceRef.current, code, 'code')
        return findTree ? [findTree] : []
    }

    const mapTree = (org: IDictDetail, selectable: boolean): ITreeSelectData => {
        const haveChildren = Array.isArray(org.children) && org.children.length > 0
        return {
            title: org.name,
            value: org.code,
            selectable: selectable ? true : haveChildren ? false : true,
            //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
            children: haveChildren ? org.children.map((i) => mapTree(i, selectable)) : [],
        }
    }

    /**
     *
     * @param code 根据code 返回treeSelect 数据格式
     * @param selectable 有子节点的父节点不能选中  (默认只能选中子节点)
     */
    const getTreeSelectOne = (code: DictCodeEnmu, selectable: boolean = false): ITreeSelectData[] => {
        let findTree: IDictDetail | undefined = forFind(treeSource, code, 'code')
        let resultTree: ITreeSelectData[] = findTree ? mapTree(findTree, selectable).children : []
        return resultTree
    }

    return {
        /***field***/
        treeSource,
        selectedNodes,
        mode,
        formVal,
        /***method***/
        setTreeSource,
        setSelectedNodes,
        setMode,
        setFormVal,
        onChangeFormVal,
        loadData,
        onSubmit,
        removeOneDict,
        getSelectedDictOne,
        getTreeNodeName,
        getTreeNodeOne,
        getTreeSelectOne,
        sync,
        getTreeNodeValue,
        getTreeNodeNameValue,
        getSelectedDictValueOne,
        getTreeNodeNameEn,
        getTreeNodeNameFine,
    }
})
