import { useEffect, useState } from 'react'
import { Table } from 'antd'
import { useModel } from '../model'
import { useNavModel } from '@/model/navModel'
import YhTootip from '@/components/YhTootip'
import { momentFormat } from '@/utils/utils'
import { YhTableSort } from '@/components/YhTableSort'
import { commonService } from '@/service/common'
import { SettingOutlined } from '@ant-design/icons'
import { setStatusTag } from '@/App'

const sortKey = 'enterWarehouse'

export function DataTable() {
    const { dataSource, pgCfg, updateEditFormVal, loadData, columnsSetVisible, setColumnsSetVisible, orders, loading } = useModel()
    const { addNav, findNav, switchNavState } = useNavModel(() => [])

    useEffect(() => {
        loadData()
        getColumns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onEdit = (record) => {
        let supplierEditKey = '3-2-1'
        let isFindNav = findNav(supplierEditKey)
        if (!isFindNav) {
            addNav(supplierEditKey, { id: record?.id, mode: 'edit' })
            updateEditFormVal(null)
            window.sessionStorage.setItem('enterWarehouseDetails', JSON.stringify(record))
        } else {
            switchNavState(supplierEditKey, { id: record?.id, mode: 'edit' })
            updateEditFormVal(null)
            window.sessionStorage.setItem('enterWarehouseDetails', JSON.stringify(record))
        }
    }

    const getRegularColumns = [
        {
            title: (
                <>
                    <span>
                        <SettingOutlined
                            onClick={() => {
                                setColumnsSetVisible(true)
                            }}
                        />
                    </span>
                    <span>序号</span>
                </>
            ),
            width: 80,
            fixed: 'left',
            align: 'center',
            render: (t, r, i) => i + 1,
        },
    ]
    const defaultColumns = [
        {
            title: '分支编码',
            width: 100,
            dataIndex: 'branch',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '门店名称',
            width: 140,
            dataIndex: 'shopName',
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '140px' }} />,
        },
        {
            title: '单位名称',
            width: 220,
            dataIndex: 'orgName',
            sorter: true,
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '220px' }} />,
        },
        {
            title: '入库单号',
            width: 200,
            dataIndex: 'code',
            sorter: true,
            isSet: true,
            render: (t, r) => (
                <YhTootip
                    onClick={() => {
                        onEdit(r)
                    }}
                    text={r.code}
                    style={{ width: '200px', color: '#4165E3', cursor: 'pointer' }}
                />
            ),
        },
        {
            title: '入库时间',
            width: 160,
            dataIndex: 'stockIoTime',
            sorter: true,
            isSet: true,
            render: (text) => momentFormat(text),
        },
        {
            title: '入库数量',
            width: 100,
            dataIndex: 'num',
            sorter: true,
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '入库类型',
            width: 100,
            dataIndex: 'tradeTypeName',
            sorter: true,
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '单据状态',
            width: 100,
            dataIndex: 'status',
            sorter: true,
            isSet: true,
            render: (text) => {
                return setStatusTag(text)
            },
        },
        {
            title: '制单员',
            width: 100,
            dataIndex: 'createUser',
            sorter: true,
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
        {
            title: '制单时间',
            width: 160,
            dataIndex: 'createTime',
            sorter: true,
            isSet: true,
            render: (text) => momentFormat(text),
        },
        {
            title: '库管员',
            width: 100,
            dataIndex: 'storekeeper',
            sorter: true,
            isSet: true,
            render: (text) => <YhTootip text={text} style={{ width: '100px' }} />,
        },
    ]

    const [columns, setColumns] = useState<any[]>([])

    const getColumns = () => {
        commonService.userCustomizeConfigOne(sortKey).then((res) => {
            if (!res.retData) {
                setColumns([...getRegularColumns, ...defaultColumns])
            } else {
                let newColumns: any[] = [...getRegularColumns]
                let data = JSON.parse(res.retData.val)
                data.forEach((element) => {
                    if (element.isShow) {
                        newColumns.push({ ...defaultColumns.find((i) => i.dataIndex === element.key), sorter: element.isSort })
                    }
                })
                setColumns(newColumns)
            }
        })
    }

    const onSuccess = () => {
        setColumnsSetVisible(false)
        getColumns()
    }

    const onChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            if (sorter.order) {
                orders.current = [{ column: sorter.field, asc: sorter.order === 'ascend' }]
            } else {
                orders.current = []
            }
            loadData()
        }
    }

    return (
        <>
            <Table
                rowKey={(record) => record.id || record.index}
                onChange={onChange}
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                pagination={{
                    ...pgCfg,
                    position: ['bottomRight'],
                    showTotal: () => `共${pgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    },
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 'max-content', y: 'calc(100vh - 380px)' }}
            />
            <YhTableSort
                sortKey={sortKey}
                visible={columnsSetVisible}
                setVisible={setColumnsSetVisible}
                defaultColumns={defaultColumns}
                onSuccess={onSuccess}
            />
        </>
    )
}
