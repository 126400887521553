import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'
import { useModel } from '../model'
import useGlobalModel from '@/model/globalModel'
import { YHImport } from '@/components/YHImport'

export function CommoditiesList() {
    const { importVisible, setImportVisible, loadData, exportSupplier } = useModel()
    const {
        user: { branch },
    } = useGlobalModel()
    return (
        <>
            <PageHeader />

            <DataBox />

            <YHImport
                visible={importVisible}
                setVisible={setImportVisible}
                onCancel={() => {
                    setImportVisible(false)
                }}
                downloadTemlpate={() => exportSupplier({ materialName: 'xxxxxxxxxxxxxxx' }, '商品管理导入模板.xlsx')}
                exportFile={() => exportSupplier()}
                importUrl={`/${
                    branch === 'scm' ? 'scm2' : branch === 'bcm' ? 'bcm' : branch === 'vrm' ? 'vrm2' : branch === 'ci' ? 'ci-storage' : ''
                }/base/material/materialLimitImport`}
                upLoadCallback={(data) => {
                    loadData()
                    setImportVisible(false)
                }}
            />
        </>
    )
}
