import { useEffect, useState } from 'react'
import { message, Table } from 'antd'
import { service } from '@/views/commodities/commoditiesList/service'

export const YCOcommoditiesDetailTable = ({ code }) => {
    const [dataSource, setDataSource] = useState<any[]>([])

    useEffect(() => {
        if (code) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])

    const getData = async () => {
        try {
            let { retData } = await service.getDetailsList(code)
            setDataSource(retData)
        } catch {
            message.error('库存信息列表获取失败')
        }
    }

    const columns = [
        {
            title: '系统分类',
            dataIndex: 'branch',
        },
        {
            title: '门店名称',
            dataIndex: 'shopName',
        },
        {
            title: '最低下限',
            dataIndex: 'limitLower',
        },
        {
            title: '最高上限',
            dataIndex: 'limitUpper',
        },
    ]

    return (
        <>
            <Table
                rowClassName="editable-row"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 'max-content' }}
                bordered
            />
        </>
    )
}
