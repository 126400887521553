import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, Radio } from "antd";

export const __FormRadio: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}
        >
            <Radio.Group disabled={props.disable}>
                {props.radioDs && props.radioDs?.map((item, index) => <Radio value={item.value} key={index}>{item.label}</Radio>)}
            </Radio.Group>
        </Form.Item>
    )
}