import { IPage, ISearchPage } from '@/types/AppTypes'
import { httpRequest } from '@/utils/HttpRequest'

//详情
export interface IDetail {
    /**
     * 总金额
     */
    amount?: number
    /**
     * 分支
     */
    branch?: string
    /**
     * 单号
     */
    code?: string
    /**
     * 制单时间
     */
    createTime?: string
    /**
     * 制单员
     */
    createUser?: string
    /**
     * 配件编码
     */
    materialCode?: string
    /**
     * 配件名称
     */
    materialName?: string
    /**
     * 数量
     */
    num?: number
    /**
     * 单位名称
     */
    orgName?: string
    /**
     * 单价
     */
    price?: number
    /**
     * 货位
     */
    shelfCode?: string
    /**
     * 门店ID
     */
    shopId?: string
    /**
     * 门店名称
     */
    shopName?: string
    /**
     * 单据状态
     */
    status?: string
    /**
     * 出入库时间
     */
    stockIoTime?: string
    /**
     * 库管员
     */
    storekeeper?: string
    /**
     * 出入库类型编码
     */
    tradeTypeCode?: string
    /**
     * 出入库类型
     */
    tradeTypeName?: string
    /**
     * 仓库名称
     */
    warehouseName?: string
    [property: string]: any
}

//搜索条件
export interface ISearchFields {
    code?: string
    tradeTypeCode?: string
    status?: any
    startTime?: string
    endTime?: string
}

export interface ISearch extends ISearchFields, ISearchPage {
    shopId?: string
    shopCode?: string
    branch: string
}

class Service {
    async page(searchVo: ISearch, config: object = {}) {
        const apiUrl = '/ims/stockIo/details/out'
        return await httpRequest.post<IPage<IDetail>>(apiUrl, searchVo, config)
    }
    //导出
    async export(searchVo: ISearch) {
        let apiUrl = '/yh-export/stockIo/details/out/export'
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000, ...{} })
    }
}

export const service = new Service()
